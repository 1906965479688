import React from 'react';
import { FormControl } from '@material-ui/core';
import { Select } from '@material-ui/core';
import { Controller } from 'react-hook-form';

function EditableSelector({ formProps, name, children }) {
  return (
    <FormControl fullWidth>
      <Controller
        as={
          <Select title={name} required>
            {children}
          </Select>
        }
        name={name}
        control={formProps.control}
      />
    </FormControl>
  );
}

export default EditableSelector;
