import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import api from '../../ApiService/MarketCoreService';
import EnhancedTable from '../EnhancedTableView/EnhancedTable';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  label: {
    paddingLeft: theme.spacing(4),
  },
  value: {
    paddingTop: theme.spacing(2),
  },
  up: {
    color: 'lime',
  },
  down: {
    color: 'red',
  },
}));

const TransactionsTable = ({ address }) => {
  const classes = useStyles();
  const [transactions, setTransactions] = useState([]);
  const DP = 6;

  useEffect(() => {
    if (address) {
      console.log('tx address', address);
      const fetch = async () => {
        setTransactions((await api.getTransactionsCustodian(address)).data);
      };
      fetch();
    }
  }, [address]);

  console.log('tx', transactions);

  const numberFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const columns = [
    {
      id: 'from',
      numeric: false,
      disablePadding: false,
      label: 'Sender',
      valueOf: (tx) => tx.from,
    },
    {
      id: 'to',
      numeric: false,
      disablePadding: false,
      label: 'Recipient',
      valueOf: (tx) => tx.to,
    },
    {
      id: 'value',
      numeric: true,
      disablePadding: false,
      label: 'Value',
      valueOf: (tx) =>
        tx.value && numberFormatter.format(tx.value / Math.pow(10, DP)),
    },
    {
      id: 'refid',
      disablePadding: false,
      label: 'Reference #',
      valueOf: (tx) => tx.refId,
    },
  ];
  const [selected, setSelected] = useState([]);

  return (
    <EnhancedTable
      classes={classes}
      columnDefs={columns}
      operationDefs={[]}
      dataList={transactions}
      selected={selected}
      setSelected={setSelected}
      actionDefs={[]}
      title={`Transactions of ${address}`}
    />
  );
};

export default TransactionsTable;
