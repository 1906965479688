import axios from 'axios'; // chrome.exe --user-data-dir="C:/Chrome dev session" --disable-web-security
import { handleError } from './Utilities';
import { toast } from 'react-toastify';

const API = axios.create({
  baseURL: window['config'].apiBaseUrl.mayan,
});

const ApiService = {
  setEdmsAuth(auth) {
    API.defaults.headers.common['Authorization'] = auth;
  },
  forgetEdmsAuth() {
    delete API.defaults.headers.common['Authorization'];
  },
  getDocuments(setter) {
    console.log('updating list');
    return API.get(`documents/`)
      .then((response) => {
        const docs = response.data.results;
        console.log('docList resolved: ', response);
        setter && setter(docs);
        return docs;
      })
      .catch(handleError);
  },

  downloadDocument(setter, docId, docName) {
    console.log('downloading doument: ' + docName);
    const FileDownload = require('js-file-download');
    return API.get(`documents/${docId}/download/`, {
      responseType: 'blob',
    })
      .then((response) => {
        FileDownload(response.data, docName);
        console.log('downloaded document: ', response);
        setter && setter(response.data);
        return true;
      })
      .catch(handleError);
  },

  createDocument(setter, file, documentType, description, label) {
    console.log('uploading file ', label);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('document_type', documentType);
    formData.append('description', description);
    formData.append('label', label);
    formData.append('language', 'en');

    return API.post(`documents/`, formData)
      .then((response) => {
        const uploadedFile = response.data;
        console.log('uploaded', response);
        setter && setter(uploadedFile);
        return uploadedFile;
      })
      .catch(handleError);
  },
  editDocument(setter, docId, body) {
    console.log('editting single document: ' + docId);
    return API.put(`documents/${docId}`, body)
      .then((response) => {
        const doc = response.data;
        console.log('got document: ', response);
        setter && setter(doc);
        return doc;
      })
      .catch(handleError);
  },
  deleteDocument(docId) {
    console.log('deleting doc: ' + docId);
    return API.delete(`documents/${docId}/`)
      .then((response) => {
        console.log(`deleted`, response);
        return true;
      })
      .catch(handleError);
  },

  //w
  changeDocType(setter, docId, newDocTypeId) {
    console.log('changing filetype to: ' + docId);
    return API.post(`/documents/${docId}/type/change/`, {
      new_document_type: newDocTypeId,
    })
      .then((response) => {
        console.log('updated doctype', response);
        setter && setter(response.data);
        return true;
      })
      .catch(handleError);
  },
  createTag(setter, rgbcolorvalue, label) {
    console.log('creating tag label: ' + label);
    return API.post(`tags/`, {
      color: rgbcolorvalue,
      label: label,
    })
      .then((response) => {
        console.log('created tag color', response);
        setter && setter(response.data);
        return true;
      })
      .catch(handleError);
  },
  addTagToDoc(setter, docPK, tagPK) {
    console.log('adding tag to doc: ' + docPK);
    return API.post(`documents/${docPK}/tags/`, {
      tag_pk: tagPK,
    })
      .then((response) => {
        console.log('added tag', response);
        setter && setter(response.data);
        return true;
      })
      .catch(handleError);
  },
  checkoutDoc(setter, IsblockNew, docPK, expTime) {
    console.log('checking out file: ' + docPK);
    return API.post(`checkouts/`, {
      block_new_version: IsblockNew,
      document_pk: docPK,
      expiration_datetime: expTime,
    })
      .then((response) => {
        console.log('checked out file', response);
        setter && setter(response.data);
        return true;
      })
      .catch(handleError);
  },
  checkinDoc(docId) {
    console.log('checking in file:' + docId);
    return API.delete(`checkouts/${docId}/checkout_info/`)
      .then((response) => {
        console.log('checked in file', response);
        return true;
      })
      .catch(handleError);
  },
  createUser(setter, body) {
    console.log('creating new user ', body);
    return API.post(`users/`, body)
      .then((response) => {
        console.log('created new user', response);
        setter && setter(response.data);
        return true;
      })
      .catch(handleError); // TODO: handle invalid
  },
  getUsers(setter) {
    console.log('getting all users info');
    return API.get(`users/`)
      .then((response) => {
        let users = response.data['results'];
        console.log('users info', users);
        setter && setter(users);
        return true;
      })
      .catch(handleError);
  },
  getUserInfo(setter, userId) {
    console.log('getting user info: ' + userId);
    return API.get(`users/${userId}/`)
      .then((response) => {
        console.log('user info', response);
        setter && setter(response.data);
        return true;
      })
      .catch(handleError);
  },
  editUserInfo(setter, userId, body) {
    console.log('editing user info:' + userId);
    console.log('body: ', body);
    return API.put(`users/${userId}/`, body)
      .then((response) => {
        console.log('edited user info', response);
        setter && setter(response.data);
        return true;
      })
      .catch(handleError);
  },
  changeUserPW(setter, userId, password) {
    console.log('changing user pw: ' + userId);
    return API.get(`users/${userId}/`)
      .then((response) => {
        let body = response.data;
        body.password = password;
        return API.put(`users/${userId}/`, body)
          .then((response) => {
            console.log('updated user pw', response);
            setter && setter(response.data);
            return true;
          })
          .catch(handleError);
      })
      .catch(handleError);
  },
  editGroupsOfUser(setter, userId, groupPkListString) {
    console.log(`updating userId: ${userId} to groupsId: ${groupPkListString}`);
    return API.post(`users/${userId}/groups/`, {
      group_pk_list: groupPkListString,
    })
      .then((response) => {
        console.log('updated groups to user', response);
        setter && setter(response.data);
        return true;
      })
      .catch(handleError);
  },
  deleteUser(userId) {
    console.log('deleting user: ' + userId);
    return API.delete(`users/${userId}/`)
      .then((response) => {
        console.log('deleted user', response);
        return true;
      })
      .catch(handleError);
  },

  getCurrentUser(setter) {
    console.log('getting current user');
    return API.get(`users/current`)
      .then((response) => {
        let currentUser = response.data;
        console.log('current user info', currentUser);
        setter && setter(currentUser);
        return true;
      })
      .catch(handleError);
  },

  getGroups(setter) {
    console.log('getting all groups info');
    return API.get(`groups/`)
      .then((response) => {
        let groups = response.data['results'];
        console.log('groups info', groups);
        setter && setter(groups);
        return true;
      })
      .catch(handleError);
  },
  createGroup(setter, groupName) {
    console.log('creating group: ' + groupName);
    return API.post(`groups/`, {
      name: groupName,
    })
      .then((response) => {
        const newGroup = response.data;
        console.log('created group', response);
        setter && setter(newGroup);
        return newGroup;
      })
      .catch(handleError);
  },
  deleteGroup(setter, groupId) {
    console.log('deleting group with id: ' + groupId);
    return API.delete(`groups/${groupId}`)
      .then((response) => {
        console.log('deleted group', response);
        setter && setter(response.data);
        return true;
      })
      .catch(handleError);
  },
  editGroupName(setter, groupId, newGroupName) {
    console.log('updating group: ' + groupId);
    return API.get(`groups/${groupId}/`)
      .then((response) => {
        let body = response.data;
        body.name = newGroupName;
        return API.put(`groups/${groupId}/`, body)
          .then((response) => {
            console.log('updated group', response);
            setter && setter(response.data);
            return true;
          })
          .catch(handleError);
      })
      .catch(handleError);
  },
  postUserGroups(setter, userId, groupPkList) {
    console.log('adding groups to user: ' + userId);
    return API.post(`users/${userId}/groups/`, {
      groups_pk_list: groupPkList,
    })
      .then((response) => {
        console.log('added groups', response);
        setter && setter(response.data);
        return true;
      })
      .catch(handleError);
  },
  editRoleGroups(setter, roleId, groupPkList) {
    const groupPkListString = groupPkList.join(',');
    console.log('editing groups for role: ' + roleId);
    return API.get(`roles/${roleId}/`)
      .then((response) => {
        let body = response.data;
        if (groupPkListString) {
          body.groups_pk_list = groupPkListString;
        } else {
          delete body.groups_pk_list;
        }
        return API.put(`roles/${roleId}/`, body)
          .then((response) => {
            console.log('edited groups for roles', response);
            setter && setter(response.data);
            return true;
          })
          .catch(handleError);
      })
      .catch(handleError);
  },
  getRoles(setter) {
    console.log('getting roles');
    return API.get(`roles/`)
      .then((response) => {
        let roles = response.data['results'];
        console.log('roles info', roles);
        setter && setter(roles);
        return true;
      })
      .catch(handleError);
  },
  createRole(setter, body) {
    console.log('creating role: ', body);
    return API.post(`roles/`, body)
      .then((response) => {
        console.log('created role', response);
        setter && setter(response.data);
        return true;
      })
      .catch(handleError);
  },
  editRole(setter, roleId, body) {
    console.log('editing name role: ', roleId, ' with body ', body);
    return API.put(`roles/${roleId}/`, body)
      .then((response) => {
        console.log('edited role', response);
        setter && setter(response.data);
        return true;
      })
      .catch(handleError);
  },
  deleteRole(roleId) {
    console.log('deleting role: ' + roleId);
    return API.delete(`roles/${roleId}/`)
      .then((response) => {
        console.log('deleted role', response);
        return true;
      })
      .catch(handleError);
  },
  editRolePermission(setter, roleId, roleName, permissions_pk_List) {
    console.log('editing role permission: ' + roleId);
    return API.put(`roles/${roleId}/`, {
      label: roleName,
      permissions_pk_list: permissions_pk_List,
    })
      .then((response) => {
        console.log('edited role permissions', response);
        setter && setter(response.data);
        return true;
      })
      .catch(handleError);
  },
  createDocType(setter, docTypeName) {
    console.log('creating new document type: ' + docTypeName);
    return API.post(`document_types/`, {
      label: docTypeName,
    })
      .then((response) => {
        console.log('created new document type', response);
        setter && setter(response.data);
        return true;
      })
      .catch(handleError);
  },
  getDocTypes(setter) {
    console.log('getting all document types');
    return API.get(`document_types/`)
      .then((response) => {
        const docTypes = response.data.results;
        console.log('got document types', response);
        setter && setter(docTypes);
        return docTypes;
      })
      .catch(handleError);
  },
  getDocType(setter, docId) {
    console.log('getting document type: ' + docId);
    return API.get(`document_types/${docId}/`)
      .then((response) => {
        console.log('got document type', response);
        setter && setter(response.data);
        return true;
      })
      .catch(handleError);
  },
  updateDocType(setter, docId, body) {
    console.log('updating document type: ' + docId);
    return API.put(`document_types/${docId}/`, body)
      .then((response) => {
        console.log('updated document type', response);
        setter && setter(response.data);
        return true;
      })
      .catch(handleError);
  },
  deleteDocType(docTypeId) {
    console.log('deleting document type: ' + docTypeId);
    return API.delete(`document_types/${docTypeId}/`)
      .then((response) => {
        console.log('deleted document type', response);
        return true;
      })
      .catch(handleError);
  },
  getDocTypesAndAcls(setDocTypes, setAclObjs) {
    this.getDocTypes((newDocTypeList) => {
      (async () => {
        setDocTypes(newDocTypeList);
        let promises = newDocTypeList.map((docType) => {
          // combine acls from docType into one array (newAclList) with docType Obj attached
          let obj = [];
          const setter = (aclsOfDocType) => {
            if (aclsOfDocType?.length) {
              obj = obj.concat(
                aclsOfDocType.map((acl) => ({ ...acl, docType }))
              );
            }
          };
          return this.getACLForDocType(setter, docType.id).then(() => obj);
        });
        let newAclList = (await Promise.all(promises)).flat();
        console.log('acl list: ', newAclList);

        // for each acl, get its permissions to the aclObj
        promises = newAclList.map((acl) => {
          const setter = (permissions) => {
            acl.permissions = permissions;
          };
          return this.getPermissionsForAcl(setter, acl.object_id, acl.id).then(
            () => acl
          );
        });
        newAclList = await Promise.all(promises);
        console.log('new ACL list with permissions: ', newAclList);
        setAclObjs(() => newAclList);
      })();
    });
  },
  getACLForDocType(setter, docTypeId) {
    console.log('getting acls for doc_type: ' + docTypeId);
    return API.get(`objects/documents/documenttype/${docTypeId}/acls/`)
      .then((response) => {
        console.log('got acl for doctype', response);
        setter && setter(response.data.results);
        return true;
      })
      .catch(handleError);
  },
  createACLForDocType(setter, docTypeId, role_pk, permissions_pk_List) {
    console.log('creating acls for doc_type: ' + docTypeId);
    return API.post(`objects/documents/documenttype/${docTypeId}/acls/`, {
      role_pk: role_pk,
      permissions_pk_list: permissions_pk_List,
    })
      .then((response) => {
        console.log('created acl for doctype', response);
        setter && setter(response.data);
        return true;
      })
      .catch(handleError);
  },
  deleteAclForDocType(docTypeId, aclId) {
    console.log(`deleting acl no.${aclId} for doctype: ${docTypeId}`);
    return API.delete(
      `objects/documents/documenttype/${docTypeId}/acls/${aclId}/`
    )
      .then((response) => {
        console.log('deleted acl for doctype', response);
        return true;
      })
      .catch(handleError);
  },
  getPermissionsForAcl(setter, docTypeId, aclId) {
    return API.get(
      `objects/documents/documenttype/${docTypeId}/acls/${aclId}/permissions/`
    )
      .then((response) => {
        console.log(
          `got permisssion of acl\ndocTypeId: ${docTypeId}\nacl id: ${aclId}`,
          response.data
        );
        setter && setter(response.data.results);
        return;
      })
      .catch(handleError);
  },
  getPermissions(setter) {
    let promises = [];
    for (let i = 1; i <= Math.ceil(145.0 / 10); ++i) {
      promises.push(
        API.get(`/permissions/?page=${i}`).then(
          (response) => response.data.results
        )
      );
    }
    return Promise.all(promises).then((results) => {
      results = results.flat();
      console.log('got permissions ', results);
      setter && setter(results);
      return results;
    });
  },
  // to be faded out
  Login(username, password) {
    console.log('login as ', username, ':', password);
    return API.post(`/auth/token/obtain/`, {
      username,
      password,
    })
      .then((response) => {
        console.log('login: ', response);
        const token = response.data.token;
        const dms_token = `Token ${token}`;
        console.log('dms token: ', dms_token);
        API.defaults.headers.common['Authorization'] = dms_token;
        console.log('logged in');
        return true;
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          toast.error('Invalid username/password');
        } else {
          handleError(err);
        }
      });
  },
  Logout() {
    console.log('logged out');
    delete API.defaults.headers.common['Authorization'];
  },
};

export default ApiService;
