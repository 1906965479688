import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  TextField,
  Typography,
  Input,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Card,
  Paper,
  IconButton,
  InputAdornment,
  FormHelperText,
} from '@material-ui/core';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import SelectGroupsSubform from '../GroupsManagement/SelectGroupsSubform';
import TextFieldWithValidation from '../TextFieldWithValidation';
import { ErrorMessage } from '@hookform/error-message';
import DialogForm from '../DialogForm';
import FieldArraySection from '../FieldArraySection';
import ApiService from '../../ApiService/ApiService';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  content: {
    width: '100%',
    padding: theme.spacing(0, 2, 2, 2),
  },
  formControl: {
    minWidth: 120,
    margin: theme.spacing(0, 1),
  },
  paper: {
    width: '100%',
    minWidth: 200,
    padding: theme.spacing(1, 2),
    margin: theme.spacing(2, 0, 0, 0),
  },
  card: {
    width: '100%',
    minWidth: 200,
    padding: theme.spacing(1, 2),
    margin: theme.spacing(0, 0, 1, 0),
  },
}));

const CreateNewStaffDialog = ({
  prevGroups,
  open,
  closeDialog,
  update,
  params,
  staffRoles,
  staffStatus,
}) => {
  const [selectedGroups, setSelectedGroups] = useState([]);

  const classes = useStyles();
  const { register, control, handleSubmit, reset, errors } = useForm();

  const phonesFieldArray = useFieldArray({
    control,
    name: 'phones',
  });

  const addressesFeildArray = useFieldArray({
    control,
    name: 'addresses',
  });

  const onSubmit = (data, e) => {
    data.statusID = 1; // i.e. ACTIVE
    data['groups_pk_list'] = selectedGroups.length
      ? selectedGroups.join(',')
      : '0';
    console.log('new Staff: ', data);
    ApiService.createStaff(null, data).then((newStaff) => {
      if (newStaff) {
        toast.success(`Successfully created new Staff ${newStaff.userName}!`);
        update();
        closeDialog();
        e.target.reset();
        reset();
      }
    });
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((old) => !old);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <DialogForm
      open={open}
      onClose={() => {
        closeDialog();
        reset();
      }}
      onSubmit={handleSubmit(onSubmit)}
      title="Create New Staff"
      maxWidth="sm"
    >
      <TextFieldWithValidation
        errors={errors}
        label={`New Username`}
        name="username"
        inputRef={register({
          required: 'Enter Username',
          pattern: {
            value: /^[\w.@+-]+$/,
            message: 'Letters, digits and @.+-_ only',
          },
          maxLength: {
            value: 150,
            message: '150 characters or fewer',
          },
        })}
        fullWidth
        error={typeof errors.username !== 'undefined'}
      />

      <FormControl fullWidth>
        <InputLabel required error={typeof errors.password !== 'undefined'}>
          New Password
        </InputLabel>
        <Input
          label={`New Password`}
          type={showPassword ? 'text' : 'password'}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                tabIndex={-1}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          name="password"
          inputRef={register({
            required: 'Enter Password',
            pattern: {
              value: /[^0-9]/,
              message: "Your password can't be entirely numeric.",
            },
            minLength: {
              value: 8,
              message: 'Your password must contain at least 8 characters',
            },
          })}
          error={typeof errors.password !== 'undefined'}
        />
        <ErrorMessage
          errors={errors}
          name="password"
          as={<FormHelperText id={`password-error-text`} error />}
        />
      </FormControl>

      <TextFieldWithValidation
        errors={errors}
        label={`New First Name`}
        name="firstName"
        inputRef={register({
          required: 'Enter First Name',
          maxLength: {
            value: 30,
            message: '30 characters or fewer',
          },
        })}
        fullWidth
        error={typeof errors.firstName !== 'undefined'}
      />

      <TextFieldWithValidation
        errors={errors}
        label={`New Last Name`}
        name="lastName"
        inputRef={register({
          required: 'Enter Last Name',
          maxLength: {
            value: 150,
            message: '30 characters or fewer',
          },
        })}
        fullWidth
        error={typeof errors.lastName !== 'undefined'}
      />

      <TextFieldWithValidation
        errors={errors}
        label={`New Email`}
        name="emails"
        inputRef={register({
          required: 'Enter Email',
          pattern: {
            value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
            message: 'Please enter a valid email.',
          },
        })}
        fullWidth
        error={typeof errors.emails !== 'undefined'}
      />

      <FormControl fullWidth>
        <InputLabel
          id="staffRole-label"
          required
          error={typeof errors.roleID !== 'undefined'}
        >
          Staff Role
        </InputLabel>
        <Controller
          as={
            <Select labelId="staffRole-label">
              <MenuItem value="" disabled={true}>
                Select...
              </MenuItem>
              {staffRoles?.map((role) => (
                <MenuItem key={role.id} value={role.id}>
                  {role.role}
                </MenuItem>
              ))}
            </Select>
          }
          rules={{ required: 'Plese select Staff Role' }}
          name={`roleID`}
          control={control}
          defaultValue={''}
        />
        <FormHelperText error>
          {errors.roleID && errors.roleID.message}
        </FormHelperText>
      </FormControl>

      <Paper className={classes.paper}>
        <Typography variant="subtitle1">
          <b>Phones</b>
        </Typography>
        <FieldArraySection
          {...phonesFieldArray}
          render={(item, index) => (
            <Card className={classes.card} key={item.id}>
              <Grid container spacing={2} sm>
                <Grid item xs={5}>
                  <FormControl fullWidth>
                    <InputLabel id="phoneType-label" required>
                      Phone Type
                    </InputLabel>
                    <Controller
                      as={
                        <Select
                          labelId="phoneType-label"
                          placeholder="Phone Type"
                          required
                        >
                          <MenuItem value="" disabled={true}>
                            Select...
                          </MenuItem>
                          {params?.PhoneType?.map((type) => (
                            <MenuItem key={type.id} value={type.id}>
                              {type.phoneTyp}
                            </MenuItem>
                          ))}
                        </Select>
                      }
                      name={`phones[${index}].phoneTypeID`}
                      control={control}
                      defaultValue={1}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={7}>
                  <TextFieldWithValidation
                    errors={errors}
                    label={`Phone ${index}`}
                    name={`phones[${index}].phoneNo`}
                    inputRef={register({
                      required: 'Enter Phone',
                      pattern: {
                        value: /^\+?\d+$/,
                        message: 'Please use + and digits only',
                      },
                    })}
                    error={
                      typeof errors.phones !== 'undefined' &&
                      typeof errors.phones[index] !== 'undefined'
                    }
                  />
                  <FormHelperText
                    hidden={
                      typeof errors.phones !== 'undefined' &&
                      typeof errors.phones[index] !== 'undefined'
                    }
                  >
                    Use numbers only
                  </FormHelperText>
                </Grid>
              </Grid>
            </Card>
          )}
        />
      </Paper>

      <Paper className={classes.paper}>
        <Typography variant="subtitle1">
          <b>Addresses</b>
        </Typography>
        <FieldArraySection
          {...addressesFeildArray}
          render={(item, index) => (
            <Card className={classes.card} key={item.id}>
              <Grid container spacing={2} sm>
                <Grid item xs={5}>
                  <FormControl fullWidth>
                    <InputLabel id="addressType-label" required>
                      Address Type
                    </InputLabel>
                    <Controller
                      as={
                        <Select
                          labelId="addressType-label"
                          placeholder="Address Type"
                          required
                        >
                          <MenuItem value="" disabled={true}>
                            Select...
                          </MenuItem>
                          {params?.AddressType?.map((type, index) => (
                            <MenuItem key={type.id} value={type.id}>
                              {type.addressTyp}
                            </MenuItem>
                          ))}
                        </Select>
                      }
                      name={`addresses[${index}].addrTypeID`}
                      control={control}
                      defaultValue={1}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    multiline
                    fullWidth
                    rows="3"
                    rowsMax="5"
                    variant="filled"
                    label={`Address ${index}`}
                    name={`addresses[${index}].addr`}
                    inputRef={register()}
                  />
                </Grid>
              </Grid>
            </Card>
          )}
        />
      </Paper>

      <Paper className={classes.paper}>
        <Typography variant="subtitle1">
          <b>Groups</b>
        </Typography>
        <SelectGroupsSubform
          selected={selectedGroups}
          setSelected={setSelectedGroups}
          allGroups={prevGroups}
        />
      </Paper>
    </DialogForm>
  );
};

export default CreateNewStaffDialog;
