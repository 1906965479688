import React, { useEffect, useState } from 'react';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import { StockList } from './StockList';
import { StockView2 } from './StockView2';
import { Grid, MenuItem, Select } from '@material-ui/core';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import HomeIcon from '@material-ui/icons/Home';
import ApiService from '../../../ApiService/ApiService';
import { numberWithCommas } from '../components/common';
import BrokerOMSService from '../../../ApiService/BrokerOMSService';

export const Exchange = () => {
  let { path, url } = useRouteMatch();
  const history = useHistory();

  // Positions
  const [positions, setPositions] = useState([]);

  const [auth, setAuth] = useState(null);
  const timestamp = new Date();

  const [account, setAccount] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [acctPos, setAcctPos] = useState(null);

  useEffect(() => {
    ApiService.getCurrentAccounts(setAccounts);
  }, []);

  // Default to the first available account
  if (account == null && accounts.length > 0) setAccount(accounts[0]);

  const refreshAccountPos = () => {
    if (account) {
      BrokerOMSService.getAcctPositions({ accountNo: account.account_no })
        .then((res) => {
          if (res && res.data) {
            setAcctPos(res.data);
          }
        })
        .catch((err) =>
          console.error(
            'Failed to get account position: ' +
              account.account_no +
              ', error: ' +
              err
          )
        );
    }
  };

  useEffect(() => {
    refreshAccountPos();
    let interval_id = setInterval(refreshAccountPos, 5000);
    return () => clearInterval(interval_id);
  }, [account]);

  return (
    <Grid container>
      <Grid item xs={7}>
        <h1
        // style={{ cursor: 'pointer' }}
        // onClick={() => {
        //   history.push('/exchange');
        // }}
        >
          <HomeIcon />
          Exchange
        </h1>
      </Grid>
      <Grid item xs={2}>
        Account number
        <h2>
          <Select
            id="account_no"
            value={account}
            onChange={(e) => {
              setAccount(e.target.value);
            }}
            displayEmpty={true}
            renderValue={(x) => (x ? x.account_no : 'Select an account...')}
          >
            {accounts.map((x, index) => (
              <MenuItem key={index} value={x}>
                {x.account_no}
              </MenuItem>
            ))}
          </Select>
        </h2>
      </Grid>
      <Grid item xs={2}>
        Purchasing power
        <h2>{numberWithCommas(acctPos ? acctPos.buyingPower : 0, 2)}</h2>
      </Grid>
      <Grid item xs={1}>
        <Button
          color="primary"
          outline
          onClick={() => history.push('/portfolios')}
        >
          <AccountBalanceWalletIcon />
          <span> Accounts </span>
        </Button>
      </Grid>
      <Grid item xs={12} style={{ backgroud: 'black' }}>
        <Switch>
          <Route path={path} exact>
            <StockList
              path={path}
              auth={auth}
              timestamp={timestamp}
              accountNum={account}
            />
          </Route>
          <Route path={`${path}/trade/:ticker`}>
            <StockView2
              path={path}
              auth={auth}
              timestamp={timestamp}
              positions={positions}
              account={account}
              acctPos={acctPos}
            />
          </Route>
        </Switch>
      </Grid>
    </Grid>
  );
};
