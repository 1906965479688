import React from 'react';
import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  TextField,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import api from '../../ApiService/MarketCoreService';
import { toast } from 'react-toastify';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const TransferForm = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    console.log('TransferForm', data);
    setOpen(true);
    try {
      await api.transfer(data.receipient, data.amount, data.refId);
      toast.success(`Transferred [${data.refId}]`);
    } catch (ex) {
      toast.error(`Failed to transfer [${data.refId}]`);
    }
    setOpen(false);
  };

  return (
    <>
      <Grid container>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid item xs={12}>
            <TextField
              required
              inputRef={register}
              id="receipient"
              name="receipient"
              label="Receipient"
              helperText="Enter the account address to transfer tokens to"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              inputRef={register}
              id="amount"
              name="amount"
              label="Amount"
              helperText="Enter the amount of tokens to transfer"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              inputRef={register}
              id="refId"
              name="refId"
              label="Ref ID"
              helperText="Enter a reference ID"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" color="primary" variant="contained">
              Transfer
            </Button>
          </Grid>
        </form>
      </Grid>
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default TransferForm;
