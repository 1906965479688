import React, { useState } from 'react';
import { Button, TextField, Paper } from '@material-ui/core';
import { toast } from 'react-toastify';
import ApiService from '../ApiService/ApiService';
import AccountsManagement from './AccountsManagement';
import ExchangeAdmin from './ExchangeAdmin';

const Bulkloader = () => {
  const [customerFile, setCustomerFile] = useState(null);
  const [staffFile, setStaffFile] = useState(null);
  const [issuerAccount, setIssuerAccount] = useState(null);
  const [update, setUpdate] = useState(false);
  const [reload, setReload] = useState(0);

  const refresh = () => {
    setUpdate(!update);
    setReload(reload + 1);
  };

  const renderBulkloadCustomersInvestor = (
    <p>
      <TextField
        label="File Name"
        type="file"
        onChange={(e) => setCustomerFile(e.target.files[0])}
      />
      <Button
        disabled={!customerFile}
        variant="contained"
        color="primary"
        onClick={() => {
          console.log(customerFile);
          ApiService.bulkloadCustomer(customerFile, 'investors').then(() => {
            toast('Customers bulkloaded');
            refresh();
          });
        }}
      >
        Bulkload Investor
      </Button>
    </p>
  );

  const renderBulkloadCustomersCustodian = (
    <p>
      <TextField
        label="File Name"
        type="file"
        onChange={(e) => setCustomerFile(e.target.files[0])}
      />
      <Button
        disabled={!customerFile}
        variant="contained"
        color="primary"
        onClick={() => {
          console.log(customerFile);
          ApiService.bulkloadCustomer(customerFile, 'custodians').then(() => {
            toast('Customers bulkloaded');
            refresh();
          });
        }}
      >
        Bulkload Custodian
      </Button>
    </p>
  );

  const renderBulkloadCustomersIssuer = (
    <p>
      <TextField
        label="File Name"
        type="file"
        onChange={(e) => setCustomerFile(e.target.files[0])}
      />
      <Button
        disabled={!customerFile}
        variant="contained"
        color="primary"
        onClick={() => {
          console.log(customerFile);
          ApiService.bulkloadCustomer(customerFile, 'issuers').then(() => {
            toast('Customers bulkloaded');
            refresh();
          });
        }}
      >
        Bulkload Issuer
      </Button>
    </p>
  );

  const renderBulkloadStaff = (
    <p>
      <TextField
        label="File Name"
        type="file"
        onChange={(e) => setStaffFile(e.target.files[0])}
      />
      <Button
        disabled={!staffFile}
        variant="contained"
        color="primary"
        onClick={() => {
          console.log(staffFile);
          ApiService.bulkloadStaff(staffFile, 'staffs').then(() => {
            toast('Staff bulkloaded');
            refresh();
          });
        }}
      >
        Bulkload Staff
      </Button>
    </p>
  );

  const renderIssuerAccount = (
    <p>
      <TextField
        label="Issuer account no."
        type="text"
        value={issuerAccount || ''}
        onChange={(e) => setIssuerAccount(e.target.value)}
        required
        fullWidth
      />
    </p>
  );

  const Bulkload = ({ title, func }) => {
    const [file, setFile] = useState(null);

    return (
      <p>
        <TextField
          label="File Name"
          type="file"
          onChange={(e) => setFile(e.target.files[0])}
        />
        <Button
          disabled={!file}
          variant="contained"
          color="primary"
          onClick={() => {
            func(file).then(() => {
              toast(`${title} success`);
            });
          }}
        >
          {title}
        </Button>
      </p>
    );
  };

  const BulkloadByIssuer = ({ title, issuerAccount, func }) => {
    const [file, setFile] = useState(null);

    return (
      <p>
        <TextField
          label="File Name"
          type="file"
          onChange={(e) => setFile(e.target.files[0])}
        />
        <Button
          disabled={!issuerAccount || !file}
          variant="contained"
          color="primary"
          onClick={() => {
            func(issuerAccount, file).then(() => {
              toast(`${title} success`);
            });
          }}
        >
          {title}
        </Button>
      </p>
    );
  };

  const BulkloadStaticFile = () => {
    const title = 'Upload Static File';
    const [file, setFile] = useState(null);
    const [accessKey, setAccessKey] = useState('');
    const [secretKey, setSecretKey] = useState('');
    const [urls, setUrls] = useState([]);

    return (
      <Paper>
        <h3>Static File</h3>
        <p>
          <TextField
            label="Access Key"
            type="accessKey"
            value={accessKey}
            onChange={(e) => setAccessKey(e.target.value)}
          />
          <TextField
            label="Secret Key"
            type="secretKey"
            value={secretKey}
            onChange={(e) => setSecretKey(e.target.value)}
          />
        </p>
        <p>
          <TextField
            label="File Name"
            type="file"
            onChange={(e) => setFile(e.target.files[0])}
          />
          <Button
            disabled={!accessKey || !secretKey || !file}
            variant="contained"
            color="primary"
            onClick={() => {
              ApiService.bulkloadStaticFile(accessKey, secretKey, file)
                .then((resp) => {
                  console.log('bulkloadStaticFile', resp);
                  setUrls(urls.concat(resp.data));
                  toast(`${title} success`);
                })
                .catch((err) =>
                  toast(`${title} failed - ${JSON.stringify(err)}`)
                );
            }}
          >
            {title}
          </Button>
        </p>
        <div>
          <h3>Uploaded History</h3>
          <ul>
            {urls.map((url, i) => (
              <li key={i}>{url}</li>
            ))}
          </ul>
        </div>
      </Paper>
    );
  };

  return (
    <>
      {renderBulkloadCustomersInvestor}
      {renderBulkloadCustomersIssuer}
      {renderBulkloadCustomersCustodian}
      {renderBulkloadStaff}
      <AccountsManagement
        onClick={setIssuerAccount}
        canOpenAccount={true}
        reload={reload}
      />
      {renderIssuerAccount}

      <h3>PRODUCTS</h3>
      <BulkloadByIssuer
        title="Wines"
        issuerAccount={issuerAccount}
        func={ApiService.bulkloadWines}
      />
      <BulkloadByIssuer
        title="Bonds"
        issuerAccount={issuerAccount}
        func={ApiService.bulkloadBonds}
      />
      <BulkloadByIssuer
        title="Bond Coupons"
        issuerAccount={issuerAccount}
        func={ApiService.bulkloadBondCoupons}
      />
      <BulkloadByIssuer
        title="Diamonds"
        issuerAccount={issuerAccount}
        func={ApiService.bulkloadDiamonds}
      />

      <BulkloadStaticFile />

      <h3>COMMON</h3>
      <BulkloadByIssuer
        title="Documents"
        issuerAccount={issuerAccount}
        func={ApiService.bulkloadDocuments}
      />
      <BulkloadByIssuer
        title="Price"
        issuerAccount={issuerAccount}
        func={ApiService.bulkloadPrice}
      />
      <Bulkload title="Spot" func={ApiService.bulkloadSpot} />
      <BulkloadByIssuer
        title="Whitelist"
        issuerAccount={issuerAccount}
        func={ApiService.bulkloadWhitelist}
      />
      <BulkloadByIssuer
        title="Broker Positions"
        issuerAccount={issuerAccount}
        func={ApiService.bulkloadBrokerPositions}
      />
      <Bulkload title="Investor Positions" func={ApiService.bulkloadPosition} />
      <ExchangeAdmin />
    </>
  );
};

export default Bulkloader;
