import React, { useState, useEffect } from 'react';
import RefreshIcon from '@material-ui/icons/Refresh';
import EnhancedTable from '../../EnhancedTableView/EnhancedTable';
import api from 'src/ApiService/BrokerOMSService';
import { Button, TextField } from '@material-ui/core';
import { toast } from 'react-toastify';
import moment from 'moment';

const CashMovements = ({ classes, account, roles }) => {
  const [acctTxns, setAcctTxns] = useState([]);
  const [amount, setAmount] = useState(undefined);
  const [update, setUpdate] = useState(true);
  const [currency, setCurrency] = useState('USD');
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    api.getAcctTxn(account.account_no).then((res) => {
      setAcctTxns(res.data);
    });
  }, [account, update]);

  const refresh = () => {
    setUpdate(!update);
  };

  const approve = (accountNo, txnId, comment) => {
    api
      .approve(accountNo, txnId, true, comment)
      .then(() => {
        refresh();
      })
      .catch(() => {
        toast.error(`Failed to approve transaction [${txnId}]`);
      });
  };

  const reject = (accountNo, txnId, comment) => {
    api
      .approve(accountNo, txnId, false, comment)
      .then(() => {
        refresh();
      })
      .catch(() => {
        toast.error(`Failed to reject transaction [${txnId}]`);
      });
  };

  const renderStatus = (txn) => {
    const status = txn.transactionStatus;
    return (
      <>
        {status}{' '}
        {roles?.includes('broker') && status === 'New' && (
          <>
            <Button
              color="primary"
              onClick={() => approve(account.account_no, txn.id, null)}
            >
              Approve
            </Button>
            <Button
              color="secondary"
              onClick={() => reject(account.account_no, txn.id, null)}
            >
              Reject
            </Button>
          </>
        )}
      </>
    );
  };

  const columns = [
    {
      id: 'id',
      numeric: false,
      disablePadding: false,
      label: 'ID',
      valueOf: (x) => x.id,
    },
    {
      id: 'type',
      numeric: false,
      disablePadding: false,
      label: 'Type',
      valueOf: (x) => x.transactionType,
    },
    {
      id: 'currency',
      numeric: false,
      disablePadding: false,
      label: 'Curreny',
      valueOf: (x) => x.currency,
    },
    {
      id: 'amount',
      numeric: true,
      disablePadding: false,
      label: 'Amount',
      valueOf: (x) => x.price && x.price.toLocaleString(),
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: 'Status',
      valueOf: (x) => renderStatus(x),
    },
    // {
    //   id: 'txnDate',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'Transaction Date',
    //   valueOf: (x) => x.txnDate,
    //   displayValueOf: (x) => moment(x.txnDate).format('ll'),
    // },
    {
      id: 'creationTime',
      numeric: false,
      disablePadding: false,
      label: 'Transaction Date',
      valueOf: (x) => x.creationTime,
      displayValueOf: (x) =>
        moment(x.creationTime).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      id: 'comment',
      numeric: false,
      disablePadding: false,
      label: 'Comment',
      valueOf: (x) => x.comment,
    },
  ];

  const operations = [
    {
      label: 'refresh',
      icon: <RefreshIcon />,
      handleClick: refresh,
    },
  ];

  const investorPanel = (
    <>
      <TextField
        type="text"
        value={amount}
        onChange={(e) => {
          setAmount(parseFloat(e.target.value));
        }}
      />
      {currency}
      <Button
        variant="outlined"
        color="primary"
        disabled={!account}
        onClick={() => {
          api
            .deposit(account.account_no, currency, amount)
            .then((res) => {
              refresh();
            })
            .catch((err) => {
              toast.error('Requested deposit of ' + amount + ' failed');
            });
        }}
      >
        Deposit
      </Button>
      <Button
        variant="outlined"
        color="primary"
        disabled={!account}
        onClick={() => {
          api
            .withdraw(account.account_no, currency, amount)
            .then((res) => {
              refresh();
            })
            .catch((err) => {
              toast.error('Requested withdrawal of ' + amount + ' failed');
            });
        }}
      >
        Withdraw
      </Button>
    </>
  );

  return (
    acctTxns && (
      <>
        <EnhancedTable
          classes={classes}
          columnDefs={columns}
          operationDefs={operations}
          dataList={acctTxns}
          selected={selected}
          setSelected={setSelected}
          actionDefs={[]}
          title="Cash Movements"
          initialOrder="desc"
          initialOrderBy={columns.find((x) => x.id === 'creationTime')}
        />
        {roles?.includes('investor') && investorPanel}
      </>
    )
  );
};

export default CashMovements;
