import axios from 'axios'; // chrome.exe --user-data-dir="C:/Chrome dev session" --disable-web-security
import { handleError, onResolved, handleSpecificError } from './Utilities';

const API = axios.create({
  baseURL: window['config'].apiBaseUrl.applicationWfManager,
});

const WfManagerService = {
  setWfManangerServiceAuth(auth) {
    API.defaults.headers.common['Authorization'] = auth;
  },

  getWorkflowInstances(setter) {
    return API.get()
      .then((response) => {
        let WorkflowInstances = response.data;
        console.log('StaffWorkflowInstances info', WorkflowInstances);
        setter && setter(WorkflowInstances);
        return WorkflowInstances;
      })
      .catch(handleError);
  },

  getCurrentProcessState(setter, WorkflowInstancesId) {
    return API.get(`${WorkflowInstancesId}/ProcessStateInstances/current`)
      .then((response) => {
        let CurrentProcessState = response.data;
        console.log('CurrentProcessState info', CurrentProcessState);
        setter && setter(CurrentProcessState);
        return CurrentProcessState;
      })
      .catch(handleError);
  },

  getAvailableActions(setter, workflowInstanceID, ProcessInstanceID) {
    return API.get(`AvailableAction/${workflowInstanceID}/${ProcessInstanceID}`)
      .then(onResolved('got available action', setter))
      .catch(handleError);
  },

  createWorkflow(setter, userID, actionID, body) {
    return API.post(`${userID}/${actionID}`, body)
      .then(onResolved('create workflow', setter))
      .catch(handleError);
  },

  triggerProcessStateTransition(
    setter,
    WorkflowInstancesId,
    ProcessStateID,
    body
  ) {
    return API.post(
      `AvailableAction/${WorkflowInstancesId}/${ProcessStateID}`,
      body
    )
      .then(onResolved('trigger ProcessState Transition', setter))
      .catch(handleSpecificError(400, 'Only staff can do that action!'));
  },

  uploadDocument(setter, processInstaceID, file) {
    const formData = new FormData();
    formData.append('file', file);
    return API.post(`ApplicationDocs/${processInstaceID}`, formData)
      .then(onResolved('uploaded Document', setter))
      .catch(handleError);
  },

  addStaffToUserGroup(setter, body) {
    return API.post(`AssignUserGroup/`, body)
      .then(onResolved('added user to user to userGroup', setter))
      .catch(handleError);
  },

  getControlParams(setter) {
    return API.get(`ControlParams/`)
      .then(onResolved('got control params', setter))
      .catch(handleError);
  },
  updateControlParams(setter, id, body) {
    return API.put(`ControlParams/${id}`, body)
      .then(onResolved('set control params', setter))
      .catch(handleError);
  },
};

export default WfManagerService;
