import React from 'react';
import PropTypes from 'prop-types';

import { format } from 'd3-format';
import { scaleLinear } from 'd3-scale';
import { curveStep } from 'd3-shape';

import { ChartCanvas, Chart } from 'react-stockcharts';
import { AreaSeries } from 'react-stockcharts/lib/series';
import { XAxis, YAxis } from 'react-stockcharts/lib/axes';
import { fitWidth } from 'react-stockcharts/lib/helper';
import {
  createVerticalLinearGradient,
  hexToRGBA,
} from 'react-stockcharts/lib/utils';
import MouseCoordinateX from 'react-stockcharts/lib/coordinates/MouseCoordinateX';
import MouseCoordinateY from 'react-stockcharts/lib/coordinates/MouseCoordinateY';
import CrossHairCursor from 'react-stockcharts/lib/coordinates/CrossHairCursor';

const canvasGradient = createVerticalLinearGradient([
  { stop: 0, color: hexToRGBA('#b5d0ff', 0.2) },
  { stop: 0.7, color: hexToRGBA('#6fa4fc', 0.4) },
  { stop: 1, color: hexToRGBA('#4286f4', 0.8) },
]);

class DepthChart extends React.Component {
  render() {
    const {
      price,
      data: initData,
      type,
      height,
      width,
      ratio,
      precision,
      depth,
      axisColor = 'black',
      bidColor,
    } = this.props;

    // Clip data to range
    const diff = Math.pow(0.1, precision) * depth;
    const xMin = price - diff;
    const xMax = price + diff;
    const data = initData.filter((x) => x.price >= xMin && x.price <= xMax);

    if (data.length < 2)
      return <div style={{ textAlign: 'center' }}>No data</div>;

    return (
      <ChartCanvas
        ratio={ratio}
        width={width}
        height={height}
        margin={{ left: 50, right: 50, top: 10, bottom: 30 }}
        seriesName="MSFT"
        data={data}
        type={type}
        xAccessor={(d) => d.price}
        xScale={scaleLinear()}
        xExtents={[xMin, xMax]}
        mouseMoveEvent={true}
        panEvent={false}
        zoomEvent={true}
      >
        <Chart id={0} yExtents={(d) => [d.ask, d.bid]}>
          <defs>
            <linearGradient id="MyGradient1" x1="0" y1="100%" x2="0" y2="0%">
              <stop offset="0%" stopColor={bidColor} stopOpacity={0.1} />
              <stop offset="70%" stopColor={bidColor} stopOpacity={0.2} />
              <stop offset="100%" stopColor={bidColor} stopOpacity={0.3} />
            </linearGradient>
            <linearGradient id="MyGradient2" x1="0" y1="100%" x2="0" y2="0%">
              <stop offset="0%" stopColor="red" stopOpacity={0.1} />
              <stop offset="70%" stopColor="red" stopOpacity={0.2} />
              <stop offset="100%" stopColor="red" stopOpacity={0.3} />
            </linearGradient>
          </defs>
          <XAxis
            axisAt="bottom"
            orient="bottom"
            ticks={6}
            stroke={axisColor}
            tickStroke={axisColor}
          />
          <YAxis
            axisAt="left"
            orient="left"
            stroke={axisColor}
            tickStroke={axisColor}
          />
          <AreaSeries
            yAccessor={(d) => d.bid}
            fill="url(#MyGradient1)"
            strokeWidth={1}
            interpolation={curveStep}
            canvasGradient={canvasGradient}
            stroke={bidColor}
          />
          <AreaSeries
            yAccessor={(d) => d.ask}
            fill="url(#MyGradient2)"
            strokeWidth={1}
            interpolation={curveStep}
            canvasGradient={canvasGradient}
            stroke="red"
          />
          <MouseCoordinateX
            at="bottom"
            orient="bottom"
            displayFormat={format('.4s')}
          />
          <MouseCoordinateY
            at="left"
            orient="left"
            displayFormat={format('.4s')}
          />
        </Chart>
        <CrossHairCursor />
      </ChartCanvas>
    );
  }
}

DepthChart.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  ratio: PropTypes.number.isRequired,
  type: PropTypes.oneOf(['svg', 'hybrid']).isRequired,
};

DepthChart.defaultProps = {
  type: 'svg',
};
DepthChart = fitWidth(DepthChart);

export default DepthChart;
