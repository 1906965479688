import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';

import GetAppIcon from '@material-ui/icons/GetApp';
import RefreshIcon from '@material-ui/icons/Refresh';
import VisibilityIcon from '@material-ui/icons/Visibility';

import ApiService from '../../ApiService/ApiService';

import BuyDialog from '../BondMarket/BuyDialog';
import DetailInfo from '../BondMarket/DetailInfo';
import EnhancedTable from '../EnhancedTableView/EnhancedTable';
import InfoDrawer from '../DetailedInfo/InfoDrawer';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

function BondView() {
  const [bondList, setBondList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [infoData, setInfoData] = useState(null);
  const [buyItem, setBuyItem] = useState(null);
  const classes = useStyles();

  const [updateFlag, setUpdateFlag] = useState(false);
  const update = () => setUpdateFlag((old) => !old);
  const handleUpdate = () => {
    let mounted = true;
    ApiService.getBonds().then((res) => {
      if (mounted && res.data) {
        var bonds = res.data.filter((x) => x.issueSize > 0);
        setBondList(bonds);
        infoData &&
          setInfoData((old) => bonds.find((doc) => doc.id === old.id));
      }
    });

    return () => {
      mounted = false;
    };
  };

  useEffect(handleUpdate, [updateFlag]);

  console.log('bonds', bondList);

  const columns = [
    {
      id: 'id',
      numeric: false,
      disablePadding: false,
      label: 'SecKey',
      valueOf: (bond) => bond.key,
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: false,
      label: 'Description',
      valueOf: (bond) => bond.description,
    },
    {
      id: 'lotSize',
      numeric: false,
      disablePadding: false,
      label: 'Lot Size',
      valueOf: (bond) => bond.lotSize,
    },
    {
      id: 'brokerId',
      numeric: false,
      disablePadding: false,
      label: 'Broker',
      valueOf: (bond) => bond.brokerId,
    },
    {
      id: 'issuer',
      numeric: false,
      disablePadding: false,
      label: 'Issuer',
      valueOf: (bond) => bond.issuer,
    },
    {
      id: 'coupon',
      numeric: false,
      disablePadding: false,
      label: 'Coupon',
      valueOf: (bond) => (bond.coupon * 100).toFixed(2) + '%',
    },
    {
      id: 'fx',
      numeric: false,
      disablePadding: false,
      label: 'FX Rate',
      valueOf: (bond) => bond.fxRate.toFixed(2),
    },
    {
      id: 'facevalue',
      numeric: false,
      disablePadding: false,
      label: 'Face Value',
      valueOf: (bond) => bond.faceValue + ' (' + bond.faceValueCcy + ')',
    },
    {
      id: 'ccy',
      numeric: false,
      disablePadding: false,
      label: 'Contract Currency',
      valueOf: (bond) => bond.ccy,
    },
    {
      id: 'issueSize',
      numeric: false,
      disablePadding: false,
      label: 'Issue Size',
      valueOf: (bond) => bond.issueSize,
    },
    {
      id: 'riskRating',
      numeric: false,
      disablePadding: false,
      label: 'Risk Rating',
      valueOf: (bond) => bond.riskRating,
    },
    {
      id: 'maturity',
      numeric: false,
      disablePadding: false,
      label: 'Maturity',
      valueOf: (bond) => bond.maturity,
      displayValueOf: (bond) => moment(bond.maturity).format('ll'),
    },
    {
      id: 'listing',
      numeric: false,
      disablePadding: false,
      label: 'Listing Date',
      valueOf: (bond) => bond.listingDate,
      displayValueOf: (bond) => moment(bond.listingDate).format('ll'),
    },
    {
      id: 'drawdown',
      numeric: false,
      disablePadding: false,
      label: 'Drawdown Date',
      valueOf: (bond) => bond.drawdownDate,
      displayValueOf: (bond) => moment(bond.drawdownDate).format('ll'),
    },
    {
        id: 'securedAssets',
        numeric: false,
        disablePadding: false,
        label: 'Secured Assets',
        valueOf: (bond) => bond.securedAssets,
    },    
  ];
  const opDefs = [
    {
      label: 'refresh',
      icon: <RefreshIcon />,
      handleClick: update,
    },
  ];
  const actionDefs = [
    {
      label: 'View',
      icon: <VisibilityIcon />,
      handleClick: setInfoData,
      show: 'cell',
    },
    // {
    //   label: 'Buy',
    //   icon: <GetAppIcon />,
    //   handleClick: (x) => {
    //     //toast.info('Buy ' + x.name);
    //     setBuyItem(x);
    //   },
    //   show: 'both',
    // },
  ];

  return (
    <>
      <EnhancedTable
        classes={classes}
        columnDefs={columns}
        operationDefs={opDefs}
        dataList={bondList}
        selected={selected}
        setSelected={setSelected}
        actionDefs={actionDefs}
        title="Products"
        valueOfprimaryKey={(obj) => obj.sec_key}
      />
      {infoData && (
        <InfoDrawer
          // style={{ width: '50%' }}
          open={infoData !== null}
          handleClose={() => setInfoData(null)}
          title="Product Detail"
        >
          <DetailInfo data={infoData} update={update} />
        </InfoDrawer>
      )}
      {buyItem && (
        <BuyDialog
          bondList={bondList}
          bond={buyItem}
          open={buyItem != null}
          closeDialog={() => setBuyItem(null)}
          update={update}
        />
      )}
    </>
  );
}

export default BondView;
