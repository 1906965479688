import React from 'react';
import {
  Input,
  FormHelperText,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import { ErrorMessage } from '@hookform/error-message';

function TextFieldWithValidation({
  errors,
  label,
  name,
  required,
  requiredInLabel,
  shrink,
  ...textFeieldProps
}) {
  return (
    <FormControl fullWidth={textFeieldProps.fullWidth === true}>
      <InputLabel
        required={required || requiredInLabel}
        error={textFeieldProps.error}
        shrink={shrink}
      >
        {label}
      </InputLabel>
      <Input
        type="text"
        name={name}
        required={required}
        {...textFeieldProps}
        aria-describedby={`${name}-error-text`}
      />
      <ErrorMessage errors={errors} name={name} as={<FormHelperText error />} />
    </FormControl>
  );
}

export default TextFieldWithValidation;
