import React, { useState } from 'react';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import api from '../../ApiService/MarketCoreService';

const HasCustodianRoleForm = () => {
  const { register, handleSubmit } = useForm();
  const [result, setResult] = useState(null);

  const onSubmit = async (data) => {
    console.log('HasCustodianRoleForm', data);
    setResult((await api.hasCustodianRole(data.address)).data);
  };

  console.log('hasCustodianRole', result);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
        {result != null && (
          <Grid item xs={12}>
            <Typography style={{ color: result.hasCustodianRole ? 'green' : 'red' }}>
              Account {result.address} is {!result.hasCustodianRole && 'not '}a custodian 
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            required
            inputRef={register}
            id="address"
            label="Address"
            name="address"
            helperText="Enter the account address to check if it has a custodian role"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" color="primary" variant="contained">
            Check Custodian Role
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default HasCustodianRoleForm;
