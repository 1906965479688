import React, { useState } from 'react';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import api from '../../ApiService/MarketCoreService';

const CheckTokenBalanceForm = () => {
  const { register, handleSubmit } = useForm();
  const [tokenBalance, setTokenBalance] = useState(null);

  const onSubmit = async (data) => {
    console.log('CheckTokenBalanceForm', data);
    setTokenBalance((await api.checktokenBalanceCustodian(data.address)).data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
        {tokenBalance != null && (
          <Grid item xs={12}>
            <Typography>
              Token Balance: {tokenBalance.balance} PTC [{tokenBalance.account}]
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            required
            inputRef={register}
            id="address"
            label="Address"
            name="address"
            helperText="Enter the account address to check balance"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" color="primary" variant="contained">
            Check Balance
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default CheckTokenBalanceForm;
