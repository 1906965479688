/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
var proto = {
  Exchange: {
    GRPC: {}
  }
}
var COMPILED;

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.exportSymbol('proto.Exchange.GRPC.BrokerRequest', null, global);
goog.exportSymbol('proto.Exchange.GRPC.MarketUpdate', null, global);
goog.exportSymbol('proto.Exchange.GRPC.Message', null, global);
goog.exportSymbol('proto.Exchange.GRPC.Order', null, global);
goog.exportSymbol('proto.Exchange.GRPC.Ticker', null, global);
goog.exportSymbol('proto.Exchange.GRPC.TickerRequest', null, global);
goog.exportSymbol('proto.Exchange.GRPC.TickerUpdate', null, global);
goog.exportSymbol('proto.Exchange.GRPC.TradableTicker', null, global);
goog.exportSymbol('proto.Exchange.GRPC.TradeHistory', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Exchange.GRPC.BrokerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Exchange.GRPC.BrokerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.Exchange.GRPC.BrokerRequest.displayName = 'proto.Exchange.GRPC.BrokerRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Exchange.GRPC.BrokerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Exchange.GRPC.BrokerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Exchange.GRPC.BrokerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Exchange.GRPC.BrokerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestBrokerId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Exchange.GRPC.BrokerRequest}
 */
proto.Exchange.GRPC.BrokerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Exchange.GRPC.BrokerRequest;
  return proto.Exchange.GRPC.BrokerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Exchange.GRPC.BrokerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Exchange.GRPC.BrokerRequest}
 */
proto.Exchange.GRPC.BrokerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestBrokerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Exchange.GRPC.BrokerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Exchange.GRPC.BrokerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Exchange.GRPC.BrokerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Exchange.GRPC.BrokerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestBrokerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string request_broker_id = 1;
 * @return {string}
 */
proto.Exchange.GRPC.BrokerRequest.prototype.getRequestBrokerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.Exchange.GRPC.BrokerRequest.prototype.setRequestBrokerId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Exchange.GRPC.Message = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Exchange.GRPC.Message, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.Exchange.GRPC.Message.displayName = 'proto.Exchange.GRPC.Message';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Exchange.GRPC.Message.prototype.toObject = function(opt_includeInstance) {
  return proto.Exchange.GRPC.Message.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Exchange.GRPC.Message} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Exchange.GRPC.Message.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Exchange.GRPC.Message}
 */
proto.Exchange.GRPC.Message.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Exchange.GRPC.Message;
  return proto.Exchange.GRPC.Message.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Exchange.GRPC.Message} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Exchange.GRPC.Message}
 */
proto.Exchange.GRPC.Message.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Exchange.GRPC.Message.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Exchange.GRPC.Message.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Exchange.GRPC.Message} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Exchange.GRPC.Message.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string text = 1;
 * @return {string}
 */
proto.Exchange.GRPC.Message.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.Exchange.GRPC.Message.prototype.setText = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Exchange.GRPC.TradableTicker = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Exchange.GRPC.TradableTicker.repeatedFields_, null);
};
goog.inherits(proto.Exchange.GRPC.TradableTicker, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.Exchange.GRPC.TradableTicker.displayName = 'proto.Exchange.GRPC.TradableTicker';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Exchange.GRPC.TradableTicker.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Exchange.GRPC.TradableTicker.prototype.toObject = function(opt_includeInstance) {
  return proto.Exchange.GRPC.TradableTicker.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Exchange.GRPC.TradableTicker} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Exchange.GRPC.TradableTicker.toObject = function(includeInstance, msg) {
  var f, obj = {
    tradableTickersList: jspb.Message.toObjectList(msg.getTradableTickersList(),
    proto.Exchange.GRPC.Ticker.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Exchange.GRPC.TradableTicker}
 */
proto.Exchange.GRPC.TradableTicker.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Exchange.GRPC.TradableTicker;
  return proto.Exchange.GRPC.TradableTicker.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Exchange.GRPC.TradableTicker} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Exchange.GRPC.TradableTicker}
 */
proto.Exchange.GRPC.TradableTicker.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Exchange.GRPC.Ticker;
      reader.readMessage(value,proto.Exchange.GRPC.Ticker.deserializeBinaryFromReader);
      msg.addTradableTickers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Exchange.GRPC.TradableTicker.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Exchange.GRPC.TradableTicker.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Exchange.GRPC.TradableTicker} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Exchange.GRPC.TradableTicker.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTradableTickersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Exchange.GRPC.Ticker.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Ticker tradable_tickers = 1;
 * @return {!Array<!proto.Exchange.GRPC.Ticker>}
 */
proto.Exchange.GRPC.TradableTicker.prototype.getTradableTickersList = function() {
  return /** @type{!Array<!proto.Exchange.GRPC.Ticker>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Exchange.GRPC.Ticker, 1));
};


/** @param {!Array<!proto.Exchange.GRPC.Ticker>} value */
proto.Exchange.GRPC.TradableTicker.prototype.setTradableTickersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Exchange.GRPC.Ticker=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Exchange.GRPC.Ticker}
 */
proto.Exchange.GRPC.TradableTicker.prototype.addTradableTickers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Exchange.GRPC.Ticker, opt_index);
};


proto.Exchange.GRPC.TradableTicker.prototype.clearTradableTickersList = function() {
  this.setTradableTickersList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Exchange.GRPC.TickerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Exchange.GRPC.TickerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.Exchange.GRPC.TickerRequest.displayName = 'proto.Exchange.GRPC.TickerRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Exchange.GRPC.TickerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Exchange.GRPC.TickerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Exchange.GRPC.TickerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Exchange.GRPC.TickerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestBrokerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    symbol: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Exchange.GRPC.TickerRequest}
 */
proto.Exchange.GRPC.TickerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Exchange.GRPC.TickerRequest;
  return proto.Exchange.GRPC.TickerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Exchange.GRPC.TickerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Exchange.GRPC.TickerRequest}
 */
proto.Exchange.GRPC.TickerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestBrokerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Exchange.GRPC.TickerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Exchange.GRPC.TickerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Exchange.GRPC.TickerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Exchange.GRPC.TickerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestBrokerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string request_broker_id = 1;
 * @return {string}
 */
proto.Exchange.GRPC.TickerRequest.prototype.getRequestBrokerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.Exchange.GRPC.TickerRequest.prototype.setRequestBrokerId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string symbol = 2;
 * @return {string}
 */
proto.Exchange.GRPC.TickerRequest.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.Exchange.GRPC.TickerRequest.prototype.setSymbol = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Exchange.GRPC.Ticker = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Exchange.GRPC.Ticker, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.Exchange.GRPC.Ticker.displayName = 'proto.Exchange.GRPC.Ticker';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Exchange.GRPC.Ticker.prototype.toObject = function(opt_includeInstance) {
  return proto.Exchange.GRPC.Ticker.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Exchange.GRPC.Ticker} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Exchange.GRPC.Ticker.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbol: jspb.Message.getFieldWithDefault(msg, 1, ""),
    issuerBroker: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lastPrice: +jspb.Message.getFieldWithDefault(msg, 4, 0.0),
    marketCap: +jspb.Message.getFieldWithDefault(msg, 5, 0.0),
    volume24h: +jspb.Message.getFieldWithDefault(msg, 6, 0.0),
    change24h: +jspb.Message.getFieldWithDefault(msg, 7, 0.0),
    high24h: +jspb.Message.getFieldWithDefault(msg, 8, 0.0),
    low24h: +jspb.Message.getFieldWithDefault(msg, 9, 0.0),
    time: (f = msg.getTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Exchange.GRPC.Ticker}
 */
proto.Exchange.GRPC.Ticker.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Exchange.GRPC.Ticker;
  return proto.Exchange.GRPC.Ticker.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Exchange.GRPC.Ticker} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Exchange.GRPC.Ticker}
 */
proto.Exchange.GRPC.Ticker.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIssuerBroker(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLastPrice(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMarketCap(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setVolume24h(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setChange24h(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHigh24h(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLow24h(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Exchange.GRPC.Ticker.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Exchange.GRPC.Ticker.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Exchange.GRPC.Ticker} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Exchange.GRPC.Ticker.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIssuerBroker();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLastPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getMarketCap();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getVolume24h();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getChange24h();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getHigh24h();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getLow24h();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getTime();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string symbol = 1;
 * @return {string}
 */
proto.Exchange.GRPC.Ticker.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.Exchange.GRPC.Ticker.prototype.setSymbol = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string issuer_broker = 2;
 * @return {string}
 */
proto.Exchange.GRPC.Ticker.prototype.getIssuerBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.Exchange.GRPC.Ticker.prototype.setIssuerBroker = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.Exchange.GRPC.Ticker.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.Exchange.GRPC.Ticker.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double last_price = 4;
 * @return {number}
 */
proto.Exchange.GRPC.Ticker.prototype.getLastPrice = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 4, 0.0));
};


/** @param {number} value */
proto.Exchange.GRPC.Ticker.prototype.setLastPrice = function(value) {
  jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double market_cap = 5;
 * @return {number}
 */
proto.Exchange.GRPC.Ticker.prototype.getMarketCap = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 5, 0.0));
};


/** @param {number} value */
proto.Exchange.GRPC.Ticker.prototype.setMarketCap = function(value) {
  jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double volume24h = 6;
 * @return {number}
 */
proto.Exchange.GRPC.Ticker.prototype.getVolume24h = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 6, 0.0));
};


/** @param {number} value */
proto.Exchange.GRPC.Ticker.prototype.setVolume24h = function(value) {
  jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double change24h = 7;
 * @return {number}
 */
proto.Exchange.GRPC.Ticker.prototype.getChange24h = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 7, 0.0));
};


/** @param {number} value */
proto.Exchange.GRPC.Ticker.prototype.setChange24h = function(value) {
  jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double high24h = 8;
 * @return {number}
 */
proto.Exchange.GRPC.Ticker.prototype.getHigh24h = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 8, 0.0));
};


/** @param {number} value */
proto.Exchange.GRPC.Ticker.prototype.setHigh24h = function(value) {
  jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double low24h = 9;
 * @return {number}
 */
proto.Exchange.GRPC.Ticker.prototype.getLow24h = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 9, 0.0));
};


/** @param {number} value */
proto.Exchange.GRPC.Ticker.prototype.setLow24h = function(value) {
  jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional google.protobuf.Timestamp time = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Exchange.GRPC.Ticker.prototype.getTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.Exchange.GRPC.Ticker.prototype.setTime = function(value) {
  jspb.Message.setWrapperField(this, 10, value);
};


proto.Exchange.GRPC.Ticker.prototype.clearTime = function() {
  this.setTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.Exchange.GRPC.Ticker.prototype.hasTime = function() {
  return jspb.Message.getField(this, 10) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Exchange.GRPC.MarketUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Exchange.GRPC.MarketUpdate.repeatedFields_, null);
};
goog.inherits(proto.Exchange.GRPC.MarketUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.Exchange.GRPC.MarketUpdate.displayName = 'proto.Exchange.GRPC.MarketUpdate';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Exchange.GRPC.MarketUpdate.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Exchange.GRPC.MarketUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.Exchange.GRPC.MarketUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Exchange.GRPC.MarketUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Exchange.GRPC.MarketUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    allTickersList: jspb.Message.toObjectList(msg.getAllTickersList(),
    proto.Exchange.GRPC.Ticker.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Exchange.GRPC.MarketUpdate}
 */
proto.Exchange.GRPC.MarketUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Exchange.GRPC.MarketUpdate;
  return proto.Exchange.GRPC.MarketUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Exchange.GRPC.MarketUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Exchange.GRPC.MarketUpdate}
 */
proto.Exchange.GRPC.MarketUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Exchange.GRPC.Ticker;
      reader.readMessage(value,proto.Exchange.GRPC.Ticker.deserializeBinaryFromReader);
      msg.addAllTickers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Exchange.GRPC.MarketUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Exchange.GRPC.MarketUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Exchange.GRPC.MarketUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Exchange.GRPC.MarketUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAllTickersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Exchange.GRPC.Ticker.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Ticker all_tickers = 1;
 * @return {!Array<!proto.Exchange.GRPC.Ticker>}
 */
proto.Exchange.GRPC.MarketUpdate.prototype.getAllTickersList = function() {
  return /** @type{!Array<!proto.Exchange.GRPC.Ticker>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Exchange.GRPC.Ticker, 1));
};


/** @param {!Array<!proto.Exchange.GRPC.Ticker>} value */
proto.Exchange.GRPC.MarketUpdate.prototype.setAllTickersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Exchange.GRPC.Ticker=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Exchange.GRPC.Ticker}
 */
proto.Exchange.GRPC.MarketUpdate.prototype.addAllTickers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Exchange.GRPC.Ticker, opt_index);
};


proto.Exchange.GRPC.MarketUpdate.prototype.clearAllTickersList = function() {
  this.setAllTickersList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Exchange.GRPC.TickerUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Exchange.GRPC.TickerUpdate.repeatedFields_, null);
};
goog.inherits(proto.Exchange.GRPC.TickerUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.Exchange.GRPC.TickerUpdate.displayName = 'proto.Exchange.GRPC.TickerUpdate';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Exchange.GRPC.TickerUpdate.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Exchange.GRPC.TickerUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.Exchange.GRPC.TickerUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Exchange.GRPC.TickerUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Exchange.GRPC.TickerUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    singleTicker: (f = msg.getSingleTicker()) && proto.Exchange.GRPC.Ticker.toObject(includeInstance, f),
    time: (f = msg.getTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    tradeHistoryList: jspb.Message.toObjectList(msg.getTradeHistoryList(),
    proto.Exchange.GRPC.TradeHistory.toObject, includeInstance),
    ordersList: jspb.Message.toObjectList(msg.getOrdersList(),
    proto.Exchange.GRPC.Order.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Exchange.GRPC.TickerUpdate}
 */
proto.Exchange.GRPC.TickerUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Exchange.GRPC.TickerUpdate;
  return proto.Exchange.GRPC.TickerUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Exchange.GRPC.TickerUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Exchange.GRPC.TickerUpdate}
 */
proto.Exchange.GRPC.TickerUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Exchange.GRPC.Ticker;
      reader.readMessage(value,proto.Exchange.GRPC.Ticker.deserializeBinaryFromReader);
      msg.setSingleTicker(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTime(value);
      break;
    case 3:
      var value = new proto.Exchange.GRPC.TradeHistory;
      reader.readMessage(value,proto.Exchange.GRPC.TradeHistory.deserializeBinaryFromReader);
      msg.addTradeHistory(value);
      break;
    case 4:
      var value = new proto.Exchange.GRPC.Order;
      reader.readMessage(value,proto.Exchange.GRPC.Order.deserializeBinaryFromReader);
      msg.addOrders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Exchange.GRPC.TickerUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Exchange.GRPC.TickerUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Exchange.GRPC.TickerUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Exchange.GRPC.TickerUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSingleTicker();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Exchange.GRPC.Ticker.serializeBinaryToWriter
    );
  }
  f = message.getTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTradeHistoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.Exchange.GRPC.TradeHistory.serializeBinaryToWriter
    );
  }
  f = message.getOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.Exchange.GRPC.Order.serializeBinaryToWriter
    );
  }
};


/**
 * optional Ticker single_ticker = 1;
 * @return {?proto.Exchange.GRPC.Ticker}
 */
proto.Exchange.GRPC.TickerUpdate.prototype.getSingleTicker = function() {
  return /** @type{?proto.Exchange.GRPC.Ticker} */ (
    jspb.Message.getWrapperField(this, proto.Exchange.GRPC.Ticker, 1));
};


/** @param {?proto.Exchange.GRPC.Ticker|undefined} value */
proto.Exchange.GRPC.TickerUpdate.prototype.setSingleTicker = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.Exchange.GRPC.TickerUpdate.prototype.clearSingleTicker = function() {
  this.setSingleTicker(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.Exchange.GRPC.TickerUpdate.prototype.hasSingleTicker = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp time = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Exchange.GRPC.TickerUpdate.prototype.getTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.Exchange.GRPC.TickerUpdate.prototype.setTime = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


proto.Exchange.GRPC.TickerUpdate.prototype.clearTime = function() {
  this.setTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.Exchange.GRPC.TickerUpdate.prototype.hasTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated TradeHistory trade_history = 3;
 * @return {!Array<!proto.Exchange.GRPC.TradeHistory>}
 */
proto.Exchange.GRPC.TickerUpdate.prototype.getTradeHistoryList = function() {
  return /** @type{!Array<!proto.Exchange.GRPC.TradeHistory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Exchange.GRPC.TradeHistory, 3));
};


/** @param {!Array<!proto.Exchange.GRPC.TradeHistory>} value */
proto.Exchange.GRPC.TickerUpdate.prototype.setTradeHistoryList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.Exchange.GRPC.TradeHistory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Exchange.GRPC.TradeHistory}
 */
proto.Exchange.GRPC.TickerUpdate.prototype.addTradeHistory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.Exchange.GRPC.TradeHistory, opt_index);
};


proto.Exchange.GRPC.TickerUpdate.prototype.clearTradeHistoryList = function() {
  this.setTradeHistoryList([]);
};


/**
 * repeated Order orders = 4;
 * @return {!Array<!proto.Exchange.GRPC.Order>}
 */
proto.Exchange.GRPC.TickerUpdate.prototype.getOrdersList = function() {
  return /** @type{!Array<!proto.Exchange.GRPC.Order>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Exchange.GRPC.Order, 4));
};


/** @param {!Array<!proto.Exchange.GRPC.Order>} value */
proto.Exchange.GRPC.TickerUpdate.prototype.setOrdersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.Exchange.GRPC.Order=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Exchange.GRPC.Order}
 */
proto.Exchange.GRPC.TickerUpdate.prototype.addOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.Exchange.GRPC.Order, opt_index);
};


proto.Exchange.GRPC.TickerUpdate.prototype.clearOrdersList = function() {
  this.setOrdersList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Exchange.GRPC.TradeHistory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Exchange.GRPC.TradeHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.Exchange.GRPC.TradeHistory.displayName = 'proto.Exchange.GRPC.TradeHistory';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Exchange.GRPC.TradeHistory.prototype.toObject = function(opt_includeInstance) {
  return proto.Exchange.GRPC.TradeHistory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Exchange.GRPC.TradeHistory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Exchange.GRPC.TradeHistory.toObject = function(includeInstance, msg) {
  var f, obj = {
    price: +jspb.Message.getFieldWithDefault(msg, 1, 0.0),
    amount: +jspb.Message.getFieldWithDefault(msg, 2, 0.0),
    total: +jspb.Message.getFieldWithDefault(msg, 3, 0.0),
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    diff: +jspb.Message.getFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Exchange.GRPC.TradeHistory}
 */
proto.Exchange.GRPC.TradeHistory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Exchange.GRPC.TradeHistory;
  return proto.Exchange.GRPC.TradeHistory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Exchange.GRPC.TradeHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Exchange.GRPC.TradeHistory}
 */
proto.Exchange.GRPC.TradeHistory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotal(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDiff(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Exchange.GRPC.TradeHistory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Exchange.GRPC.TradeHistory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Exchange.GRPC.TradeHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Exchange.GRPC.TradeHistory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDiff();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
};


/**
 * optional double price = 1;
 * @return {number}
 */
proto.Exchange.GRPC.TradeHistory.prototype.getPrice = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 1, 0.0));
};


/** @param {number} value */
proto.Exchange.GRPC.TradeHistory.prototype.setPrice = function(value) {
  jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double amount = 2;
 * @return {number}
 */
proto.Exchange.GRPC.TradeHistory.prototype.getAmount = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 2, 0.0));
};


/** @param {number} value */
proto.Exchange.GRPC.TradeHistory.prototype.setAmount = function(value) {
  jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total = 3;
 * @return {number}
 */
proto.Exchange.GRPC.TradeHistory.prototype.getTotal = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 3, 0.0));
};


/** @param {number} value */
proto.Exchange.GRPC.TradeHistory.prototype.setTotal = function(value) {
  jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp timestamp = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Exchange.GRPC.TradeHistory.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.Exchange.GRPC.TradeHistory.prototype.setTimestamp = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


proto.Exchange.GRPC.TradeHistory.prototype.clearTimestamp = function() {
  this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.Exchange.GRPC.TradeHistory.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional double diff = 5;
 * @return {number}
 */
proto.Exchange.GRPC.TradeHistory.prototype.getDiff = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 5, 0.0));
};


/** @param {number} value */
proto.Exchange.GRPC.TradeHistory.prototype.setDiff = function(value) {
  jspb.Message.setProto3FloatField(this, 5, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Exchange.GRPC.Order = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Exchange.GRPC.Order, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.Exchange.GRPC.Order.displayName = 'proto.Exchange.GRPC.Order';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Exchange.GRPC.Order.prototype.toObject = function(opt_includeInstance) {
  return proto.Exchange.GRPC.Order.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Exchange.GRPC.Order} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Exchange.GRPC.Order.toObject = function(includeInstance, msg) {
  var f, obj = {
    price: +jspb.Message.getFieldWithDefault(msg, 1, 0.0),
    amount: +jspb.Message.getFieldWithDefault(msg, 2, 0.0),
    total: +jspb.Message.getFieldWithDefault(msg, 3, 0.0),
    buySell: jspb.Message.getFieldWithDefault(msg, 4, ""),
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Exchange.GRPC.Order}
 */
proto.Exchange.GRPC.Order.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Exchange.GRPC.Order;
  return proto.Exchange.GRPC.Order.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Exchange.GRPC.Order} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Exchange.GRPC.Order}
 */
proto.Exchange.GRPC.Order.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotal(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBuySell(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Exchange.GRPC.Order.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Exchange.GRPC.Order.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Exchange.GRPC.Order} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Exchange.GRPC.Order.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getBuySell();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional double price = 1;
 * @return {number}
 */
proto.Exchange.GRPC.Order.prototype.getPrice = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 1, 0.0));
};


/** @param {number} value */
proto.Exchange.GRPC.Order.prototype.setPrice = function(value) {
  jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double amount = 2;
 * @return {number}
 */
proto.Exchange.GRPC.Order.prototype.getAmount = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 2, 0.0));
};


/** @param {number} value */
proto.Exchange.GRPC.Order.prototype.setAmount = function(value) {
  jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total = 3;
 * @return {number}
 */
proto.Exchange.GRPC.Order.prototype.getTotal = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 3, 0.0));
};


/** @param {number} value */
proto.Exchange.GRPC.Order.prototype.setTotal = function(value) {
  jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string buy_sell = 4;
 * @return {string}
 */
proto.Exchange.GRPC.Order.prototype.getBuySell = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.Exchange.GRPC.Order.prototype.setBuySell = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp timestamp = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Exchange.GRPC.Order.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.Exchange.GRPC.Order.prototype.setTimestamp = function(value) {
  jspb.Message.setWrapperField(this, 5, value);
};


proto.Exchange.GRPC.Order.prototype.clearTimestamp = function() {
  this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.Exchange.GRPC.Order.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 5) != null;
};


goog.object.extend(exports, proto.Exchange.GRPC);
