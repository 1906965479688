import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Table } from 'reactstrap';
import moment from 'moment';
import { numberWithCommas } from './common';
import MarketDataService from '../../../ApiService/MarketDataService';

export const TradeHistory = ({ stock, maxHeight }) => {
  const { ticker } = useParams();
  console.info('Trade History ticker: ' + ticker);
  const [tradeHistory, setTradeHistory] = useState([]);
  const history_depth = 5;

  const refreshMarketTradeData = () => {
    console.info('Refreshing market trade data');
    let req = {
      secKey: ticker,
    };
    MarketDataService.getMarketTradeData(req)
      .then((res) => {
        console.info('market trade data callback: ' + res.data);
        if (res && res.data) {
          setTradeHistory(res.data.slice(0, history_depth));
        } else {
          setTradeHistory([]);
        }
      })
      .catch((err) => {
        console.error('Error in getting market trade data: ' + err);
        setTradeHistory([]);
      });
  };

  useEffect(() => {
    let interval_id = 0;
    if (ticker) {
      console.info('getting market trade data of ' + ticker);
      refreshMarketTradeData();
      //interval_id = setInterval(refreshMarketTradeData, 5000);
    }
    //return () => {
    //	clearInterval(interval_id);
    //}
  }, [ticker]);

  const renderDiff = (price, diff) => {
    return diff > 0 ? (
      <td style={{ color: 'limegreen' }}>
        <i className="fas fa-sort-up" /> {numberWithCommas(price, 2)}
      </td>
    ) : (
      <td style={{ color: 'red' }}>
        <i className="fas fa-sort-down" /> {numberWithCommas(price, 2)}
      </td>
    );
  };

  console.log('tradehist', tradeHistory);

  return (
    <div
      style={{
        maxHeight: maxHeight,
        overflowY: 'auto',
      }}
    >
      <Table style={{ textAlign: 'right' }}>
        <thead>
          <tr>
            <td>Price</td>
            <td>Qty</td>
            <td>Timestamp</td>
          </tr>
        </thead>
        <tbody>
          {tradeHistory.map((x) => {
            return (
              <tr>
                <td>{numberWithCommas(x.price, 2)}</td>
                <td>{numberWithCommas(x.volume)}</td>
                <td>{moment(x.tradeTime).format('HH:mm:ss')}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};
