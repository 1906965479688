import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';

import RefreshIcon from '@material-ui/icons/Refresh';
import VisibilityIcon from '@material-ui/icons/Visibility';

import ApiService from '../../ApiService/ApiService';

import DetailInfo from '../DiamondMarket/DetailInfo';
import EnhancedTable from '../EnhancedTableView/EnhancedTable';
import InfoDrawer from '../DetailedInfo/InfoDrawer';

import { round } from '../DiamondMarket/assets';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

function DiamondView() {
  const classes = useStyles();

  const [diamondList, setDiamondList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [infoData, setInfoData] = useState(null);

  const [updateFlag, setUpdateFlag] = useState(false);
  const update = () => setUpdateFlag((old) => !old);
  const handleUpdate = () => {
    let mounted = true;
    ApiService.getDiamonds().then((res) => {
      if (mounted && res.data) {
        var diamonds = res.data.filter((x) => x.issueSize > 0);
        setDiamondList(diamonds);
        infoData &&
          setInfoData((old) => diamonds.find((doc) => doc.id === old.id));
      }
    });

    return () => {
      mounted = false;
    };
  };

  useEffect(handleUpdate, [updateFlag]);

  console.log('diamonds', diamondList);

  const columns = [
    {
      id: 'id',
      numeric: false,
      disablePadding: false,
      label: 'SecKey',
      valueOf: (diamond) => (
        <Link style={{ color: 'white' }} to={'/exchange/trade/' + diamond.key}>
          {diamond.key}
        </Link>
      ),
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: false,
      label: 'Description',
      valueOf: (diamond) => diamond.description,
    },
    {
      id: 'issueSize',
      numeric: false,
      disablePadding: false,
      label: 'Issue Size',
      valueOf: (diamond) => diamond.issueSize,
    },
    {
      id: 'issuer',
      numeric: false,
      disablePadding: false,
      label: 'Issuer',
      valueOf: (diamond) => diamond.issuer,
    },
    {
      id: 'laboratory',
      numeric: false,
      disablePadding: false,
      label: 'Laboratory',
      valueOf: (diamond) => diamond.laboratory,
    },
    {
      id: 'shape',
      numeric: false,
      disablePadding: false,
      label: 'Shape',
      valueOf: (diamond) =>
        diamond.shape === 'Round' ? (
          <span>
            {diamond.shape.toUpperCase()}{' '}
            <img height="40px" src={round} alt="round" />
          </span>
        ) : (
          diamond.shape
        ),
    },
    {
      id: 'carat',
      numeric: false,
      disablePadding: false,
      label: 'Carat',
      valueOf: (diamond) => diamond.carat,
    },
    {
      id: 'color',
      numeric: false,
      disablePadding: false,
      label: 'Color',
      valueOf: (diamond) => diamond.color,
    },
    {
      id: 'clarity',
      numeric: false,
      disablePadding: false,
      label: 'Clarity',
      valueOf: (diamond) => diamond.clarity,
    },
    {
      id: 'cut',
      numeric: false,
      disablePadding: false,
      label: 'Cut',
      valueOf: (diamond) => diamond.cut,
    },
    {
      id: 'polish',
      numeric: false,
      disablePadding: false,
      label: 'Polish',
      valueOf: (diamond) => diamond.polish,
    },
    {
      id: 'symmetry',
      numeric: false,
      disablePadding: false,
      label: 'Symmetry',
      valueOf: (diamond) => diamond.symmetry,
    },
    {
      id: 'fluorescence',
      numeric: false,
      disablePadding: false,
      label: 'Fluorescence',
      valueOf: (diamond) => diamond.fluorescence,
    },
    {
      id: 'unitSize',
      numeric: false,
      disablePadding: false,
      label: 'Unit Size',
      valueOf: (diamond) => diamond.unitSize,
    },
  ];
  const opDefs = [
    {
      label: 'refresh',
      icon: <RefreshIcon />,
      handleClick: update,
    },
  ];
  const actionDefs = [
    {
      label: 'View',
      icon: <VisibilityIcon />,
      handleClick: setInfoData,
      show: 'cell',
    },
  ];

  return (
    <>
      <EnhancedTable
        classes={classes}
        columnDefs={columns}
        operationDefs={opDefs}
        dataList={diamondList}
        selected={selected}
        setSelected={setSelected}
        actionDefs={actionDefs}
        title="Products"
        valueOfprimaryKey={(obj) => obj.sec_key}
      />
      {infoData && (
        <InfoDrawer
          // style={{ width: '50%' }}
          open={infoData !== null}
          handleClose={() => setInfoData(null)}
          title="Product Detail"
        >
          <DetailInfo data={infoData} update={update} />
        </InfoDrawer>
      )}
    </>
  );
}

export default DiamondView;
