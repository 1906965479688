// Group orders into bins by price according to precision
// Add bid/ask accumulated amount for charting
export const groupOrders = (price, orders, precision) => {
  var bins = {};
  orders.forEach((x) => {
    if (bins[x.priceValue.toFixed(precision)] == null)
      bins[x.priceValue.toFixed(precision)] = x.volume;
    else
      bins[x.priceValue.toFixed(precision)] =
        bins[x.priceValue.toFixed(precision)] + x.volume;
  });

  var res = [];
  for (var x in bins) {
    const p = parseFloat(x);
    const a = bins[x];

    res.push({
      price: p,
      amount: a,
      total: p * a,
    });
  }
  var sorted = res.sort((a, b) => (a.price < b.price ? 1 : -1));
  var ask = 0;
  var bid = 0;
  const alpha = 0.00001;
  sorted.forEach((x) => {
    if (x.price < price * (1 - alpha)) bid += x.amount;
    x.bid = bid;
  });
  sorted.reverse().forEach((x) => {
    if (x.price > price * (1 + alpha)) ask += x.amount;
    x.ask = ask;
  });
  return sorted.reverse();
};

// Pick n elements from an array
export const randomItems = (array, n) => {
  return array.sort(() => 0.5 - Math.random()).slice(0, n);
};

export const randomItem = (array) => {
  return array[Math.floor(Math.random() * array.length)];
};

// Box-Muller
export const rand_bm = () => {
  var u = 0;
  var v = 0;
  while (u === 0) u = Math.random(); // Converting [0,1) to (0,1)
  while (v === 0) v = Math.random();
  return Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v);
};

export const randomRemove = (array) => {
  // Usage:
  // var test = [1,2,3,4,5];
  // const removed = randomRemove(test);
  return array.splice(Math.floor(Math.random() * (array.length - 1)), 1)[0];
};
