import axios from 'axios';

const API = axios.create({
  baseURL: window['config'].apiBaseUrl.marketdata,
});

const ApiService = {
  setMarketDataAuth(auth) {
    API.defaults.headers.common['Authorization'] = auth;
  },

  getMarketData(req){
    console.info(`getting market data of ${req.secKey}`);
    return API.get(`getmktdata/${req.accountNum}/${req.secKey}`, );
  },
  
  getMarketTradeData(req){
    console.info(`getting market trade data of ${req.secKey}`);
    return API.get(`getmkttradedata/${req.secKey}`, );
  }
}

export default ApiService;
