import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { updown, numberWithCommas } from './common';

import ApiService from '../../../ApiService/ApiService';

function formatProductName(type, product) {
  if (product) {
    switch (type) {
      case 'Bond':
        return product.description;
      case 'Wine':
        return product.description;
      default:
        return type;
    }
  }

  return type;
}

export const Summary = ({ stock }) => {
  const precision = 2;

  const [product, setProduct] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const product = await ApiService.getProduct(
          stock.securityType,
          stock.securityKey
        );
        setProduct(product);
      } catch (err) {
        console.error(err);
        setProduct();
      }
    };
    fetchData();
  }, [stock.securityKey, stock.securityType]);

  return (
    stock && (
      <>
        <Row>
          <Col sm="4" style={{ fontSize: '1.3rem' }}>
            {stock.securityKey} <br />
            {formatProductName(stock.securityType, product)}
          </Col>
          <Col>Open</Col>
          <Col>Close</Col>
          <Col>Last Price</Col>
          <Col>24h Change</Col>
          <Col>24h High</Col>
          <Col>24h Low</Col>
          <Col>24h Volume</Col>
        </Row>
        <Row style={{ fontSize: '1.2rem' }}>
          <Col sm="4">{stock.description}</Col>
          <Col>{numberWithCommas(stock.open, precision)}</Col>
          <Col>{numberWithCommas(stock.close, precision)}</Col>
          <Col>{numberWithCommas(stock.last, precision)}</Col>
          <Col>
            {stock.last == 0
              ? '0%'
              : updown(
                  numberWithCommas(
                    (stock.last - stock.close) / stock.close,
                    precision
                  ).toString() + '%'
                )}
          </Col>
          <Col>{numberWithCommas(stock.high, precision)}</Col>
          <Col>{numberWithCommas(stock.low, precision)}</Col>
          <Col>{numberWithCommas(stock.volume, precision)}</Col>
        </Row>
      </>
    )
  );
};
