import React, { useState, useEffect, useRef } from 'react';

import { Paper } from '@material-ui/core/';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';

import LoginForm from './LoginForm';
import ApiService from '../../ApiService/ApiService';

const AccountControlArea = ({ isLoggedIn, setIsLoggedIn, setRoles }) => {
  const [customer, setCustomer] = useState(null);
  const [staff, setStaff] = useState(null);
  const refreshTimer = useRef(false);

  const handleUpdate = () => {
    if (isLoggedIn) {
      ApiService.loadUserProfile().then((response) => {
        console.log('user profile: ', response);
        console.log(
          'response?.attributes?.platformroles ',
          response?.attributes?.platformroles?.includes('admin')
        );
        const roles = response?.attributes?.platformroles;
        console.log('role', roles);
        setRoles(roles);
      });

      ApiService.getCurrentCustomer((c) => setCustomer(c));
      ApiService.getCurrentStaff((s) => setStaff(s));
    } else {
      setCustomer(null);
      setStaff(null);
      clearInterval(refreshTimer.current);
    }
  };
  useEffect(handleUpdate, [isLoggedIn]);

  const handleRefresh = (refreshToken) => {
    ApiService.updateToken(refreshToken);
  };

  const handleLogout = () => {
    ApiService.logout();
    setIsLoggedIn(false);
  };

  const user = staff || customer;
  console.log('staff', staff);
  console.log('customer', customer);
  console.log('user', user);

  return (
    <Paper>
      {!isLoggedIn ? ( // going to login
        <List>
          <ListItem>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <LoginForm
              refreshTimer={refreshTimer}
              setIsLoggedIn={setIsLoggedIn}
              handleRefresh={handleRefresh}
            />
          </ListItem>
        </List>
      ) : (
        // logged in
        <List>
          <ListItem disabled={true}>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary={user?.userName} />
          </ListItem>
          <ListItem onClick={handleLogout} button>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary={'Logout'} />
          </ListItem>
        </List>
      )}
    </Paper>
  );
};

export default AccountControlArea;
