import React, { useState, useEffect } from 'react';
import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  TextField,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import MarketCoreService from '../ApiService/MarketCoreService';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const BrokerApprove = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const [allowance, setAllowance] = useState(1000000000);
  const [current, setCurrent] = useState(null);

  const actions = [
    {
      name: 'Approve',
      func: async () => {
        setOpen(true);
        try {
          await MarketCoreService.approveSettlement(allowance);
        } catch (ex) {
          toast.error(`Failed to approve ${ex}`);
        }
        setOpen(false);
      },
      color: 'primary',
    },
    {
      name: 'Reject',
      func: async () => {
        setOpen(true);
        try {
          await MarketCoreService.rejectSettlement();
        } catch (ex) {
          toast.error(`Failed to reject ${ex}`);
        }
        setOpen(false);
      },
      color: 'secondary',
    },
  ];

  useEffect(() => {
    MarketCoreService.checkSettlementAllowance().then((res) =>
      setCurrent(res.data)
    );
  }, [open]);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <TextField
            label="Allowance"
            value={allowance}
            onChange={(e) => setAllowance(e.target.value)}
          />
          {actions.map((x) => (
            <Button key={x.name} color={x.color} onClick={() => x.func()}>
              {x.name}
            </Button>
          ))}
        </Grid>
        <Grid item xs={12}>
          {current != null && <h3>Current Allowance {current}</h3>}
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default BrokerApprove;
