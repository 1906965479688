import React from 'react';
import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  TextField,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import api from '../../ApiService/MarketCoreService';
import { toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const BurnTokensForm = ({ custodian }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    data = { from: custodian, ...data };
    console.log('BurnTokensForm', data);
    setOpen(true);
    try {
      await api.burnTokens(data.from, data.amount, data.refId);
      toast.success(`Tokens burned [${data.refId}]`);
    } catch (ex) {
      toast.error(`Failed to burn tokens [${data.refId}}`);
    }
    setOpen(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              required
              inputRef={register}
              id="amount"
              name="amount"
              label="Amount"
              helperText="Enter the amount of PTC to be burned"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              inputRef={register}
              id="refId"
              name="refId"
              label="Ref ID"
              helperText="Enter a reference ID"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" color="primary" variant="contained">
              Burn Tokens
            </Button>
          </Grid>
        </Grid>
      </form>
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default BurnTokensForm;
