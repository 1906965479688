import axios from 'axios';
import { onResolved, handleError, handleSpecificError } from './Utilities';

const API = axios.create({
  baseURL: window['config'].apiBaseUrl.onBoarding,
});

const OnBoardService = {
  setOnBoardAuth(auth) {
    API.defaults.headers.common['Authorization'] = auth;
  },

  getCustomers(setter) {
    console.log('getting all users info');
    return API.get(`customers/`)
      .then((response) => {
        let customers = response.data;
        console.log('customers info', customers);
        setter && setter(customers);
        return true;
      })
      .catch(handleError);
  },
  getCurrentCustomer(setter) {
    console.log('getting current customer info');
    return API.get(`customers/current`)
      .then((response) => {
        let customer = response.data;
        console.log('current customer info', customer);
        setter && setter(customer);
        return true;
      })
      .catch(handleSpecificError(404, null));
  },
  createCustomer(setter, body) {
    console.log('creating new customer ', body);
    return API.post(`customers/`, body)
      .then((response) => {
        const newCustomer = response.data;
        console.log('created new customer', response);
        setter && setter(newCustomer);
        return newCustomer;
      })
      .catch(handleError); // TODO: handle invalid
  },
  deleteCustomer(setter, userId) {
    console.log('deleting customer ', userId);
    return API.delete(`customers/${userId}`)
      .then((response) => {
        console.log('deleted new customer', response);
        setter && setter(response.data);
        return true;
      })
      .catch(handleError);
  },
  updateCustomerStatus(setter, customerId, statusName) {
    return API.put(`customers/${customerId}/${statusName}`)
      .then((response) => {
        console.log('updated customer status ', response.data);
        setter && setter(response.data);
        return true;
      })
      .catch(handleError);
  },
  enableCustomer(customerId) {
    return customerId && API.post(`customers/${customerId}/enable`);
  },
  disableCustomer(customerId) {
    return customerId && API.post(`customers/${customerId}/disable`);
  },

  getStaff(setter) {
    console.log('getting all users info');
    return API.get(`staff/`)
      .then((response) => {
        let staff = response.data;
        console.log('staff info', staff);
        setter && setter(staff);
        return true;
      })
      .catch(handleError);
  },
  getCurrentStaff(setter) {
    console.log('getting current staff info');
    return API.get(`staff/current`)
      .then(onResolved('current staff info', setter))
      .catch(handleError);
  },
  createStaff(setter, body) {
    console.log('creating new staff ', body);
    return API.post(`staff/`, body)
      .then((response) => {
        const newStaff = response.data;
        console.log('created new staff', response);
        setter && setter(newStaff);
        return newStaff;
      })
      .catch(handleError); // TODO: handle invalid
  },
  deleteStaff(setter, userId) {
    console.log('deleting staff ', userId);
    return API.delete(`staff/${userId}`)
      .then((response) => {
        console.log('deleted new staff', response);
        setter && setter(response.data);
        return true;
      })
      .catch(handleError);
  },
  updateStaffStatus(setter, staffId, statusName) {
    return API.put(`staff/${staffId}/${statusName}`)
      .then((response) => {
        console.log('updated staff status ', response.data);
        setter && setter(response.data);
        return true;
      })
      .catch(handleError);
  },

  getStaffRoles(setter) {
    return API.get(`StaffRole`)
      .then((response) => {
        const params = response.data;
        console.log('got staff roles ', params);
        setter && setter(params);
        return true;
      })
      .catch(handleError);
  },
  getStaffStatus(setter) {
    return API.get(`StaffStatus`)
      .then((response) => {
        const params = response.data;
        console.log('got staff status ', params);
        setter && setter(params);
        return true;
      })
      .catch(handleError);
  },

  createApplication(setter, body) {
    return API.post(`Applications`, body)
      .then(onResolved('got application'), setter)
      .catch(handleError);
  },

  getApplicationTypes(setter) {
    return API.get(`ApplicationTypes`)
      .then((response) => {
        const params = response.data;
        console.log('got application types ', params);
        setter && setter(params);
        return true;
      })
      .catch(handleError);
  },
  getInvestorTypes(setter) {
    return API.get(`InvestorTypes`)
      .then((response) => {
        const params = response.data;
        console.log('got investor types ', params);
        setter && setter(params);
        return true;
      })
      .catch(handleError);
  },
  getIssuerTypes(setter) {
    return API.get(`IssuerTypes`)
      .then((response) => {
        const params = response.data;
        console.log('got issuer types ', params);
        setter && setter(params);
        return true;
      })
      .catch(handleError);
  },

  getOnBoardingParams(setter) {
    return API.get(`Params`)
      .then((response) => {
        const params = response.data;
        console.log('got params ', params);
        setter && setter(params);
        return true;
      })
      .catch(handleError);
  },

  bulkloadCustomer(file, customerType) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return API.post('bulkload/customer/' + customerType, formData);
  },

  bulkloadStaff(file, staffType) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return API.post('bulkload/customer/' + staffType, formData);
  },

  resetPassword(data) {
    return API.put('customers/resetpassword', data);
  },
};

export default OnBoardService;
