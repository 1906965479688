import React, { useState, useEffect } from 'react';
import { Button, Table } from 'reactstrap';
import { CircularProgress } from '@material-ui/core';
import { numberWithCommas } from './common';
import { useHistory } from 'react-router-dom';
import BrokerOMSService from '../../../ApiService/BrokerOMSService';
import moment from 'moment';
import Switch from '@material-ui/core/Switch';
import { toast } from 'react-toastify';
import EnhancedTable from '../../EnhancedTableView/EnhancedTable';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';

export const OrderHistory = ({ account_no, path, stock, bidColor, all }) => {
  const history = useHistory();
  const [orderHistory, setOrderHistory] = useState([]);
  const precision = 2;

  const fetchData = () => {
    const req = {
      AccountNo: account_no,
      OpenOrder: false,
    };
    if (all) {
      console.info(`retrieve all orders`);
    } else {
      console.info(`retrieve only ${stock.securityKey} orders`);
      req['SecurityKey'] = stock.securityKey;
    }
    // Fetch all open orders for the account
    var ticker = '';
    BrokerOMSService.getOpenOrders(req)
      .then((res) => {
        setOrderHistory(res.data);
      })
      .catch((err) => {
        console.error('Error in retrieving orders.');
        setOrderHistory([]);
      });
  };

  useEffect(() => {
    fetchData();
    let interval_id = setInterval(fetchData, 5000);
    return () => clearInterval(interval_id);
  }, [account_no, stock]);

  const [filterEnabled, setFilterEnabled] = useState(false);
  const handleChange = (e) => {
    setFilterEnabled(e.target.checked);
  };
  const orders =
    orderHistory && stock && filterEnabled
      ? orderHistory.filter((x) => x.SecurityKey === stock.symbol)
      : orderHistory;

  const onCancel = (order) => {
    console.log('cancel order', order);
    BrokerOMSService.cancelOrder(order)
      .then((res) => {
        console.log('cancel order ok', order);
        toast.success(`Cancelled order ${order.ID}.`);
      })
      .catch((err) => {
        console.log('Failed to cancel order', err, err.response);
        toast.error(`Failed to cancel order ${order.ID}. ${err}`);
      });
  };

  const [selected, setSelected] = useState([]);

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    tableCell: {
      whiteSpace: 'nowrap',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }));
  const classes = useStyles();

  const columnDefs = [
    {
      id: 'id',
      numeric: false,
      disablePadding: true,
      label: 'ID',
      valueOf: (order) => order.id,
    },
    {
      id: 'date',
      numeric: true,
      disablePadding: false,
      label: 'Date',
      valueOf: (order) =>
        moment(order.creationTime).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      id: 'ticker',
      numeric: true,
      disablePadding: false,
      label: 'Ticker',
      valueOf: (order) => order.securityKey,
    },
    {
      id: 'type',
      numeric: true,
      disablePadding: false,
      label: 'Type',
      valueOf: (order) => order.orderInstruction,
    },
    {
      id: 'side',
      numeric: true,
      disablePadding: false,
      label: 'Side',
      valueOf: (order) => order.side,
    },
    {
      id: 'price',
      numeric: true,
      disablePadding: false,
      label: 'Price',
      valueOf: (order) => numberWithCommas(order.price, 2),
    },
    {
      id: 'qty',
      numeric: true,
      disablePadding: false,
      label: 'Qty',
      valueOf: (order) => numberWithCommas(order.quantity),
    },
    {
      id: 'filledQty',
      numeric: true,
      disablePadding: false,
      label: 'Filled',
      valueOf: (order) => numberWithCommas(order.filledQuantity),
    },
    {
      id: 'osQty',
      numeric: true,
      disablePadding: false,
      label: 'O/S',
      valueOf: (order) =>
        numberWithCommas(order.quantity - order.filledQuantity),
    },
    {
      id: 'state',
      numeric: true,
      disablePadding: false,
      label: 'State',
      valueOf: (order) => order.state,
    },
    {
      id: 'status',
      numeric: true,
      disablePadding: false,
      label: 'Status',
      valueOf: (order) => order.status,
    },
    {
      id: 'mkdId',
      numeric: true,
      disablePadding: false,
      label: 'Market ID',
      valueOf: (order) => order.marketID,
    },
  ];

  const opDefs = [];
  const actionDefs = [
    {
      label: 'Cancel',
      icon: <CancelIcon />,
      handleClick: onCancel,
      show: 'cell',
    },
  ];

  return (
    <>
      <EnhancedTable
        classes={classes}
        columnDefs={columnDefs}
        operationDefs={opDefs}
        dataList={orderHistory}
        selected={selected}
        setSelected={setSelected}
        actionDefs={actionDefs}
        title=""
        initialOrder="desc"
        initialOrderBy={columnDefs.find((x) => x.id === 'date')}
      />
    </>
  );
};
