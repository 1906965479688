import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import PersonAddIcon from '@material-ui/icons/PersonAdd';
import DeleteIcon from '@material-ui/icons/Delete';
import RefreshIcon from '@material-ui/icons/Refresh';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

import CreateNewStaffDialog from './CreateNewStaffDialog';
import ConfirmationDialog from '../ConfirmationDialog';
import DetailStaffInfo from './DetailStaffInfo';
import EnhancedTable from '../EnhancedTableView/EnhancedTable';
import InfoDrawer from '../DetailedInfo/InfoDrawer';
import ApiService from '../../ApiService/ApiService';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

function StaffManageView({ isLoggedIn }) {
  const [staffList, setStaffList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [params, setParams] = useState([]);
  const [staffRoles, setStaffRoles] = useState([]);
  const [staffStatus, setStaffStatus] = useState([]);
  const [infoData, setInfoData] = useState(null);
  const [selected, setSelected] = useState([]);
  const [openDialog, setOpenDialog] = useState(null);
  const [confirmed, setConfirmed] = useState(null);
  const [edit, setEdit] = useState(false);
  const classes = useStyles();

  const [updateFlag, setUpdateFlag] = useState(false);
  const update = () => setUpdateFlag((old) => !old);
  const handleUpdate = () => {
    if (isLoggedIn) {
      ApiService.getStaff((staff) => {
        setStaffList(staff);
        infoData &&
          setInfoData(
            staff.find((staff) => staff.staffID === infoData.staffID)
          );
      });
      ApiService.getStaffRoles(setStaffRoles);
      ApiService.getStaffStatus(setStaffStatus);
      //ApiService.getGroups(setGroupList);
      ApiService.getOnBoardingParams(setParams);
    } else {
      setStaffList([]);
    }
  };

  useEffect(handleUpdate, [updateFlag, isLoggedIn]);

  const columnDefs = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Staff Name',
      valueOf: (staff) => `${staff.firstName} ${staff.lastName.toUpperCase()}`,
    },
    {
      id: 'username',
      numeric: false,
      disablePadding: false,
      label: 'Username',
      valueOf: (staff) => staff.userName,
    },
    {
      id: 'id',
      numeric: true,
      disablePadding: false,
      label: 'ID',
      valueOf: (staff) => staff.staffID,
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: 'Status',
      valueOf: (staff) => staff.status.status,
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: 'Email',
      valueOf: (staff) => staff.emails || 'None',
    },
    {
      id: 'phone',
      numeric: false,
      disablePadding: false,
      label: 'Phone',
      valueOf: (staff) =>
        staff.phones
          .map((phone) => phone.phoneType.phoneTyp + ': ' + phone.phoneNo)
          .join('\n') || 'None',
    },
  ];

  // Operations
  const handleDelete = (staff) => {
    let promises = !staff
      ? selected.map((roleId) => ApiService.deleteStaff(roleId))
      : [ApiService.deleteStaff(staff.id)];
    Promise.all(promises).then((response) => {
      setSelected(selected.filter((x, i) => !response[i]));
      update();
    });
  };

  const opDefs = [
    {
      label: 'Create Staff',
      ariaLabel: 'create staff',
      icon: <PersonAddIcon />,
      handleClick: () => setOpenDialog('create'),
    },
    {
      label: 'Refresh',
      ariaLabel: 'refresh',
      icon: <RefreshIcon />,
      handleClick: update,
    },
  ];

  const actionDefs = [
    {
      label: 'View',
      icon: <VisibilityIcon />,
      handleClick: (data) => {
        setInfoData(data);
        setEdit(false);
      },
      show: 'cell',
    },
    {
      label: 'Edit',
      icon: <EditIcon />,
      handleClick: (data) => {
        setInfoData(data);
        setEdit(true);
      },
      show: 'cell',
    },
    {
      label: 'Delete',
      icon: <DeleteIcon />,
      handleClick: (data) => {
        setOpenDialog('Delete Staff');
        setConfirmed(() => () => handleDelete(data));
      },
      show: 'both',
    },
  ];

  return (
    <>
      <EnhancedTable
        classes={classes}
        columnDefs={columnDefs}
        operationDefs={opDefs}
        dataList={staffList}
        selected={selected}
        setSelected={setSelected}
        actionDefs={actionDefs}
        title="Staff"
        valueOfprimaryKey={(staff) => staff.staffID}
      />
      <InfoDrawer
        open={infoData !== null}
        handleClose={() => setInfoData(null)}
        title="Staff Detail"
      >
        <DetailStaffInfo
          data={infoData}
          edit={edit}
          setEdit={(data) => setEdit(data)}
          {...{
            params,
            staffStatus,
            staffRoles,
            update,
          }}
        />
      </InfoDrawer>

      <CreateNewStaffDialog
        open={openDialog === 'create'}
        closeDialog={() => setOpenDialog('')}
        {...{
          prevGroups: groupList,
          staffRoles,
          staffStatus,
          params,
          update,
        }}
      />

      <ConfirmationDialog
        closeDialog={() => setOpenDialog('')}
        handleConfirmed={() => confirmed()}
        title="Delete Staff"
        open={openDialog === 'Delete Staff'}
        message="Are you sure delete the staff(s)? "
      />
    </>
  );
}

export default StaffManageView;
