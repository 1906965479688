export default {
  h1: {
    fontWeight: 500,
    fontSize: '1.59375rem',
    letterSpacing: '-0.24px',
    '@media (min-width:600px)': {
      fontSize: '1.928rem',
    },
    '@media (min-width:960px)': {
      fontSize: '2.1422rem',
    },
    '@media (min-width:1280px)': {
      fontSize: '2.1422rem',
    },
  },
  h2: {
    fontWeight: 500,
    fontSize: '1.40625rem',
    letterSpacing: '-0.24px',
    '@media (min-width:600px)': {
      fontSize: '1.6667rem',
    },
    '@media (min-width:960px)': {
      fontSize: '1.6667rem',
    },
    '@media (min-width:1280px)': {
      fontSize: '1.875rem',
    },
  },
  h3: {
    fontWeight: 500,
    fontSize: '1.25rem',
    letterSpacing: '-0.06px',
    '@media (min-width:600px)': {
      fontSize: '1.2853rem',
    },
    '@media (min-width:960px)': {
      fontSize: '1.4996rem',
    },
    '@media (min-width:1280px)': {
      fontSize: '1.4996rem',
    },
  },
  h4: {
    fontWeight: 500,
    fontSize: '1.125rem',
    letterSpacing: '-0.06px',
    '@media (min-width:600px)': {
      fontSize: '1.2146rem',
    },
    '@media (min-width:960px)': {
      fontSize: '1.2146rem',
    },
    '@media (min-width:1280px)': {
      fontSize: '1.2146rem',
    },
  },
  h5: {
    fontWeight: 500,
    fontSize: 16,
    letterSpacing: '-0.05px',
  },
  h6: {
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: '-0.05px',
  },
  overline: {
    fontWeight: 500,
  },
};
