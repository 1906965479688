import React from 'react';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import DialogForm from './DialogForm';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  content: {
    width: '100%',
    padding: theme.spacing(0, 2, 2, 2),
  },
  formControl: {
    minWidth: 120,
    margin: theme.spacing(0, 1),
  },
  paper: {
    width: '100%',
    minWidth: 200,
    padding: theme.spacing(1, 2),
    margin: theme.spacing(2, 0, 0, 0),
  },
  card: {
    width: '100%',
    minWidth: 200,
    padding: theme.spacing(1, 2),
    margin: theme.spacing(0, 0, 1, 0),
  },
}));

const CashoutDialog = ({ customers, cashout, open, closeDialog, onPay }) => {
  const classes = useStyles();
  const { register, control, handleSubmit, reset, errors } = useForm();

  const onSubmit = (data, e) => {
    closeDialog();
  };
  const customer = customers.find((x) => x.sub == cashout.sub);
  return (
    cashout && (
      <DialogForm
        open={open}
        onClose={() => {
          closeDialog();
          reset();
        }}
        onSubmit={handleSubmit(onSubmit)}
        title={`Cashout - ${cashout.accountNumber}`}
        maxWidth="sm"
      >
        <Typography>
          {cashout.accountNumber} - {customer.userName}
        </Typography>
        <Typography>
          <i>
            {customer.firstName} {customer.lastName}
          </i>
        </Typography>
        <Typography>{customer.remarks}</Typography>
        <Typography>
          Requested on {moment(cashout.createdDateTime).format('lll')}
        </Typography>
        <Typography>
          Last updated on {moment(cashout.updatedDateTime).format('lll')}
        </Typography>
        <Typography>Amount {cashout.amount.toLocaleString()}</Typography>
        <Typography>Status {cashout.status}</Typography>
        {cashout.status === 'Pending' && (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => onPay(cashout)}
          >
            Pay
          </Button>
        )}
      </DialogForm>
    )
  );
};

export default CashoutDialog;
