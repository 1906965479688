import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Grid,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '25%',
    padding: theme.spacing(1, 2, 2, 1),
  },
  fieldLabel: {
    display: 'inline-block',
    fontWeight: 'bold',
  },
  footer: {
    padding: theme.spacing(2),
    float: 'right',
  },
  cell: {
    verticalAlign: 'top',
    borderBottom: 'none',
  },
}));

function InfoGrid({
  data,
  fieldDefs,
  handleSubmit,
  update,
  overrideButtons,
  edit,
  setEdit,
}) {
  const classes = useStyles();
  const formProps = useForm(
    data
      ? {
          defaultValues: fieldDefs.reduce((obj, def) => {
            obj[def.id] = def.valueOf(data);
            return obj;
          }, {}),
        }
      : {}
  );

  useEffect(() => {
    if (data) {
      const defaultValues = fieldDefs.reduce((defaultValues, def) => {
        defaultValues[def.id] = def.valueOf(data);
        return defaultValues;
      }, {});
      formProps.reset(defaultValues);
    } else {
      formProps.reset();
    }
  }, [edit, fieldDefs, data]);

  return data ? (
    <form
      className={classes.root}
      onSubmit={formProps.handleSubmit((formData, e) => {
        handleSubmit(formData, e, data).then((promises) => {
          console.log('promises: ', promises);
          if (promises.length) {
            Promise.all(promises).then((resolvedPromises) => {
              if (resolvedPromises.every((pro) => pro)) {
                update();
                setEdit(false);
                toast.success('Saved!');
              }
            });
          } else {
            update();
            setEdit(false);
            toast.warn('nothing has changed.');
          }
        });
      })}
    >
      <TableContainer>
        <Table size="small">
          <TableBody>
            {fieldDefs.map((field, index) => (
              <TableRow key={index}>
                <TableCell className={classes.cell}>
                  <Typography className={classes.fieldLabel}>
                    {`${field.label} `}
                  </Typography>
                </TableCell>
                <TableCell className={classes.cell}>
                  {edit && field.editField
                    ? field.editField(data, formProps)
                    : field.displayValueOf
                    ? field.displayValueOf(data)
                    : field.valueOf(data)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <div className={classes.footer}>
        {(handleSubmit || overrideButtons) &&
          (edit ? (
            <Grid container spacing={2}>
              <Grid item>
                <Button variant="outlined" onClick={() => setEdit(false)}>
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" type="submit">
                  Save
                </Button>
              </Grid>
            </Grid>
          ) : (
            overrideButtons || (
              <Button
                variant="contained"
                color="primary"
                onClick={overrideButtons || (() => setEdit(true))}
              >
                Edit
              </Button>
            )
          ))}
      </div>
    </form>
  ) : (
    <></>
  );
}

export default InfoGrid;
