import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import ApiService from './ApiService/ApiService';

import WebFont from 'webfontloader';
WebFont.load({
  google: { families: ['Open+Sans:300,400,600,700'] },
  custom: {
    families: [
      'Flaticon',
      'Font Awesome 5 Solid',
      'Font Awesome 5 Regular',
      'Font Awesome 5 Brands'
    ],
    urls: [`${process.env.PUBLIC_URL}/css/fonts.css`]
  },
  active: function() {
    sessionStorage.fonts = true;
  }
});

const renderApp = () => {
  //React Render
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
};

// renderApp();
ApiService.initKeycloak(renderApp);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
