import React, { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core/';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ListItemIcon from '@material-ui/core/ListItemIcon';

const DotMenu = ({ actionDefs, data, visible }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = (event) => {
    setAnchorEl(event.target);
  };

  const onItemClickInternal = (item) => {
    item.handleClick(data);
    handleClose();
  };

  const items =
    typeof actionDefs !== 'function' ? actionDefs || [] : actionDefs(data);

  return (
    <>
      <IconButton
        onClick={handleToggle}
        hidden={!visible || items.length === 0}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        disableScrollLock={true}
      >
        {items.map((item, key) => (
          <MenuItem
            key={key}
            onClick={() => onItemClickInternal(item)}
            disabled={item.disabled}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default DotMenu;
