import React from 'react';
import PropTypes from 'prop-types';
import {
  TableHead,
  TableCell,
  TableRow,
  TableSortLabel,
  Checkbox,
} from '@material-ui/core/';
import Dotmenu from './DotMenu';

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    columnDefs,
    actionDefs,
  } = props;
  const createSortHandler = (header) => (event) => {
    console.log(`sort ${header.id} in ${order} order`); // debug
    onRequestSort(event, header);
  };

  return (
    <TableHead className={classes.header}>
      <TableRow>
        <TableCell padding="checkbox" align="left">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all documents' }}
          />
        </TableCell>
        {columnDefs.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy.id === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy.id === headCell.id}
              direction={orderBy.id === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell)}
            >
              {headCell.label}
              {orderBy.id === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {actionDefs && actionDefs.length !== 0 && (
          <TableCell align="right">
            <Dotmenu
              actionDefs={(typeof actionDefs !== 'function'
                ? actionDefs || []
                : actionDefs()
              ).filter((item) => item.show !== 'cell')}
              data={null}
              visible={!!actionDefs}
            />
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.object.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default EnhancedTableHead;
