import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Drawer, IconButton, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    minWidth: '40%',
  },
  drawerHeader: {
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    background:
      'linear-gradient(273deg, rgba(2,0,36,1) 9%, rgba(16,39,91,1) 30%, rgba(25,90,207,1) 100%)',
  },
  title: {
    color: 'white',
    fontWeiht: 'bold',
  },

  cross: {
    color: 'white',
  },
}));

function InfoDrawer({ open, handleClose, children, title }) {
  const classes = useStyles();

  return (
    <Drawer
      // variant="persistent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      <Grid
        container
        className={classes.drawerHeader}
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item className={classes.title}>
          {title && title.toUpperCase()}
        </Grid>
        <Grid item>
          <IconButton className={classes.cross} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      {children}
    </Drawer>
  );
}

export default InfoDrawer;
