import React from 'react';
import { Button } from '@material-ui/core';
import { toast } from 'react-toastify';
import ExchangeService from '../ApiService/ExchangeService';

const ExchangeAdmin = () => {
  const actions = [
    { name: 'startmarket', func: () => ExchangeService.startmarket() },
    { name: 'premarketopen', func: () => ExchangeService.premarketopen() },
    { name: 'marketopen', func: () => ExchangeService.marketopen() },
    { name: 'marketclose', func: () => ExchangeService.marketclose() },
    { name: 'postmarketclose', func: () => ExchangeService.postmarketclose() },
  ];
  return (
    <>
      <h3>EXCHANGE</h3>
      {actions.map((x) => (
        <Button
          key={x.name}
          variant="contained"
          color="primary"
          onClick={() => {
            x.func()
              .then((res) => {
                toast(`${x.name} success`);
              })
              .catch((err) => {
                toast.error(`${x.name} failed\n ${err}`);
              });
          }}
        >
          {x.name}
        </Button>
      ))}
    </>
  );
};

export default ExchangeAdmin;
