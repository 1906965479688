import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { Typography, MenuItem } from '@material-ui/core';

import TextFieldWithValidation from '../TextFieldWithValidation';
import DialogForm from '../DialogForm';
import ApiService from '../../ApiService/ApiService';
import ControlledSelectWithValidation from '../ControlledSelectWithValidation';

const BuyDialog = ({ open, closeDialog, update, bond }) => {
  const { register, control, handleSubmit, reset, errors } = useForm();

  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    ApiService.getCurrentAccounts(setAccounts);
  }, []);

  const onSubmit = (data, e) => {
    var order = {
      // account_no: '12b4bb3f-11fa-42fe-8f37-4aaf8ae899d0',
      sec_key: bond.key,
      type: 'bond',
      ...data,
    };
    console.log('buy bond: ', order);
    ApiService.buyProduct(null, order).then((d) => {
      if (d) {
        toast.success(
          `Successfully purchased ${order.units} units of ${bond.key}!`
        );
        update();
        closeDialog();
        e.target.reset();
        reset();
      }
    });
  };

  return (
    bond &&
    accounts.length > 0 && (
      <DialogForm
        open={open}
        onClose={() => {
          closeDialog();
          reset();
        }}
        onSubmit={handleSubmit(onSubmit)}
        title={`BUY - ${bond.key}`}
        maxWidth="sm"
      >
        <Typography>{bond.key}</Typography>
        <Typography>{bond.description}</Typography>
        <Typography>Unit Price {bond.price.toLocaleString()}</Typography>
        <ControlledSelectWithValidation
          control={control}
          name={`account_no`}
          label="Account for purchase"
          errors={errors}
          rules={{
            required: 'Choose an account',
          }}
          required
          fullWidth
        >
          <MenuItem value="" disabled={true}>
            Select an account...
          </MenuItem>
          {accounts.map((x, i) => (
            <MenuItem key={i} value={x.account_no}>
              {x.account_no} {x.remarks}
            </MenuItem>
          ))}
        </ControlledSelectWithValidation>
        <TextFieldWithValidation
          errors={errors}
          label={`Buy Units`}
          name="units"
          inputRef={register({
            required: 'please specify number of units to buy',
            maxLength: {
              value: 30,
              message: '30 characters or fewer',
            },
          })}
          fullWidth
          error={typeof errors.firstName !== 'undefined'}
        />
      </DialogForm>
    )
  );
};

export default BuyDialog;
