import React from 'react';
import InfoGrid from '../DetailedInfo/InfoGrid';
import moment from 'moment';
import DescriptionIcon from '@material-ui/icons/Description';
import { Button } from '@material-ui/core';
import { round } from '../DiamondMarket/assets';
import { Link } from 'react-router-dom';

function DetailInfo({ data, update }) {
  const fieldDefs = [
    {
      id: 'id',
      numeric: false,
      disablePadding: false,
      label: 'SecKey',
      valueOf: (diamond) => (
        <Link style={{ color: 'white' }} to={'/exchange/trade/' + diamond.key}>
          {diamond.key}
        </Link>
      ),
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: false,
      label: 'Description',
      valueOf: (diamond) => diamond.description,
    },
    {
      id: 'issueSize',
      numeric: false,
      disablePadding: false,
      label: 'Issue Size',
      valueOf: (diamond) => diamond.issueSize,
    },
    {
      id: 'issuer',
      numeric: false,
      disablePadding: false,
      label: 'Issuer',
      valueOf: (diamond) => diamond.issuer,
    },
    {
      id: 'laboratory',
      numeric: false,
      disablePadding: false,
      label: 'Laboratory',
      valueOf: (diamond) => diamond.laboratory,
    },
    {
      id: 'shape',
      numeric: false,
      disablePadding: false,
      label: 'Shape',
      valueOf: (diamond) =>
        diamond.shape === 'Round' ? (
          <span>
            {diamond.shape.toUpperCase()}{' '}
            <img height="40px" src={round} alt="round" />
          </span>
        ) : (
          diamond.shape
        ),
    },
    {
      id: 'carat',
      numeric: false,
      disablePadding: false,
      label: 'Carat',
      valueOf: (diamond) => diamond.carat,
    },
    {
      id: 'color',
      numeric: false,
      disablePadding: false,
      label: 'Color',
      valueOf: (diamond) => diamond.color,
    },
    {
      id: 'clarity',
      numeric: false,
      disablePadding: false,
      label: 'Clarity',
      valueOf: (diamond) => diamond.clarity,
    },
    {
      id: 'cut',
      numeric: false,
      disablePadding: false,
      label: 'Cut',
      valueOf: (diamond) => diamond.cut,
    },
    {
      id: 'polish',
      numeric: false,
      disablePadding: false,
      label: 'Polish',
      valueOf: (diamond) => diamond.polish,
    },
    {
      id: 'symmetry',
      numeric: false,
      disablePadding: false,
      label: 'Symmetry',
      valueOf: (diamond) => diamond.symmetry,
    },
    {
      id: 'fluorescence',
      numeric: false,
      disablePadding: false,
      label: 'Fluorescence',
      valueOf: (diamond) => diamond.fluorescence,
    },
    {
      id: 'unitSize',
      numeric: false,
      disablePadding: false,
      label: 'Unit Size',
      valueOf: (diamond) => diamond.unitSize,
    },
    {
      id: 'docs',
      numeric: false,
      disablePadding: false,
      label: 'Documents',
      displayValueOf: (bond) => renderDocuments(bond.productDocuments),
    },
  ];

  const renderCoupons = (coupons) => {
    return (
      <>
        {coupons.map((x) => (
          <h3 key={x.id}>{moment(x.date).format('ll')}</h3>
        ))}
      </>
    );
  };

  const renderDocuments = (docs) => {
    return (
      <>
        {docs.map((x) => (
          <p key={x.docUrl}>
            <h3>
              {x.docName}
              <Button href={x.docUrl}>
                <DescriptionIcon />
              </Button>
            </h3>
            <i>{x.docDesc}</i>
          </p>
        ))}
      </>
    );
  };
  return <InfoGrid data={data} fieldDefs={fieldDefs} update={update} />;
}

export default DetailInfo;
