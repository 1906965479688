import axios from 'axios'; // chrome.exe --user-data-dir="C:/Chrome dev session" --disable-web-security
import { keyBy } from 'lodash';
import { handleError } from './Utilities';

const API = axios.create({
  baseURL: window['config'].apiBaseUrl.marketcore,
});

const ApiService = {
  setMarketCoreAuth(auth) {
    API.defaults.headers.common['Authorization'] = auth;
  },
  getFxRates() {
    return API.get(`currencies/fx`);
  },
  getBonds() {
    return API.get(`bonds/`);
  },
  getDiamonds() {
    return API.get(`diamonds/`);
  },
  getWines(setter) {
    console.log('updating list');
    return API.get(`wines/`)
      .then((response) => {
        const wines = response.data;
        console.log('wines resolved: ', response);
        setter && setter(wines);
        return wines;
      })
      .catch(handleError);
  },
  getProduct(type, key) {
    switch (type) {
      case 'Bond':
        return API.get(`bonds/${key}`).then(({ data }) => data);
      case 'Wine':
        return API.get(`wines/${key}`).then(({ data }) => data);
      default:
        return null;
    }
  },

  // Accounts
  buyProduct(setter, data) {
    console.log('buy wine');
    return API.post(`buy/`, data)
      .then((response) => {
        return response.data;
      })
      .catch(handleError);
  },
  getCurrentPositions(setter) {
    console.log('updating list');
    return API.get(`positions/current/`)
      .then((response) => {
        const positions = response.data;
        console.log('positions resolved: ', response);
        setter && setter(positions);
        return positions;
      })
      .catch(handleError);
  },
  getAccountTransactions(account_no, setter) {
    console.log('updating list');
    return API.get(`accounts/transactions/` + account_no)
      .then((response) => {
        const transactions = response.data;
        console.log('transactions resolved: ', response);
        setter && setter(transactions);
        return transactions;
      })
      .catch(handleError);
  },
  getAccountTrades(account_no, setter) {
    console.log('updating list');
    return API.get(`accounts/trades/` + account_no)
      .then((response) => {
        const trades = response.data;
        console.log('trades resolved: ', response);
        setter && setter(trades);
        return trades;
      })
      .catch(handleError);
  },
  getCurrentAccounts(setter) {
    console.log('updating list');
    return API.get(`accounts/current/`)
      .then((response) => {
        const accounts = response.data;
        console.log('accounts resolved: ', response);
        setter && setter(accounts);
        return accounts;
      })
      .catch(handleError);
  },
  getAccounts(setter) {
    console.log('updating list');
    return API.get(`accounts/`)
      .then((response) => {
        const accounts = response.data;
        console.log('accounts resolved: ', response);
        setter && setter(accounts);
        return accounts;
      })
      .catch(handleError);
  },
  openAccount(data, update) {
    return API.post('accounts/open/', data);
  },
  deposit(data) {
    return API.post('accounts/deposit/', data);
  },
  withdraw(account_no, amount, remarks) {
    return API.post('accounts/withdraw/', { account_no, amount, remarks });
  },

  // Bulkload
  bulkloadWhitelist(account_no, file) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return API.post(`bulkload/whitelist/${account_no.trim()}`, formData);
  },
  bulkloadBrokerPositions(account_no, file) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return API.post(`bulkload/brokerpositions/${account_no.trim()}`, formData);
  },
  bulkloadBonds(account_no, file) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return API.post(`bulkload/bonds/${account_no.trim()}`, formData);
  },
  bulkloadDocuments(account_no, file) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return API.post(`bulkload/documents`, formData);
  },
  bulkloadBondCoupons(account_no, file) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return API.post(`bulkload/bonds/coupons/${account_no.trim()}`, formData);
  },
  bulkloadWines(account_no, file) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return API.post(`bulkload/wines/${account_no.trim()}`, formData);
  },
  bulkloadDiamonds(account_no, file) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return API.post(`bulkload/diamonds/${account_no.trim()}`, formData);
  },
  bulkloadPrice(account_no, file) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return API.post(`bulkload/prices/${account_no.trim()}`, formData);
  },
  bulkloadPosition(file) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return API.post(`bulkload/initialpositions`, formData);
  },
  bulkloadSpot(file) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return API.post(`bulkload/spots`, formData);
  },
  bulkloadStaticFile(accessKey, secretKey, file) {
    const formData = new FormData();
    formData.append('accessKey', accessKey);
    formData.append('secretKey', secretKey);
    formData.append('file', file, file.name);
    return API.post(`bulkload/staticfile`, formData);
  },

  // Orders
  addOrder(order) {
    return API.post('orders/submit', order);
  },
  cancelOrder(order) {
    return API.post('orders/cancel', order);
  },
  getOpenOrders(ticker, account_no) {
    return API.post('orders/open', { ticker, account_no });
  },
  getOrderHistory(ticker, account_no) {
    return API.post('orders/history', { ticker, account_no });
  },
  // Blockchain
  getHolders() {
    return API.get('bulkload/holders');
  },
  getTokenTypes() {
    return API.get('bulkload/tokentypes');
  },
  getChainInfo() {
    return API.get('bulkload/chaininfo');
  },
  getAllCashouts() {
    // get all cashout requests of all account for all customers
    return API.get('accounts/cashouts');
  },
  getCashouts(sub) {
    // get all cashout requests of all account under this customer
    return API.get('accounts/cashouts/' + sub);
  },
  requestCashout(account_no, amount, remarks) {
    return API.post('accounts/cashouts', {
      account_no,
      amount,
      remarks,
    });
  },
  payCashout(cashout_id) {
    return API.post('accounts/paycashout', { cashout_id });
  },

  // Admin
  checktokenBalanceAdmin(address) {
    return API.get(`admin/tokenbalance/${address}`);
  },
  addCustodian(address) {
    return API.post(`admin/custodians/${address}`);
  },
  removeCustodian(address) {
    return API.delete(`admin/custodians/${address}`);
  },
  hasCustodianRole(address) {
    return API.get(`admin/custodians/hasrole/${address}`);
  },
  transferEth(address) {
    return API.post(`admin/eth/${address}`);
  },

  // Broker - Transactions
  checktokenBalanceBroker(address) {
    return API.get(`brokers/tokenbalance/${address}`);
  },
  transfer(recipient, amount, refId) {
    return API.post(`brokers/transfer`, { recipient, amount, refId });
  },

  // Broker - Settlement approval
  approveSettlement(allowance) {
    return API.post('brokers/approvesettlement/', { allowance });
  },
  rejectSettlement() {
    return API.post('brokers/rejectsettlement/', {});
  },
  checkSettlementAllowance() {
    return API.get('brokers/allowance/');
  },

  // Custodian
  getTransactionsCustodian(address) {
    return API.get(`custodians/transactions/${address}`);
  },
  checktokenBalanceCustodian(address) {
    return API.get(`custodians/tokenbalance/${address}`);
  },
  mintTokens(to, amount, refId) {
    return API.post(`custodians/mint`, { to, amount, refId });
  },
  burnTokens(from, amount, refId) {
    return API.post(`custodians/burn`, { from, amount, refId });
  },
};

export default ApiService;
