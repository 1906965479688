import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Typography,
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import MarketCoreService from '../ApiService/MarketCoreService';
import BurnTokensForm from './CustodianForms/BurnTokensForm';
import CheckTokenBalanceForm from './CustodianForms/CheckTokenBalanceForm';
import MintTokensForm from './CustodianForms/MintTokensForm';
import TokenBalance from './TokenBalance';
import TransactionsTable from './CustodianForms/TransactionsTable';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const CustodianView = () => {
  const classes = useStyles();
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    MarketCoreService.getCurrentAccounts(setAccounts);
  }, []);

  const list = [
    { title: 'Check Token Balance', component: <CheckTokenBalanceForm /> },
    {
      title: 'Token Transactions',
      component: <TransactionsTable address={accounts[0]?.address} />,
    },
    { title: 'Mint Token', component: <MintTokensForm /> },
    {
      title: 'Burn Token',
      component: <BurnTokensForm custodian={accounts[0]?.address} />,
    },
  ];

  return (
    <>
      <p>Custodian Accounts</p>
      <ul>
        {accounts.map((x) => (
          <li key={x.address}>
            <TokenBalance address={x.address} />
          </li>
        ))}
      </ul>

      {list.map((x) => (
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>{x.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>{x.component}</AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default CustodianView;
