import { createMuiTheme } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';

const theme = createMuiTheme({
  overrides: {
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)',
      },
    },
    MuiCssBaseline: {
      '@global': {
        html: {
          overflow: 'hidden',
        },
        body: {
          height: '100vh',
          overflow: 'auto',
        },
      },
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
  },
  palette: {
    type: 'dark',
    background: {
      paper: '#282C34',
      default: '#1c2025',
    },
    primary: {
      main: '#bb86fc',
    },
    secondary: {
      main: '#03dac5',
    },
    text: {
      primary: '#e6e5e8',
      secondary: '#adb0bb',
    },
    action: {
      active: 'rgba(255, 255, 255, 0.54)',
      hover: 'rgba(255, 255, 255, 0.04)',
      selected: 'rgba(255, 255, 255, 0.08)',
      disabled: 'rgba(255, 255, 255, 0.26)',
    },
  },
  shadows,
  typography,
});

export default theme;
