import BrokeromsService from './BrokerOMSService';
import KeycloakService from './KeycloakService';
import MarketCoreService from './MarketCoreService';
import MayanService from './MayanService';
import MayanxService from './MayanxService';
import OnBoardService from './OnBoardService';
import WfManagerService from './WfManagerService';
import WorkflowService from './WorkflowService';
import MarketDataService from './MarketDataService';
import BinanceService from './BinanceService';

const ApiService = {
  ...BrokeromsService,
  ...KeycloakService,
  ...MarketCoreService,
  ...MayanService,
  ...MayanxService,
  ...OnBoardService,
  ...WfManagerService,
  ...WorkflowService,
  ...MarketDataService,
  ...BinanceService,
  setAuth(token) {
    BrokeromsService.setBrokeromsAuth(token);
    MarketCoreService.setMarketCoreAuth(token);
    MayanService.setEdmsAuth(token);
    MayanxService.setEdmsAuth(token);
    OnBoardService.setOnBoardAuth(token);
    WfManagerService.setWfManangerServiceAuth(token);
    WorkflowService.setWorkflowServiceAuth(token);
    MarketDataService.setMarketDataAuth(token);
  },
  login() {
    // override
    // using token from keycloak-js
    const keycloak_token = KeycloakService.getToken();
    console.info('keycloak token: ', [keycloak_token]);
    this.setAuth(`Bearer ${keycloak_token}`);
    setInterval(
      () =>
        KeycloakService.updateToken().then((response) => {
          if (response) {
            const keycloak_token = KeycloakService.getToken();
            console.log('set Auth ', [keycloak_token]);
            this.setAuth(`Bearer ${keycloak_token}`);
          }
        }),
      60000
    );
    return keycloak_token;
  },
  logout() {
    MayanService.forgetEdmsAuth();
    KeycloakService.doLogout();
  },
};

export default ApiService;
