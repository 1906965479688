import React from 'react';
import { useForm } from 'react-hook-form';
import { TextField, Button } from '@material-ui/core';

import ApiService from '../../ApiService/ApiService';

function LoginForm({ refreshTimer, setIsLoggedIn, handleRefresh }) {
  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    let username = data['username'] || '';
    let password = data['password'] || '';
    username === '' && (username = 'admin');
    password === '' && (password = 'AXhcgBqRVJ');
    ApiService.login(username, password).then((refreshToken) => {
      if (refreshToken) {
        console.log('start refreshing');
        setIsLoggedIn(true);
        refreshTimer.current = setInterval(handleRefresh, 60000, refreshToken);
      } else {
        console.warn('no refresh key');
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        type="text"
        label="Username"
        name="username"
        inputRef={register}
        required
      />
      <TextField
        type="password"
        label="Password"
        name="password"
        inputRef={register}
        required
      />

      <Button type="submit" variant="contained" color="primary" fullWidth>
        Login
      </Button>
    </form>
  );
}

export default LoginForm;
