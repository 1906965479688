import React from 'react';
import { Table } from 'reactstrap';
import { groupOrders } from '../helpers';
import Grid from '@material-ui/core/Grid';
import { numberWithCommas } from './common';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  clickable: { cursor: 'pointer' },
}));

export const OrderBook = ({
  stock,
  precision,
  depth,
  bidColor,
  onPriceClick,
}) => {
  const classes = useStyles();

  const ask = stock.asks
    .sort((a, b) =>
      a.priceValue == 0
        ? 1
        : b.priceValue == 0
        ? -1
        : a.priceValue - b.priceValue
    )
    .slice(0, depth);
  const bid = stock.bids
    .sort((a, b) =>
      a.priceValue == 0
        ? 1
        : b.priceValue == 0
        ? -1
        : b.priceValue - a.priceValue
    )
    .slice(0, depth);

  console.log('stock.orders', stock.orders);
  console.log('stock.bids', bid);
  console.log('stock.asks', ask);

  return (
    <Grid container>
      <Grid item xs="6">
        <Table style={{ textAlign: 'right' }}>
          <thead>
            <tr>
              <td>Total</td>
              <td>Qty</td>
              <td>
                <b style={{ color: bidColor }}>BID</b>
              </td>
            </tr>
          </thead>
          <tbody>
            {bid.map((x) => (
              <tr>
                <td>{numberWithCommas(x.volume * x.priceValue, precision)}</td>
                <td>{numberWithCommas(x.volume)}</td>
                <td style={{ color: bidColor }}>
                  <div
                    className={classes.clickable}
                    onClick={() => onPriceClick(x.priceValue)}
                  >
                    {numberWithCommas(x.priceValue, precision)}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Grid>
      <Grid item xs="6">
        <Table style={{ textAlign: 'right' }}>
          <thead>
            <tr>
              <td>
                <b style={{ color: 'red' }}>ASK</b>
              </td>
              <td>Qty</td>
              <td>Total</td>
            </tr>
          </thead>
          <tbody>
            {ask.map((x) => (
              <tr>
                <td style={{ color: 'red' }}>
                  <div
                    className={classes.clickable}
                    onClick={() => onPriceClick(x.priceValue)}
                  >
                    {numberWithCommas(x.priceValue, precision)}
                  </div>
                </td>
                <td>{numberWithCommas(x.volume)}</td>
                <td>{numberWithCommas(x.volume * x.priceValue, precision)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Grid>
    </Grid>
  );
};
