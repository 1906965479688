import React, { useState } from 'react';
import moment from 'moment';
import { MenuItem, Grid, Chip, Button } from '@material-ui/core';

import EditableTextField from '../DetailedInfo/EditableTextField';
import UnEditableTextField from '../DetailedInfo/UnEditableTextField';
import EditableSelector from '../DetailedInfo/EditableSelector';
import UnEditableSelector from '../DetailedInfo/UnEditableSelector';
import InfoGrid from '../DetailedInfo/InfoGrid';
import OnBoardService from '../../ApiService/OnBoardService';

function DetailCustomerInfo({ data, params, update, edit, setEdit }) {
  const [isAdmin, setIsAdmin] = useState(false);

  console.log('customer page');
  // useEffect(() => {
  //   if (data) {
  //     // console.log('customer: ', data);
  //     // setIsAdmin(false); // dummy
  //     //  TODO: check a customer's (not the current user!) admin status
  //     // KeycloakService.loadUserProfile(data?.customerID).then(response => {
  //     //   console.log('user profile: ', response);
  //     //   setIsAdmin((response.attributes?.isAdmin && response.attributes?.isAdmin[0]) === 'true')
  //     });
  //   }
  // }, [data]);

  const fieldDefs = [
    {
      id: 'username',
      numeric: false,
      disablePadding: true,
      label: 'Username',
      valueOf: (customer) => customer.userName,
    },
    {
      id: 'id',
      label: 'ID',
      valueOf: (customer) => customer.customerID,
    },
    {
      id: 'firstName',
      label: 'First Name',
      valueOf: (customer) => customer.firstName,
      displayValueOf: (customer) => (
        <UnEditableTextField text={customer.firstName} />
      ),
      editField: (customer, formProps) => (
        <EditableTextField name="firstName" formProps={formProps} />
      ),
    },
    {
      id: 'lastName',
      label: 'Last Name',
      valueOf: (customer) => customer.lastName,
      displayValueOf: (customer) => (
        <UnEditableTextField text={customer.lastName} />
      ),
      editField: (customer, formProps) => (
        <EditableTextField name="lastName" formProps={formProps} />
      ),
    },
    {
      id: 'status',
      label: 'Status',
      valueOf: (customer) => customer.status.status,
      displayValueOf: (customer) => (
        <UnEditableSelector text={customer.status.status} />
      ),
      editField: (customer, formProps) => (
        <EditableSelector formProps={formProps} name="status">
          {params.CustomerStatus.map((status) => (
            <MenuItem key={status.id} value={status.status}>
              {status.status}
            </MenuItem>
          ))}
        </EditableSelector>
      ),
      editApiCall: (customer, newValue) => {
        console.log('editing customer status: ', customer);
        return OnBoardService.updateCustomerStatus(
          null,
          customer.customerID,
          newValue
        );
      },
    },
    {
      id: 'isAdmin',
      label: 'Is Admin?',
      valueOf: () => isAdmin,
      displayValueOf: () => isAdmin.toString(),
      editField: (customer, { register, control, setValue }) => (
        // <Checkbox
        //   name="isAdmin"
        //   inputRef={register}
        // />
        <input type="checkbox" name="isAdmin" ref={register} />
      ),
      editApiCall: (customer, newValue) => {
        console.log('editing customer admin right: ', customer, newValue);
      },
    },
    {
      id: 'createdDate',
      label: 'Created Date',
      valueOf: (customer) => moment(customer.createDate).format('lll'),
    },
    {
      id: 'emails',
      label: 'Emails',
      valueOf: (customer) => customer.emails,
      displayValueOf: (customer) => (
        <Grid container spacing={1} direction="column" alignItems="flex-start">
          {customer.emails.length ? (
            customer.emails.map((email, index) => (
              <Grid key={index} item xs>
                <Chip
                  size="small"
                  label={`${email.isDefault ? 'Default:' : ''} ${
                    email.emailAddr
                  }`}
                  variant="outlined"
                />
              </Grid>
            ))
          ) : (
            <Chip size="small" label="None" variant="outlined" disabled />
          )}
        </Grid>
      ),
    },
    {
      id: 'phones',
      label: 'Phones',
      valueOf: (customer) => customer.phones,
      displayValueOf: (customer) => (
        <Grid container spacing={1} direction="column" alignItems="flex-start">
          {customer.phones.length ? (
            customer.phones.map((phone, index) => (
              <Grid key={index} item xs>
                <Chip
                  size="small"
                  label={`${phone.phoneType.phoneTyp}: ${phone.phoneNo}`}
                  variant="outlined"
                />
              </Grid>
            ))
          ) : (
            <Chip size="small" label="None" variant="outlined" disabled />
          )}
        </Grid>
      ),
    },
    {
      id: 'enabling',
      label: 'Enabling',
      valueOf: (customer) => customer.id,
      displayValueOf: (customer) => (
        <>
          <Button
            onClick={async () =>
              await OnBoardService.enableCustomer(customer.customerID)
            }
            color="primary"
          >
            Enable
          </Button>
          <Button
            onClick={async () =>
              await OnBoardService.disableCustomer(customer.customerID)
            }
            color="secondary"
          >
            Disable
          </Button>
        </>
      ),
    },
  ];

  const handleSubmit = async (formData, e, customer) => {
    console.log('data', formData);
    let promises = [];
    fieldDefs.forEach((field) => {
      if (field.editField && field.editApiCall) {
        const formValue = formData[field.id];
        if (field.valueOf(customer) !== formValue) {
          promises.push(field.editApiCall(customer, formValue));
        }
      }
    });
    return promises;
  };

  return (
    <InfoGrid
      data={data}
      fieldDefs={fieldDefs}
      handleSubmit={handleSubmit}
      update={update}
      edit={edit}
      setEdit={setEdit}
    />
  );
}

export default DetailCustomerInfo;
