import React, { useEffect, useState, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import MarketCoreService from '../../../ApiService/MarketCoreService';
import BrokerOMSService from '../../../ApiService/BrokerOMSService';

import Button from '@material-ui/core/Button';
import {
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  buy: {
    backgroundColor: 'blue',
  },
  sell: {
    backgroundColor: 'red',
  },
}));

export const PlaceOrder = ({
  account_no,
  auth,
  stock,
  onOrder,
  xs = '6',
  selectedPrice,
}) => {
  const classes = useStyles();
  const refForm = useRef(null);

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      price: 0,
      qty: 0,
      total: 0,
    },
  });

  const enforcePositiveValue = {
    validate: (value) => value > 0 || 'Value must be postive.',
  };

  console.info('loading place order of symbol: ' + stock.securityKey);

  const onSubmit = (data) => {
    const order = {
      ...data,
      AccountNo: account_no,
      Sub: null,
      SecurityKey: stock.securityKey,
      Price: data.price,
      Qty: data.qty,
      OrderInstruction: data.timeinforce,
      Expiry: data.goodtill,
    };
    console.log('Add Order', order);
    BrokerOMSService.placeOrder(order)
      .then((res) => {
        console.log('Add Order ok', order);
      })
      .catch((error) => {
        console.log('placeOrder error: ', error);
      });
  };

  var today = new Date();
  var tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);

  const [type, setType] = useState('limit');
  const [tif, setTif] = useState('GTC');

  useEffect(() => {
    if (selectedPrice !== undefined) {
      setValue('price', selectedPrice.price);
    }
  }, [selectedPrice]);

  return (
    <>
      <form ref={refForm} formId="basic">
        <h4>Ticker</h4>
        <h4>{stock.securityKey}</h4>
        <Controller
          as={TextField}
          name="price"
          label="Price"
          required
          rules={enforcePositiveValue}
          control={control}
        />
        <Controller
          as={TextField}
          name="qty"
          label="Amount"
          required
          rules={enforcePositiveValue}
          control={control}
        />
        <Grid container>
          <Grid item xs={4}>
            <InputLabel id="type">Type</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <Controller
              as={
                <Select
                  labelId="type"
                  value={type}
                  onChange={(e) => {
                    setType(e.target.value);
                  }}
                >
                  <MenuItem key="limit" value="limit">
                    LIMIT
                  </MenuItem>
                  <MenuItem key="market" value="market">
                    MARKET
                  </MenuItem>
                </Select>
              }
              name="ordertype"
              required
              control={control}
              defaultValue={type}
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel id="type">Time in force</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <Controller
              as={
                <Select
                  labelId="tif"
                  value={tif}
                  onChange={(e) => {
                    setTif(e.target.value);
                  }}
                >
                  <MenuItem key="GTC" value="GTC">
                    GTC
                  </MenuItem>
                  <MenuItem key="IOC" value="IOC">
                    IOC
                  </MenuItem>
                  <MenuItem key="FOK" value="FOK">
                    FOK
                  </MenuItem>
                  <MenuItem key="AON" value="AON">
                    AON
                  </MenuItem>
                </Select>
              }
              name="timeinforce"
              required
              control={control}
              defaultValue={tif}
            />
          </Grid>
        </Grid>
        <TextField
          name="goodtill"
          label="Good Till"
          value={moment(today).format('ll')}
          required
        />
      </form>
      <Button
        onClick={handleSubmit((data) => onSubmit({ ...data, Side: '1' }))}
        className={classes.buy}
        disabled={!account_no}
      >
        Buy
      </Button>
      <Button
        onClick={handleSubmit((data) => onSubmit({ ...data, side: '2' }))}
        className={classes.sell}
        disabled={!account_no}
      >
        Sell
      </Button>
    </>
  );
};
