import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import CheckTokenBalanceForm from './BrokerForms/CheckTokenBalanceForm';
import TransferForm from './BrokerForms/TransferForm';
import TokenBalance from './TokenBalance';
import MarketCoreService from '../ApiService/MarketCoreService';
import BrokerApprove from './BrokerApprove';
import TransactionsTable from './CustodianForms/TransactionsTable';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const BrokerView = () => {
  const classes = useStyles();
  const [broker, setBroker] = useState(null);

  useEffect(() => {
    MarketCoreService.getChainInfo().then((res) =>
      setBroker(res.data.contract.nodeAccount)
    );
  }, []);

  const list = [
    { title: 'Check Token Balance', component: <CheckTokenBalanceForm /> },
    {
      title: 'Token Transactions',
      component: <TransactionsTable address={broker} />,
    },
    { title: 'Transfer Tokens', component: <TransferForm /> },
    { title: 'Settlement Approval', component: <BrokerApprove /> },
  ];

  return (
    <>
      <p>Broker Node Account</p>
      <ul>
        <li>{broker && <TokenBalance address={broker} />}</li>
      </ul>

      {list.map((x) => (
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>{x.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>{x.component}</AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default BrokerView;
