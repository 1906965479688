import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TablePagination,
  Checkbox,
  Grid,
  makeStyles,
} from '@material-ui/core/';
import EnhancedTableHead from './EnhancedTableHead';
import EnhancedTableToolBar from './EnhancedTableToolBar';
import Divider from '@material-ui/core/Divider';
import Dotmenu from './DotMenu';

function descendingComparator(a, b, key) {
  // order by key
  if (key(b) < key(a)) {
    return -1;
  }
  if (key(b) > key(a)) {
    return 1;
  }
  return 0;
}

function getComparator(order, key) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, key)
    : (a, b) => -descendingComparator(a, b, key);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      '& .MuiTableCell-sizeSmall': {
        paddingRight: theme.spacing(1),
      },
      '& .MuiTableCell-root': {
        whiteSpace: 'nowrap',
      },
    },
    paper: {},
    table: {},
    tableCell: {},
    visuallyHidden: {},
  }),
  { name: 'EnhancedTable' }
);

function EnhancedTable({
  classes: classesProp,
  columnDefs,
  operationDefs,
  dataList: rawDataList,
  selected,
  setSelected,
  title,
  valueOfprimaryKey,
  actionDefs,
  initialOrder,
  initialOrderBy,
}) {
  const classes = useStyles({ classes: classesProp });
  const getPrimaryKey = valueOfprimaryKey || ((obj) => obj['id']); // "selected" stores a list of id

  const [order, setOrder] = useState(initialOrder || 'asc');
  const [orderBy, setOrderBy] = useState(initialOrderBy || columnDefs[0]);
  const [filteredDataList, setData] = useState(rawDataList);
  const [onScreenDataList, setDataOnScreen] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [searchQueryObj, setSearchQueryObj] = useState(null);
  const query = new URLSearchParams(useLocation().search);
  const location = useLocation();
  const { push } = useHistory();

  const getParamFromUrl = () => {
    let queryObj = {};
    const searchInAnyField = query.get('any');
    if (searchInAnyField?.length) {
      queryObj['any'] = searchInAnyField;
    }
    columnDefs.forEach((def) => {
      const queryRequest = query.get(def.id);
      if (queryRequest?.length) {
        queryObj[def.id] = queryRequest;
      }
    });
    setSearchQueryObj(queryObj);
  };

  useEffect(getParamFromUrl, []);

  useEffect(() => {
    const isMatchedValue = (wholeData, searchContent) => {
      wholeData = wholeData?.toString().toLowerCase();
      searchContent = searchContent?.toLowerCase();
      return (
        wholeData && searchContent && wholeData.indexOf(searchContent) !== -1
      );
    };
    if (rawDataList.length !== 0) {
      let filteredDatum = rawDataList;
      if (searchQueryObj) {
        const queryRequest = searchQueryObj['any'];
        if (queryRequest) {
          filteredDatum = filteredDatum.filter((data) =>
            columnDefs.some((def) =>
              isMatchedValue(def.valueOf(data), queryRequest)
            )
          );
        }
        columnDefs.forEach((def) => {
          const queryRequest = searchQueryObj[def.id];
          if (queryRequest) {
            filteredDatum = filteredDatum.filter((data) =>
              isMatchedValue(def.valueOf(data), queryRequest)
            );
          }
        });
        setData(filteredDatum);
      }
    } else {
      setData(rawDataList);
    }
  }, [rawDataList, searchQueryObj]);

  useEffect(() => {
    setDataOnScreen(
      stableSort(
        filteredDataList,
        getComparator(order, (obj) => orderBy.valueOf(obj))
      ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    );
  }, [page, rowsPerPage, order, orderBy, filteredDataList]);

  const putParamToUrl = (queryObj) => {
    const searchQuery = Object.entries(queryObj || searchQueryObj)
      .filter(([key, value]) => value?.length)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    push(`${location.pathname}${searchQuery?.length ? `?${searchQuery}` : ''}`);
  };

  const handleChangeSearch = (e) => {
    e.persist();
    setSearchQueryObj((old) => ({ ...old, any: e.target?.value }));
  };

  const handleSearchLeave = () => {
    putParamToUrl();
  };

  const handleDeleteFilter = (key) => () => {
    setSearchQueryObj((old) => {
      const newQueryObj = { ...old, [key]: '' };
      putParamToUrl(newQueryObj);
      return newQueryObj;
    });
  };

  const handleRequestSort = (event, header) => {
    const isAsc = orderBy.id === header.id && order === 'asc';
    // console.log(orderBy === header, ' ', order === 'asc');
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(header);
    // console.log('order by ', header, ' ', isAsc ? 'desc' : 'asc')
    setSelected((old) => (old.length ? [] : old));
  };

  const handleSelectAllClick = (event) => {
    setSelected((old) =>
      event.target.checked && !old.length
        ? onScreenDataList.map((obj) => getPrimaryKey(obj))
        : []
    );
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    console.log('selected: ', newSelected.length);

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (primaryKey) =>
    selected && selected.indexOf(primaryKey) !== -1;

  const handleFilter = () => {};

  const generateHoverEvents = (index) => ({
    onMouseEnter: () => setHoveredRow(index),
    onMouseLeave: () => setHoveredRow((old) => (old === index ? null : index)),
  });

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container direction="column" alignItems="stretch">
          <Grid item xs>
            <EnhancedTableToolBar
              operationDefs={operationDefs}
              selected={selected}
              title={title}
              handleChange={handleChangeSearch}
              handleFilter={handleFilter}
              searchQueryObj={searchQueryObj}
              handleDeleteFilter={handleDeleteFilter}
              handleSearchLeave={handleSearchLeave}
            />
            <Divider />
          </Grid>

          <Grid item xs>
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size="small"
                aria-label="sticky table"
                stickyHeader
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={filteredDataList.length} // should be data.result.count instead
                  columnDefs={columnDefs}
                  actionDefs={actionDefs}
                />
                <TableBody>
                  {onScreenDataList.map((obj, index) => {
                    const isItemSelected = isSelected(getPrimaryKey(obj));
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={getPrimaryKey(obj)}
                        selected={isItemSelected}
                      >
                        <TableCell
                          padding="checkbox"
                          align="left"
                          {...generateHoverEvents(index)}
                        >
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                            onClick={(event) =>
                              handleClick(event, getPrimaryKey(obj))
                            }
                            style={{
                              visibility:
                                selected.length || hoveredRow === index
                                  ? 'visible'
                                  : 'hidden',
                            }}
                          />
                        </TableCell>
                        <TableCell
                          // component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          align="left"
                          {...generateHoverEvents(index)}
                        >
                          {columnDefs[0].displayValueOf
                            ? columnDefs[0].displayValueOf(obj)
                            : columnDefs[0].valueOf(obj)}
                        </TableCell>
                        {columnDefs.slice(1).map((heading, index2) => (
                          <TableCell
                            key={index2}
                            align="left"
                            {...generateHoverEvents(index)}
                            className={classes.tableCell}
                          >
                            {heading.displayValueOf
                              ? heading.displayValueOf(obj)
                              : heading.valueOf(obj)}
                          </TableCell>
                        ))}
                        {actionDefs && actionDefs.length !== 0 && (
                          <TableCell
                            align="right"
                            {...generateHoverEvents(index)}
                          >
                            <Dotmenu
                              data={obj}
                              actionDefs={(typeof actionDefs !== 'function'
                                ? actionDefs || []
                                : actionDefs(obj)
                              ).filter((item) => item.show !== 'header')}
                              visible={true} //{hoveredRow === index}
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                  {/* {emptyRows > 0 && (
                        <TableRow
                          style={{
                            height: 33 * emptyRows,
                          }}
                        >
                          <TableCell colSpan={6} />
                        </TableRow>
                      )} */}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={filteredDataList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Grid>
          {/* <Grid item xs>
                <Grid container justify="flex-end">
                  <TableRow>
                    <Paper>
                    </Paper>
                  </TableRow>
                </Grid>
              </Grid> */}
        </Grid>
      </Paper>
    </div>
  );
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  columnDefs: PropTypes.array.isRequired,
  operationDefs: PropTypes.array.isRequired,
  dataList: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default EnhancedTable;
