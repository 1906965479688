import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { createStyles, withStyles } from '@material-ui/core/styles';

const styles = (theme) =>
  createStyles({
    root: {
      '& .Mui-selected .MuiListItemText-root': {
        color: theme.palette.secondary.main,
      },
    },
  });

function ViewSelector({ classes, views }) {
  return (
    <List className={classes.root}>
      {views.map(
        (view, index) =>
          view && (
            <ListItem
              button
              // NavLink props
              component={NavLink}
              key={index}
              to={view.path}
              activeClassName="Mui-selected"
            >
              <ListItemIcon>{view.icon}</ListItemIcon>
              <ListItemText color="inherit" primary={view.text} />
            </ListItem>
          )
      )}
    </List>
  );
}

export default withStyles(styles)(ViewSelector);
