import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import PersonAddIcon from '@material-ui/icons/PersonAdd';
import DeleteIcon from '@material-ui/icons/Delete';
import RefreshIcon from '@material-ui/icons/Refresh';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

import CreateNewCustomerDialog from './CreateNewCustomerDialog';
import ConfirmationDialog from '../ConfirmationDialog';
import DetailCustomerInfo from './DetailCustomerInfo';
import EnhancedTable from '../EnhancedTableView/EnhancedTable';
import InfoDrawer from '../DetailedInfo/InfoDrawer';
import ApiService from '../../ApiService/ApiService';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

function CustomersManageView({ isLoggedIn }) {
  const [customerList, setCustomerList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [params, setParams] = useState([]);
  const [infoData, setInfoData] = useState(null);
  const [selected, setSelected] = useState([]);
  const [openDialog, setOpenDialog] = useState(null);
  const [edit, setEdit] = useState(false);
  const [confirmed, setConfirmed] = useState(null);

  const classes = useStyles();

  const [updateFlag, setUpdateFlag] = useState(false);
  const update = () => setUpdateFlag((old) => !old);
  const handleUpdate = () => {
    if (isLoggedIn) {
      ApiService.getCustomers((customers) => {
        setCustomerList(customers);
        infoData &&
          setInfoData(
            customers.find(
              (customer) => customer.customerID === infoData.customerID
            )
          );
      });
      //ApiService.getGroups(setGroupList);
      ApiService.getOnBoardingParams(setParams);
    } else {
      setCustomerList([]);
    }
  };

  useEffect(handleUpdate, [updateFlag, isLoggedIn]);

  const columnDefs = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Customer Name',
      valueOf: (customer) => (
        <>{`${customer.firstName} ${customer.lastName.toUpperCase()}`}</>
      ),
    },
    {
      id: 'username',
      numeric: false,
      disablePadding: false,
      label: 'Username',
      valueOf: (customer) => customer.userName,
    },
    {
      id: 'id',
      numeric: true,
      disablePadding: false,
      label: 'ID',
      valueOf: (customer) => customer.customerID,
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: 'Status',
      valueOf: (customer) => customer.status.status,
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: 'Default Email',
      valueOf: (customer) =>
        customer.emails.find((email) => email.isDefault)?.emailAddr || 'None',
    },
    {
      id: 'phone',
      numeric: false,
      disablePadding: false,
      label: 'Phone',
      valueOf: (customer) =>
        customer.phones
          .map((phone) => phone.phoneType.phoneTyp + ': ' + phone.phoneNo)
          .join('\n') || 'None',
    },
  ];

  // Operations
  const handleDelete = (cus) => {
    let promises = !cus
      ? selected.map((customerID) =>
          ApiService.deleteCustomer(null, customerID)
        )
      : [ApiService.deleteCustomer(null, cus.customerID)];
    Promise.all(promises).then((response) => {
      setSelected(selected.filter((x, i) => !response[i]));
      update();
    });
  };

  const opDefs = [
    {
      label: 'Create Customer',
      ariaLabel: 'create customer',
      icon: <PersonAddIcon />,
      handleClick: () => setOpenDialog('create'),
    },
    {
      label: 'Refresh',
      ariaLabel: 'refresh',
      icon: <RefreshIcon />,
      handleClick: update,
    },
  ];

  const actionDefs = [
    {
      label: 'View',
      icon: <VisibilityIcon />,
      handleClick: (data) => {
        setInfoData(data);
        setEdit(false);
      },
      show: 'cell',
    },
    {
      label: 'Edit',
      icon: <EditIcon />,
      handleClick: (data) => {
        setInfoData(data);
        setEdit(true);
      },
      show: 'cell',
    },
    {
      label: 'Delete',
      icon: <DeleteIcon />,
      handleClick: (data) => {
        setOpenDialog('Delete Customer');
        setConfirmed(() => () => handleDelete(data));
      },
      show: 'both',
    },
  ];

  return (
    <>
      <EnhancedTable
        classes={classes}
        columnDefs={columnDefs}
        operationDefs={opDefs}
        actionDefs={actionDefs}
        dataList={customerList}
        selected={selected}
        setSelected={setSelected}
        title="Customers"
        valueOfprimaryKey={(customer) => customer.customerID}
      />
      <InfoDrawer
        open={infoData !== null}
        handleClose={() => setInfoData(null)}
        title="Customer Detail"
      >
        <DetailCustomerInfo
          data={infoData}
          params={params}
          update={update}
          edit={edit}
          setEdit={setEdit}
        />
      </InfoDrawer>

      <CreateNewCustomerDialog
        open={openDialog === 'create'}
        closeDialog={() => setOpenDialog('')}
        prevGroups={groupList}
        params={params}
        update={update}
      />
      <ConfirmationDialog
        closeDialog={() => setOpenDialog('')}
        handleConfirmed={() => confirmed()}
        title="Delete Customer"
        open={openDialog === 'Delete Customer'}
        message="Are you sure delete the customer(s)? "
      />
    </>
  );
}

export default CustomersManageView;
