import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';

import RefreshIcon from '@material-ui/icons/Refresh';

import DetailInfo from './DetailInfo';
import EnhancedTable from '../EnhancedTableView/EnhancedTable';
import InfoDrawer from '../DetailedInfo/InfoDrawer';
import ApiService from '../../ApiService/ApiService';
import VisibilityIcon from '@material-ui/icons/Visibility';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

function WineView({ isLoggedIn }) {
  const [wineList, setWineList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [infoData, setInfoData] = useState(null);
  const classes = useStyles();

  const [updateFlag, setUpdateFlag] = useState(false);
  const update = () => setUpdateFlag((old) => !old);
  const handleUpdate = () => {
    let mounted = true;
    if (isLoggedIn && mounted) {
      ApiService.getWines().then((wines) => {
        if (wines && mounted) {
          setWineList(wines.filter((x) => x.issued_size > 0));
          infoData &&
            setInfoData((old) => wines.find((doc) => doc.id === old.id));
        }
      });
    } else {
      setWineList([]);
    }

    return () => {
      mounted = false;
    };
  };

  useEffect(handleUpdate, [updateFlag, isLoggedIn]);

  const columns = [
    {
      id: 'id',
      numeric: false,
      disablePadding: false,
      label: 'SecKey',
      valueOf: (wine) => wine.sec_key,
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
      valueOf: (wine) => wine.desc,
    },
    {
      id: 'vinatage',
      numeric: false,
      disablePadding: false,
      label: 'Vintage',
      valueOf: (wine) => wine.vintage,
    },
    {
      id: 'unit_size',
      numeric: false,
      disablePadding: false,
      label: 'Unit Size',
      valueOf: (wine) => wine.unit_size,
    },
    {
      id: 'price',
      numeric: true,
      disablePadding: false,
      label: 'Price',
      valueOf: (wine) => wine.price,
      displayValueOf: (wine) => wine.price.toLocaleString(),
    },
    {
      id: 'origin',
      numeric: false,
      disablePadding: false,
      label: 'Origin',
      valueOf: (wine) => wine.origin,
    },
    {
      id: 'region',
      numeric: false,
      disablePadding: false,
      label: 'Region',
      valueOf: (wine) => wine.region,
    },
    // {
    //   id: 'producer',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'Producer',
    //   valueOf: (wine) => wine.producer,
    // },
    // {
    //   id: 'grape',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'Grape',
    //   valueOf: (wine) => wine.grape,
    //   displayValueOf: (wine) => wine.grape,
    // },
    {
      id: 'style',
      numeric: false,
      disablePadding: false,
      label: 'Style',
      valueOf: (wine) => wine.style,
      displayValueOf: (wine) => wine.style,
    },
    {
      id: 'issued_size',
      numeric: true,
      disablePadding: false,
      label: 'Issued Size',
      valueOf: (wine) => wine.issued_size,
      displayValueOf: (wine) => wine.issued_size,
    },
    {
      id: 'issuer',
      numeric: false,
      disablePadding: false,
      label: 'Issuer',
      valueOf: (wine) => wine.issuer,
      displayValueOf: (wine) => wine.issuer,
    },
    {
      id: 'outstanding',
      numeric: true,
      disablePadding: false,
      label: 'Outstanding',
      valueOf: (wine) => wine.outstanding,
      displayValueOf: (wine) => wine.outstanding,
    },
    {
      id: 'risk',
      numeric: true,
      disablePadding: false,
      label: 'Risk',
      valueOf: (wine) => wine.risk,
    },
    {
      id: 'update_date',
      numeric: false,
      disablePadding: false,
      label: 'Updated On',
      valueOf: (wine) => wine.update_date,
      displayValueOf: (wine) => moment(wine.update_date).format('ll'),
    },
  ];

  const opDefs = [
    {
      label: 'refresh',
      icon: <RefreshIcon />,
      handleClick: update,
    },
  ];
  const actionDefs = [
    {
      label: 'View',
      icon: <VisibilityIcon />,
      handleClick: setInfoData,
      show: 'cell',
    },
  ];

  return (
    <>
      <EnhancedTable
        classes={classes}
        columnDefs={columns}
        operationDefs={opDefs}
        dataList={wineList}
        selected={selected}
        setSelected={setSelected}
        actionDefs={actionDefs}
        title="Products"
        valueOfprimaryKey={(obj) => obj.sec_key}
      />
      {infoData && (
        <InfoDrawer
          // style={{ width: '50%' }}
          open={infoData !== null}
          handleClose={() => setInfoData(null)}
          title="Product Detail"
        >
          <DetailInfo data={infoData} update={update} />
        </InfoDrawer>
      )}
    </>
  );
}

export default WineView;
