import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import {
  TextField,
  Typography,
  Input,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Card,
  Paper,
  IconButton,
  InputAdornment,
  FormHelperText,
  Grid,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { ErrorMessage } from '@hookform/error-message';

import TextFieldWithValidation from '../TextFieldWithValidation';
import DialogForm from '../DialogForm';
import FieldArraySection from '../FieldArraySection';
import ApiService from '../../ApiService/ApiService';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  content: {
    width: '100%',
    padding: theme.spacing(0, 2, 2, 2),
  },
  formControl: {
    minWidth: 120,
    margin: theme.spacing(0, 1),
  },
  paper: {
    width: '100%',
    minWidth: 200,
    padding: theme.spacing(1, 2),
    margin: theme.spacing(2, 0, 0, 0),
  },
  card: {
    width: '100%',
    minWidth: 200,
    padding: theme.spacing(1, 2),
    margin: theme.spacing(0, 0, 1, 0),
  },
}));

const CreateNewCustomerDialog = ({
  prevGroups,
  open,
  closeDialog,
  update,
  params,
}) => {
  const classes = useStyles();
  const { register, control, handleSubmit, reset, errors } = useForm({
    reValidateMode: 'onChange',
  });

  const emailsFieldArray = useFieldArray({
    control,
    name: 'emails',
  });

  const phonesFieldArray = useFieldArray({
    control,
    name: 'phones',
  });

  const addressesFeildArray = useFieldArray({
    control,
    name: 'addresses',
  });

  const onSubmit = (data, e) => {
    data.statusId = 1; // i.e. LEAD
    console.log('new Customer: ', data);
    ApiService.createCustomer(null, data).then((newCustomer) => {
      if (newCustomer) {
        toast.success(
          `Successfully created new Customer ${newCustomer.userName}!`
        );
        update();
        closeDialog();
        e.target.reset();
        reset();
      }
    });
  };
  const onError = (errors, e) => console.error('error: ', errors, e);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((old) => !old);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <DialogForm
      open={open}
      onClose={(formdata) => {
        closeDialog();
        reset();
      }}
      onSubmit={handleSubmit(onSubmit, onError)}
      title="Create New Customer"
      maxWidth="sm"
    >
      <TextFieldWithValidation
        errors={errors}
        label={`New Username`}
        name="username"
        inputRef={register({
          required: 'Enter Username',
          pattern: {
            value: /^[\w.@+-]+$/,
            message: 'Letters, digits and @.+-_ only',
          },
          maxLength: {
            value: 150,
            message: '150 characters or fewer',
          },
        })}
        fullWidth
        error={typeof errors.username !== 'undefined'}
      />
      <FormHelperText hidden={typeof errors.username !== 'undefined'}>
        You can use letters,numbers and @.+-_
      </FormHelperText>

      <FormControl fullWidth>
        <InputLabel required error={typeof errors.password !== 'undefined'}>
          New Password
        </InputLabel>
        <Input
          label={`New Password`}
          type={showPassword ? 'text' : 'password'}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                tabIndex={-1}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          name="password"
          inputRef={register({
            required: 'Enter Password',
            pattern: {
              value: /[^0-9]/,
              message: "Your password can't be entirely numeric.",
            },
            minLength: {
              value: 8,
              message: 'Your password must contain at least 8 characters',
            },
          })}
          error={typeof errors.password !== 'undefined'}
        />
        <ErrorMessage
          errors={errors}
          name="password"
          as={<FormHelperText id={`password-error-text`} error />}
        />
      </FormControl>
      <FormHelperText hidden={typeof errors.password !== 'undefined'}>
        Use 8 or more characters with a mix of letters, numbers
      </FormHelperText>

      <TextFieldWithValidation
        errors={errors}
        label={`New First Name`}
        name="firstName"
        inputRef={register({
          required: 'Enter First Name',
          maxLength: {
            value: 30,
            message: '30 characters or fewer',
          },
        })}
        fullWidth
        error={typeof errors.firstName !== 'undefined'}
      />
      <TextFieldWithValidation
        errors={errors}
        label={`New Last Name`}
        name="lastName"
        inputRef={register({
          required: 'Enter Last Name',
          maxLength: {
            value: 150,
            message: '30 characters or fewer',
          },
        })}
        fullWidth
        error={typeof errors.lastName !== 'undefined'}
      />
      <Paper className={classes.paper}>
        <Typography variant="subtitle1">
          <b>Emails</b>
        </Typography>
        <TextFieldWithValidation
          errors={errors}
          label={`Default Email`}
          name={`emails[0].emailAddr`}
          inputRef={register({
            required: 'Enter Email',
            pattern: {
              value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
              message: 'Please enter a valid email.',
            },
          })}
          error={
            typeof errors.emails !== 'undefined' &&
            typeof errors.emails[0] !== 'undefined'
          }
          fullWidth
        />
        <Input
          name={`emails[0].isDefault`}
          type="hidden"
          disabled
          defaultValue={true}
          inputRef={register()}
        />
        <FormHelperText
          hidden={
            typeof errors.emails !== 'undefined' &&
            typeof errors.emails[0] !== 'undefined'
          }
        >
          Use letters, numbers and periods
        </FormHelperText>
        <FieldArraySection
          {...emailsFieldArray}
          render={(item, index) => (
            <div key={item.id}>
              <TextFieldWithValidation
                errors={errors}
                label={`Email ${index + 1}`}
                name={`emails[${index + 1}].emailAddr`}
                inputRef={register({
                  required: 'Enter Default Email',
                  pattern: {
                    value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                    message: 'Please enter a valid email.',
                  },
                })}
                error={
                  typeof errors.emails !== 'undefined' &&
                  typeof errors.emails[index + 1] !== 'undefined'
                }
                fullWidth
              />

              <Input
                name={`emails[${index + 1}].isDefault`}
                type="hidden"
                disabled
                defaultValue={false}
                inputRef={register()}
              />
            </div>
          )}
        />
      </Paper>

      <Paper className={classes.paper}>
        <Typography variant="subtitle1">
          <b>Phones</b>
        </Typography>
        <FieldArraySection
          {...phonesFieldArray}
          render={(item, index) => (
            <Card className={classes.card} key={item.id}>
              <Grid container spacing={2} sm>
                <Grid item xs={5}>
                  <FormControl fullWidth>
                    <InputLabel id="phoneType-label" required>
                      Phone Type
                    </InputLabel>
                    <Controller
                      as={
                        <Select
                          labelId="phoneType-label"
                          placeholder="Phone Type"
                          required
                        >
                          <MenuItem value="" disabled={true}>
                            Select...
                          </MenuItem>
                          {params?.PhoneType?.map((type) => (
                            <MenuItem key={type.id} value={type.id}>
                              {type.phoneTyp}
                            </MenuItem>
                          ))}
                        </Select>
                      }
                      name={`phones[${index}].phoneTypeID`}
                      control={control}
                      defaultValue={1}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={7}>
                  <TextFieldWithValidation
                    errors={errors}
                    label={`Phone ${index}`}
                    name={`phones[${index}].phoneNo`}
                    inputRef={register({
                      required: 'Enter Phone',
                      pattern: {
                        value: /^\+?\d+$/,
                        message: 'Please use + and digits only',
                      },
                    })}
                    error={
                      typeof errors.phones !== 'undefined' &&
                      typeof errors.phones[index] !== 'undefined'
                    }
                  />
                  <FormHelperText
                    hidden={
                      typeof errors.phones !== 'undefined' &&
                      typeof errors.phones[index] !== 'undefined'
                    }
                  >
                    Use numbers only
                  </FormHelperText>
                </Grid>
              </Grid>
            </Card>
          )}
        />
      </Paper>

      <Paper className={classes.paper}>
        <Typography variant="subtitle1">
          <b>Addresses</b>
        </Typography>
        <FieldArraySection
          {...addressesFeildArray}
          render={(item, index) => (
            <Card className={classes.card} key={item.id}>
              <Grid container spacing={2} sm>
                <Grid item xs={5}>
                  <FormControl fullWidth>
                    <InputLabel id="addressType-label" required>
                      Address Type
                    </InputLabel>
                    <Controller
                      as={
                        <Select
                          labelId="addressType-label"
                          placeholder="Address Type"
                          required
                        >
                          <MenuItem value="" disabled={true}>
                            Select...
                          </MenuItem>
                          {params?.AddressType?.map((type, index) => (
                            <MenuItem key={type.id} value={type.id}>
                              {type.addressTyp}
                            </MenuItem>
                          ))}
                        </Select>
                      }
                      name={`addresses[${index}].addrTypeID`}
                      control={control}
                      defaultValue={1}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    multiline
                    fullWidth
                    rows="3"
                    rowsMax="5"
                    variant="filled"
                    label={`Address ${index}`}
                    name={`addresses[${index}].addr`}
                    inputRef={register()}
                  />
                </Grid>
              </Grid>
            </Card>
          )}
        />
      </Paper>

      {/* <Paper className={classes.paper}>
        <Typography variant="subtitle1">
          <b>Groups</b>
        </Typography>
        <SelectGroupsSubform
          selected={selectedGroups}
          setSelected={setSelectedGroups}
          allGroups={prevGroups}
        />
      </Paper> */}
    </DialogForm>
  );
};

export default CreateNewCustomerDialog;
