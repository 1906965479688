import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import {
  createStyles,
  Theme,
  useTheme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import React, { ReactNode, useContext } from 'react';
import { ReactComponent as Logo } from 'src/logo.svg';
import { LayoutContext } from './LayoutContext';

const drawerWidth = 240;
const systemName = 'PT Liquidity';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    // necessary for content to be below app bar
    appBarOffset: theme.mixins.toolbar,
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      padding: theme.spacing(0, 1),
      position: 'fixed',
      width: drawerWidth,
      top: 0,
      backgroundColor: theme.palette.background.default,
      zIndex: theme.zIndex.appBar,
      ...theme.mixins.toolbar,
    },

    logo: {
      padding: theme.spacing(1),
      height: 60,
      width: 'auto',
    },
  });

const NavBar = ({
  classes,
  renderAccount,
  renderMenu,
}: {
  renderAccount: ReactNode;
  renderMenu: ReactNode;
} & WithStyles<typeof styles>) => {
  const theme = useTheme();
  const { state, actions } = useContext(LayoutContext);

  const renderHeader = (
    <Grid
      container
      className={classes.drawerHeader}
      direction="row"
      justify="space-between"
      alignItems="center"
    >
      <Grid item>
        <Logo className={classes.logo} title={systemName} />
      </Grid>
      <Grid item>
        <IconButton onClick={actions.hideMainMenu}>
          {theme.direction === 'ltr' ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </Grid>
    </Grid>
  );

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={state.mainMenuOpened}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      {renderHeader}
      <div className={classes.appBarOffset} />
      {renderAccount}
      <Divider />
      <nav>{renderMenu}</nav>
    </Drawer>
  );
};

export default withStyles(styles)(NavBar);
