import React, { useState } from 'react';
import { Button, Grid, TextField } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import TokenBalance from '../TokenBalance';

const CheckTokenBalanceForm = () => {
  const { register, handleSubmit } = useForm();
  const [account, setAccount] = useState(null);

  const onSubmit = async (data) => {
    setAccount(data.address);
  };

  return (
    <Grid container>
      {account && (
        <Grid item xs={12}>
          <TokenBalance address={account} />
        </Grid>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid item xs={12}>
          <TextField
            required
            inputRef={register}
            id="address"
            label="Address"
            name="address"
            helperText="Enter the account address to check balance"
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" color="primary" variant="contained">
            Check Balance
          </Button>
        </Grid>
      </form>
    </Grid>
  );
};

export default CheckTokenBalanceForm;
