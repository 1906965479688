import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Spinner } from 'reactstrap';
import { Col, Row } from 'reactstrap';
import moment from 'moment';

import { Summary } from '../components/Summary';
import { OrderBook } from '../components/OrderBook';
import { PlaceOrder } from '../components/PlaceOrder';
import { TradeHistory } from '../components/TradeHistory';
import { TradeBook } from '../components/TradeBook';
import { OrderHistory } from '../components/OrderHistory';
import { Positions } from '../components/Positions';
import { numberWithCommas } from '../components/common';

import DepthChart from '../components/DepthChart';
import { groupOrders } from '../helpers';

import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

import { MarketHubServiceClient } from './markethubpb/markethub_grpc_web_pb';
import { TickerRequest } from './markethubpb/markethub_pb';
import BrokerOMSService from '../../../ApiService/BrokerOMSService';
import BrokerView from 'src/Components/BrokerView';

import MarketDataService from '../../../ApiService/MarketDataService';

const exchangeGatewayUrl = window['config'].apiBaseUrl.exchange;
const client = new MarketHubServiceClient(exchangeGatewayUrl);

export const StockView2 = ({ auth, timestamp, path, account, acctPos }) => {
  const { ticker } = useParams();
  const [stock, setStock] = useState(null);

  const initMarketDataFields = () => {
    return {
      securityKey: ticker,
      bids: [],
      asks: [],
      orders: [],
      last: 0,
      high: 0,
      low: 0,
      open: 0,
      close: 0,
      turnover: 0,
      volume: 0,
    };
  };

  const refreshMarketData = () => {
    console.info('Refreshing market data');
    let req = {
      accountNum: account.account_no,
      secKey: ticker,
    };
    MarketDataService.getMarketData(req)
      .then((res) => {
        console.info('market data callback: ' + res.data);
        const data = res && res.data;
        if (data) {
          setStock({
            ...data,
            orders: [],
          });
        } else {
          console.warn('No market data received for ' + ticker);
          setStock(initMarketDataFields());
        }
      })
      .catch((err) => {
        console.error('Error in getting market data:' + err);
        setStock(initMarketDataFields());
      });
  };

  useEffect(() => {
    let interval_id = 0;
    if (account && ticker) {
      console.info('getting market data of ' + ticker);
      refreshMarketData();
      interval_id = setInterval(refreshMarketData, 5000);
    }

    return () => {
      clearInterval(interval_id);
    };
  }, [account, ticker]);

  const [positions, setPositions] = useState([]);
  useEffect(() => {
    console.info('effect on asset positions..' + account + ', ' + stock);
    if (account && stock) {
      const req = {
        accountNo: account && account.account_no,
        secKey: stock && stock.securityKey,
      };
      BrokerOMSService.getAssetPositions(req)
        .then((results) => {
          setPositions(results.data);
        })
        .catch((err) =>
          console.error('Error in getting asset position: ' + err)
        );
    }
  }, [account, stock]);

  const bidColor = 'cyan';

  const [activeTab, setActiveTab] = useState('1');
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  // Order book params
  const precision = 2;
  const depth = 8;
  const precisionChart = 2;
  const depthChart = 50;

  const [selectedPrice, setSelectedPrice] = useState();
  const handlePriceClick = (price) => {
    setSelectedPrice({ price });
  };

  if (!stock) {
    return (
      <div style={{ textAlign: 'center' }} className="mt-5">
        <div>Loading ...</div>
        <Spinner className="mt-3" />
      </div>
    );
  } else {
    const orderBook = groupOrders(
      stock.lastPrice ? stock.lastPrice : 0,
      [...stock.bids, ...stock.asks],
      precisionChart
    ).reverse();

    console.log('orderBook', orderBook);

    console.info('All Position holdings: ' + positions);
    const pos =
      positions.length > 0
        ? positions.find((x) => x.securityKey === stock.securityKey)
        : null;

    console.info('Position : ' + pos);
    const renderPosition = (
      <>
        <h4>Purchasing power</h4>
        <h3>{numberWithCommas(acctPos ? acctPos.buyingPower : 0, 2)}</h3>
        <hr />
        <h4>Holding Shares</h4>
        <h3>{pos ? numberWithCommas(pos.netQuantity) : 0}</h3>
        <hr />
      </>
    );

    const renderChart = (
      <DepthChart
        height={300}
        price={stock.last ? stock.last : 0}
        data={orderBook}
        precision={precisionChart}
        depth={depthChart}
        axisColor="white"
        bidColor={bidColor}
      />
    );

    const tabs = [
      { tab: '1', label: 'Orders' },
      { tab: '2', label: 'Trades' },
      { tab: '3', label: 'Positions' },
    ];

    const renderBottom = (
      <>
        <Nav tabs>
          {tabs.map((x) => (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === x.tab })}
                onClick={() => {
                  toggle(x.tab);
                }}
              >
                {x.label}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <OrderHistory
              account_no={account && account.account_no}
              path={path}
              stock={stock}
              orderHistory={[]}
              bidColor={bidColor}
              all={false}
            />
          </TabPane>
          <TabPane tabId="2">
            <TradeBook
              account_no={account && account.account_no}
              path={path}
              stock={stock}
            />
          </TabPane>
          <TabPane tabId="3">
            <Positions
              account_no={account && account.account_no}
              path={path}
              positions={positions}
            ></Positions>
          </TabPane>
        </TabContent>
      </>
    );

    return (
      <Card body className="stock-view">
        <div className="text-right">
          Last updated on {moment(timestamp).format('llll')}
        </div>
        <Row>
          <Col>
            <Summary stock={stock} />
          </Col>
        </Row>
        <Row>
          <Col>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col sm="9">
            <Row>
              <Col sm="3">
                <div>
                  <h3>Trade History</h3>
                </div>
                <TradeHistory stock={stock} maxHeight={300} />
              </Col>
              <Col sm="9">
                <OrderBook
                  stock={stock}
                  precision={precision}
                  depth={depth}
                  bidColor={bidColor}
                  onPriceClick={handlePriceClick}
                />
              </Col>
            </Row>
            <Row>
              <Col>{renderBottom}</Col>
            </Row>
          </Col>
          <Col sm="3">
            {renderPosition}
            <PlaceOrder
              account_no={account && account.account_no}
              stock={stock}
              auth={auth}
              xs="12"
              selectedPrice={selectedPrice}
            />
          </Col>
        </Row>
      </Card>
    );
  }
};
