import axios from 'axios';

const API = axios.create({
  baseURL: 'https://api.binance.com/api/v3/',
});

const ApiService = {
  get24hr(symbol) {
    return API.get(`ticker/24hr?symbol=` + symbol);
  },
};

export default ApiService;
