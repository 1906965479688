import React from 'react';
import { InputBase, InputLabel } from '@material-ui/core';

function UnEditableSelector({ text }) {
  return (
    <div>
      <InputLabel style={{ visibility: 'hidden', height: 0 }}>
        {text}
      </InputLabel>
      <InputBase
        fullWidth
        value={text}
        disabled
        style={{
          color: 'black',
          paddingRight: 28.5,
          height: 32,
        }}
      >
        {text}
      </InputBase>
    </div>
  );
}

export default UnEditableSelector;
