import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import ChangePasswordForm from 'src/Components/CurrentUserManagement/ChangePasswordForm';

const keyLabels = {
  account_no: 'Account No',
  account_name: 'Account Name',
  balance: 'Balance',
  remarks: 'Remarks',
  sub: 'Sub',
  status: 'Status',
  address: 'Address',
};

const useStyles = makeStyles((theme) => ({
  label: {
    paddingLeft: theme.spacing(4),
  },
  value: {
    paddingTop: theme.spacing(2),
  },
}));

const AccountDetail = ({ account }) => {
  const classes = useStyles();

  return (
    <>
      <h4>Account Details</h4>
      {account &&
        Object.keys(account).map((key, index) => (
          <Grid key={index} container>
            <Grid item xs={3} className={classes.label}>
              <h4>{keyLabels[key] || key}:</h4>
            </Grid>
            <Grid item xs={9} className={classes.value}>
              {account[key]}
            </Grid>
          </Grid>
        ))}
      <ChangePasswordForm />
    </>
  );
};

export default AccountDetail;
