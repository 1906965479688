import React from 'react';
import InfoGrid from '../DetailedInfo/InfoGrid';
import moment from 'moment';
import DescriptionIcon from '@material-ui/icons/Description';
import { Button } from '@material-ui/core';

function DetailInfo({ data, update }) {
  const fieldDefs = [
    {
      id: 'id',
      numeric: false,
      disablePadding: false,
      label: 'SecKey',
      valueOf: (bond) => bond.key,
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: false,
      label: 'Description',
      valueOf: (bond) => bond.description,
    },
    {
      id: 'lotSize',
      numeric: false,
      disablePadding: false,
      label: 'Lot Size',
      valueOf: (bond) => bond.lotSize,
    },
    {
      id: 'brokerId',
      numeric: false,
      disablePadding: false,
      label: 'Broker',
      valueOf: (bond) => bond.brokerId,
    },
    {
      id: 'issuer',
      numeric: false,
      disablePadding: false,
      label: 'Issuer',
      valueOf: (bond) => bond.issuer,
    },
    {
      id: 'coupon',
      numeric: false,
      disablePadding: false,
      label: 'Coupon',
      valueOf: (bond) => bond.coupon.toFixed(2) + '%',
    },
    {
      id: 'fx',
      numeric: false,
      disablePadding: false,
      label: 'FX Rate',
      valueOf: (bond) => bond.fxRate.toFixed(2),
    },
    {
      id: 'facevalue',
      numeric: false,
      disablePadding: false,
      label: 'Face Value',
      valueOf: (bond) => bond.faceValue + ' (' + bond.faceValueCcy + ')',
    },
    {
      id: 'ccy',
      numeric: false,
      disablePadding: false,
      label: 'Contract Currency',
      valueOf: (bond) => bond.ccy,
    },
    {
      id: 'issueSize',
      numeric: false,
      disablePadding: false,
      label: 'Issue Size',
      valueOf: (bond) => bond.issueSize,
    },
    {
      id: 'riskRating',
      numeric: false,
      disablePadding: false,
      label: 'Risk Rating',
      valueOf: (bond) => bond.riskRating,
    },
    {
      id: 'maturity',
      numeric: false,
      disablePadding: false,
      label: 'Maturity',
      valueOf: (bond) => bond.maturity,
      displayValueOf: (bond) => moment(bond.maturity).format('ll'),
    },
    {
      id: 'listing',
      numeric: false,
      disablePadding: false,
      label: 'Listing Date',
      valueOf: (bond) => bond.listingDate,
      displayValueOf: (bond) => moment(bond.listingDate).format('ll'),
    },
    {
      id: 'drawdown',
      numeric: false,
      disablePadding: false,
      label: 'Drawdown Date',
      valueOf: (bond) => bond.drawdownDate,
      displayValueOf: (bond) => moment(bond.drawdownDate).format('ll'),
    },
    {
      id: 'coupons',
      numeric: false,
      disablePadding: false,
      label: 'Coupon dates',
      displayValueOf: (bond) => renderCoupons(bond.couponDates),
    },
    {
      id: 'docs',
      numeric: false,
      disablePadding: false,
      label: 'Documents',
      displayValueOf: (bond) => renderDocuments(bond.productDocuments),
    },
  ];

  const renderCoupons = (coupons) => {
    return (
      <>
        {coupons.map((x) => (
          <h3 key={x.id}>{moment(x.date).format('ll')}</h3>
        ))}
      </>
    );
  };

  const renderDocuments = (docs) => {
    return (
      <>
        {docs.map((x) => (
          <p key={x.docUrl}>
            <h3>
              {x.docName}
              <Button href={x.docUrl}>
                <DescriptionIcon />
              </Button>
            </h3>
            <i>{x.docDesc}</i>
          </p>
        ))}
      </>
    );
  };
  return <InfoGrid data={data} fieldDefs={fieldDefs} update={update} />;
}

export default DetailInfo;
