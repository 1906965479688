import React from 'react';
import { Grid, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
  item: {
    margin: theme.spacing(0, 0, 1, 0),
  },
}));

function FieldArraySection({ fields, append, remove, render }) {
  const classes = useStyles();
  return (
    <div>
      {fields.map((item, index) => (
        <Grid
          key={item.id}
          className={classes.item}
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          container
        >
          <Grid item>
            <IconButton
              size="small"
              variant="contained"
              color="secondary"
              onClick={() => remove(index)}
            >
              <ClearIcon />
            </IconButton>
          </Grid>
          <Grid item xs>
            {render(item, index)}
          </Grid>
        </Grid>
      ))}
      <IconButton
        size="small"
        variant="contained"
        color="primary"
        onClick={() => append()}
      >
        <AddIcon />
      </IconButton>
    </div>
  );
}

export default FieldArraySection;
