import React from 'react';

import DialogForm from './DialogForm';
import Typography from '@material-ui/core/Typography';

function ConfirmationDialog({
  closeDialog,
  handleConfirmed,
  title,
  open,
  message,
}) {
  return (
    <DialogForm
      open={open}
      onClose={() => {
        closeDialog();
      }}
      onSubmit={() => {
        handleConfirmed();
        closeDialog();
      }}
      title={title}
      maxWidth="xs"
    >
      <Typography>{message}</Typography>
    </DialogForm>
  );
}

export default ConfirmationDialog;
