import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  List,
  ListItem,
  MenuItem,
  Select,
  Typography,
  TextField,
  makeStyles,
  Tooltip,
  Switch,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import ApiService from '../ApiService/ApiService';
import OnBoardService from '../ApiService/OnBoardService';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import RefreshIcon from '@material-ui/icons/Refresh';
import VisibilityIcon from '@material-ui/icons/Visibility';

import moment from 'moment';
import EnhancedTable from './EnhancedTableView/EnhancedTable';
import CashoutDialog from './CashoutDialog';
import CashMovements from './WineMarket/Accounts/CashMovements';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const AccountsManagement = ({
  onClick = () => {},
  canOpenAccount = false,
  reload = 0,
  roles,
}) => {
  const classes = useStyles();
  const [accountName, setAccountName] = useState('');
  const [accounts, setAccounts] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [selectedSub, setSelectedSub] = useState(null);
  const [update, setUpdate] = useState(false);
  const [companyCode, setCompanyCode] = useState(null);
  const [accountType, setAccountType] = useState(null); // INVESTOR/ISSUER
  const [remarks, setRemarks] = useState('');

  const [allCashouts, setAllCashouts] = useState([]);

  useEffect(() => {
    ApiService.getCustomers(setCustomers);
    console.log('fetch');
  }, [update, reload]);

  useEffect(() => {
    ApiService.getAllCashouts().then((res) => {
      setAllCashouts(res.data);
    });
  }, [update]);

  useEffect(() => {
    if (selectedSub != null) {
      ApiService.getAccounts(setAccounts);
    }
  }, [selectedSub, update]);

  const refresh = () => {
    setUpdate(!update);
  };
  const customerAccounts = accounts.filter((x) => x.sub === selectedSub);

  const _accountTypes = ['Investor', 'Issuer'];
  const renderOpenAccount = selectedSub && (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.heading}>Open a New Account</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid item xs={12}>
            <Select
              style={{ minWidth: 300 }}
              id="accountType"
              value={accountType}
              onChange={(e) => {
                setAccountType(e.target.value);
              }}
              displayEmpty={true}
              renderValue={(x) => x || 'Select an account type...'}
            >
              {_accountTypes.map((x) => (
                <MenuItem value={x} key={x}>
                  {x}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Company Code"
              type="text"
              onChange={(e) => setCompanyCode(e.target.value)}
            />
            <Tooltip
              style={{ verticalAlign: 'bottom' }}
              placement="right"
              title="A 3 characters company code. Use IUA for individual investors"
            >
              <InfoIcon />
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Account Name"
              type="text"
              onChange={(e) => setAccountName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Remarks"
              type="text"
              onChange={(e) => setRemarks(e.target.value)}
            />
            <Typography style={{ verticalAlign: 'bottom' }}>
              (optional)
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                selectedSub
                  ? ApiService.openAccount({
                      sub: selectedSub,
                      company_code: companyCode,
                      account_name: accountName,
                      application_type: accountType.toUpperCase(),
                      remarks: remarks,
                    }).then(() => {
                      toast(`Account opened - sub ${selectedSub}`);
                      refresh();
                    })
                  : toast('No customer selected');
              }}
              disabled={!selectedSub}
            >
              Open Account
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );

  const renderCustomerAccounts = customerAccounts.length > 0 && (
    <Accordion defaultExpanded={true}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.heading}>Customer Accounts</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List>
          {customerAccounts.map((acc, i) => (
            <ListItem key={acc.account_no}>
              <Grid container>
                <Grid item xs={12}>
                  <span
                    style={{ cursor: 'pointer', fontWeight: 'bold' }}
                    onClick={() => onClick(acc.account_no)}
                  >
                    <AccountBalanceWalletIcon />{' '}
                    {` ${acc.account_no} - ${acc.account_name}`}
                  </span>
                </Grid>
                <Grid item xs={12}>
                  Blockchain Address:{' '}
                  <i>{acc.address && acc.address.toLowerCase()}</i>
                </Grid>
                <Grid item xs={12}>
                  Remarks: <i>{acc.remarks}</i>
                </Grid>
                <Grid item xs={12}>
                  Account Balance: {acc.balance.toLocaleString()}
                </Grid>
                <Grid item xs={12}>
                  <CashMovements
                    classes={classes}
                    account={acc}
                    roles={roles}
                  />
                </Grid>
                <Grid item xs={12}>
                  <hr />
                </Grid>
              </Grid>
            </ListItem>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );

  const columns = [
    {
      id: 'account_no',
      numeric: false,
      disablePadding: false,
      label: 'Account Number',
      valueOf: (x) => x.accountNumber,
    },
    {
      id: 'username',
      numeric: false,
      disablePadding: false,
      label: 'User Name',
      valueOf: (x) => {
        const customer = customers.find((c) => c.sub === x.sub);
        return customer && customer.userName;
      },
    },
    {
      id: 'firstname',
      numeric: false,
      disablePadding: false,
      label: 'First Name',
      valueOf: (x) => {
        const customer = customers.find((c) => c.sub === x.sub);
        return customer && customer.firstName;
      },
    },
    {
      id: 'lastname',
      numeric: false,
      disablePadding: false,
      label: 'Last Name',
      valueOf: (x) => {
        const customer = customers.find((c) => c.sub === x.sub);
        return customer && customer.lastName;
      },
    },
    {
      id: 'amount',
      numeric: true,
      disablePadding: false,
      label: 'Amount',
      valueOf: (x) => x.amount,
    },
    {
      id: 'status',
      numeric: true,
      disablePadding: false,
      label: 'Status',
      valueOf: (x) => x.status,
    },
    {
      id: 'date',
      numeric: true,
      disablePadding: false,
      label: 'Requested Date',
      valueOf: (x) => x.createdDateTime,
      displayValueOf: (x) => moment(x.createdDateTime).format('ll'),
    },
    {
      id: 'pay',
      numeric: true,
      disablePadding: false,
      label: 'Pay',
      valueOf: (x) => '',
      displayValueOf: (x) => {
        return (
          x.status === 'Pending' && (
            <Button variant="outlined" color="primary" onClick={() => onPay(x)}>
              Pay
            </Button>
          )
        );
      },
    },
  ];

  const onPay = (x) => {
    console.log('onPay', x);
    // deposit to fiat account and deduct from broker balance
    ApiService.payCashout(x.id).then((res) => {
      refresh();
    });
  };

  const operations = [
    {
      label: 'refresh',
      icon: <RefreshIcon />,
      handleClick: refresh,
    },
  ];

  const [pay, setPay] = useState(null);
  const actions = [
    {
      label: 'Details',
      icon: <VisibilityIcon />,
      handleClick: (x) => {
        setPay(x);
      },
      show: 'cell',
    },
  ];

  const [filterEnabled, setFilterEnabled] = useState(false);
  const handleChange = (e) => {
    setFilterEnabled(e.target.checked);
  };

  const filteredCashouts = filterEnabled
    ? allCashouts.filter((x) => x.status === 'Pending')
    : allCashouts;

  const [selected, setSelected] = useState([]);
  const renderAllCashouts = allCashouts.length > 0 && (
    <>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            Customer Cashout Requests
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={12}>
              <div style={{ textAlign: 'right' }}>
                All
                <Switch
                  checked={filterEnabled}
                  onChange={handleChange}
                  color="primary"
                  value="checked"
                  size="small"
                  inputProps={{ 'aria-label': 'checkbox' }}
                />
                Open
              </div>
            </Grid>
            <Grid item xs={12}>
              <EnhancedTable
                classes={classes}
                columnDefs={columns}
                operationDefs={operations}
                dataList={filteredCashouts}
                selected={selected}
                setSelected={setSelected}
                actionDefs={actions}
                title="Cashout Requests"
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      {pay && (
        <CashoutDialog
          customers={customers}
          cashout={pay}
          open={pay != null}
          closeDialog={() => setPay(null)}
          onPay={onPay}
        />
      )}
    </>
  );

  const enableDisableCustomer = selectedSub && (
    <>
      <Button
        onClick={async () =>
          await OnBoardService.enableCustomer(
            customers.find((c) => c.sub === selectedSub)?.customerID
          )
        }
        color="primary"
      >
        Enable Customer
      </Button>
      <Button
        onClick={async () =>
          await OnBoardService.disableCustomer(
            customers.find((c) => c.sub === selectedSub)?.customerID
          )
        }
        color="secondary"
      >
        Disable Customer
      </Button>
    </>
  );

  return (
    <>
      <h2>Manage Customer Accounts</h2>
      <Select
        style={{ minWidth: 300 }}
        labelId="select-label"
        id="select"
        value={selectedSub}
        onChange={(e) => {
          setSelectedSub(e.target.value);
        }}
      >
        <MenuItem value={null}>
          <em>Select a Customer...</em>
        </MenuItem>
        {customers &&
          customers.map((c, i) => {
            return (
              <MenuItem value={c.sub} key={i}>
                {c.userName}
              </MenuItem>
            );
          })}
      </Select>
      {selectedSub}
      {canOpenAccount && renderOpenAccount}
      {renderCustomerAccounts}
      {renderAllCashouts}
      {enableDisableCustomer}
    </>
  );
};

export default AccountsManagement;
