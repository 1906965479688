import React, { createContext, useReducer, Dispatch } from 'react';

export interface LayoutContextActions {
  showMainMenu: () => void;
  hideMainMenu: () => void;
}

export interface LayoutContextState {
  mainMenuOpened: boolean;
}

export interface LayoutContextProps {
  state: LayoutContextState;
  actions: LayoutContextActions;
}

export interface ActionProps {
  type: string;
}

const initialState: LayoutContextState = {
  mainMenuOpened: true,
};

const actions = {
  showMainMenu: { type: 'SHOW_MAIN_MENU' },
  hideMainMenu: { type: 'HIDE_MAIN_MENU' },
};

function actionsCreator(dispatch: Dispatch<ActionProps>): LayoutContextActions {
  return {
    showMainMenu() {
      dispatch(actions.showMainMenu);
    },
    hideMainMenu() {
      dispatch(actions.hideMainMenu);
    },
  };
}

export const LayoutContext = createContext<LayoutContextProps>(null);

export const LayoutContextProvider = ({ children, ...otherProps }) => {
  const [state, dispatch] = useReducer<
    (state: LayoutContextState, action: ActionProps) => LayoutContextState
  >((state, action) => {
    switch (action.type) {
      case actions.showMainMenu.type:
        return {
          ...state,
          mainMenuOpened: true,
        };
      case actions.hideMainMenu.type:
        return {
          ...state,
          mainMenuOpened: false,
        };
      default:
        throw new Error('Unknown Action');
    }
  }, initialState);

  return (
    <LayoutContext.Provider
      value={{
        state,
        actions: actionsCreator(dispatch),
      }}
      {...otherProps}
    >
      {children}
    </LayoutContext.Provider>
  );
};
