import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { fade, makeStyles, useTheme } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import EnhancedTable from '../EnhancedTableView/EnhancedTable';
import ApiService from '../../ApiService/ApiService';
import clsx from 'clsx';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { measureTextWidth } from './GraphUtil';
import moment from 'moment';
import TokenBalance from '../TokenBalance';
import MarketCoreService from '../../ApiService/MarketCoreService';
import MarketDataService from '../../ApiService/MarketDataService';
import BrokerOMSService from '../../ApiService/BrokerOMSService';
import { numberWithCommas } from '../exchange/components/common';

import { useCurrencyContext } from '../../Contexts/CurrencyContext';
import { Positions } from '../exchange/components/Positions';

const numberFormatter = new Intl.NumberFormat('en-US', {
  // style: 'currency',
  // currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const useSummaryStyles = makeStyles((theme) => ({
  cell: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  flag: {
    alignItems: 'center',
    borderRadius: 2,
    display: 'inline-flex',
    flexGrow: 0,
    fontSize: '0.75rem',
    height: 20,
    marginLeft: theme.spacing(1),
    minWidth: 20,
    padding: theme.spacing(0.5, 1),
  },
  raise: {
    color: green[500],
    backgroundColor: fade(green[500], 0.08),
  },
  drop: {
    color: red[500],
    backgroundColor: fade(red[500], 0.08),
  },
}));
const Summary = ({ accounts, tokenPositionStat, acctPos }) => {
  const context = useCurrencyContext();
  const classes = useSummaryStyles();

  const account = accounts.length ? accounts[0] : {};
  const totalDelta = (
    ((tokenPositionStat.totalValue - tokenPositionStat.totalCost) /
      tokenPositionStat.totalCost) *
    100
  ).toFixed(0);

  return (
    <Paper elevation={1}>
      <Grid container alignContent="center" alignItem="center">
        <Grid item xs={6} className={classes.cell}>
          <Typography variant="overline" color="textSecondary" gutterBottom>
            Purchasing Power
          </Typography>
          <Typography variant="h3" color="textPrimary">
            <div className={classes.flexContainer}>
              {numberFormatter.format(acctPos ? acctPos.buyingPower : 0)}
              {/* <div className={clsx(classes.flag, classes.drop)}>-100%</div> */}
            </div>
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.cell}>
          <Typography variant="overline" color="textSecondary" gutterBottom>
            Portfolio Value
          </Typography>
          {/* <Typography variant="h3" color="textPrimary">
            {context.convert(12345.67)}
          </Typography> */}
          <Typography variant="h3" color="textPrimary">
            <div className={classes.flexContainer}>
              {tokenPositionStat.totalValue !== undefined
                ? numberFormatter.format(tokenPositionStat.totalValue)
                : 'Loading...'}
              <div className={clsx(classes.flag, classes.raise)}>
                {totalDelta !== 0 && (totalDelta > 0 ? '+' : '-')}
                {totalDelta}%
              </div>
            </div>
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

const useChartStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}));
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = (theme) => ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  name,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.25;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  const text = `${name} ${(percent * 100).toFixed(0)}%`;
  const textWidth = measureTextWidth(text, 14, 'Arial', 700);
  const rectWidth = textWidth + 10;
  const rectPadding = 5;

  return (
    <>
      <rect
        x={x > cx ? x - rectPadding : x - rectWidth + rectPadding}
        y={y - 10}
        width={rectWidth}
        height="20"
        fill={fade(theme.palette.text.secondary, 0.5)}
      ></rect>
      <text
        x={x}
        y={y}
        fill={theme.palette.text.primary}
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
        fontFamily="Arial"
        fontSize={14}
        fontWeight={700}
      >
        {text}
      </text>
    </>
  );
};
const PositionPieChart = ({ data }) => {
  const classes = useChartStyles();
  const theme = useTheme();

  return (
    <Paper elevation={1} className={classes.root}>
      <ResponsiveContainer minHeight={200}>
        <PieChart>
          <Pie
            data={data}
            labelLine={false}
            label={renderCustomizedLabel(theme)}
            innerRadius={30}
            isAnimationActive={false}
          >
            {data.map((_, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </Paper>
  );
};

function getActiveTokenPositions(accounts, tokenPositions) {
  return tokenPositions;
}

const usePositionsStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  label: {
    paddingLeft: theme.spacing(4),
  },
  value: {
    paddingTop: theme.spacing(2),
  },
  up: {
    color: 'lime',
  },
  down: {
    color: 'red',
  },
}));

const TokenPositions = ({ accounts, positionsList, title }) => {
  const classes = usePositionsStyles();

  const columnDefs = [
    {
      id: 'ticker',
      numeric: false,
      disablePadding: false,
      label: 'Ticker',
      valueOf: (wine) => wine.sec_key,
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
      valueOf: (wine) => wine.desc,
    },
    {
      id: 'price',
      numeric: true,
      disablePadding: false,
      label: 'Price',
      valueOf: (wine) => wine.price && numberFormatter.format(wine.price),
    },
    {
      id: 'qty',
      numeric: true,
      disablePadding: false,
      label: 'Qty',
      valueOf: (wine) => wine.qty && wine.qty.toLocaleString(),
    },
    {
      id: 'spot',
      numeric: true,
      disablePadding: false,
      label: 'Spot',
      valueOf: (wine) => wine.spot && numberFormatter.format(wine.spot),
    },
    {
      id: 'value',
      numeric: true,
      disablePadding: false,
      label: 'Value',
      valueOf: (wine) =>
        wine.spot && numberFormatter.format(wine.spot * wine.qty),
    },
    {
      id: 'pnl',
      numeric: true,
      disablePadding: false,
      label: 'P&L',
      valueOf: (wine) => wine.unrealized,
      displayValueOf: (wine) =>
        wine.unrealized && (
          <div className={wine.percentage < 0 ? classes.down : classes.up}>
            {wine.unrealized.toLocaleString()} (
            {(Math.round(wine.percentage * 100) / 100).toFixed(2)}%)
          </div>
        ),
    },
    {
      id: 'update_date',
      numeric: false,
      disablePadding: false,
      label: 'Update Date',
      valueOf: (wine) => wine.update_date,
      displayValueOf: (wine) => moment(wine.update_date).format('ll'),
    },
  ];

  const positions = getActiveTokenPositions(accounts, positionsList);

  return <Positions positions={positions} title={title} />;
};

const CashPositions = ({ data, title }) => {
  const classes = usePositionsStyles();
  let precision = 2;

  const columnDefs = [
    {
      id: 'ccy',
      numeric: false,
      disablePadding: false,
      label: 'Currency',
      valueOf: (x) => x.ccy,
    },
    {
      id: 'balance',
      numeric: false,
      disablePadding: false,
      label: 'Balance',
      valueOf: (x) => x.balance,
      displayValueOf: (x) => numberWithCommas(x.balance, precision),
    },
    {
      id: 'deposit',
      numeric: false,
      disablePadding: false,
      label: 'Deposit',
      valueOf: (x) => x.deposit,
      displayValueOf: (x) => numberWithCommas(x.deposit, precision),
    },
    {
      id: 'withdraw',
      numeric: false,
      disablePadding: false,
      label: 'Withdraw',
      valueOf: (x) => x.withdraw,
      displayValueOf: (x) => numberWithCommas(x.withdraw, precision),
    },
  ];

  return (
    <EnhancedTable
      classes={classes}
      columnDefs={columnDefs}
      operationDefs={[]}
      dataList={data}
      selected={[]}
      setSelected={() => {}}
      actionDefs={[]}
      valueOfprimaryKey={(x) => x.ccy}
      title={title}
    />
  );
};

const usePositionStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
const PortfolioView = ({ roles }) => {
  const classes = usePositionStyles();

  const [accounts, setAccounts] = useState([]);
  const [tokenPositionsList, setTokenPositionsList] = useState([]);
  const [acctPos, setAcctPos] = useState(null);
  const [tokenPositionStat, setTokenPositionStat] = useState({ data: [], tokenPositionList: [] });

  const cashStat = useMemo(() => {
    if (acctPos) {
      return {
        data: [
          {
            ccy: acctPos.currency,
            name: acctPos.currency,
            balance: acctPos.accountBalance,
            deposit: acctPos.depositFund,
            withdraw: acctPos.withdrawFund,
            value: acctPos.buyingPower,
          },
        ],
      };
    }

    return { data: [] };
  }, [acctPos]);

  useEffect(() => {
    ApiService.getCurrentAccounts(setAccounts);
    //ApiService.getCurrentPositions(setTokenPositionsList);
  }, []);

  const refreshAccountPos = () => {
    let account = accounts.length ? accounts[0] : null;
    if (account) {
      BrokerOMSService.getAcctPositions({ accountNo: account.account_no })
        .then((res) => {
          if (res && res.data) {
            setAcctPos(res.data);
          }
        })
        .catch((err) =>
          console.error(
            'Failed to get account position: ' +
              account.account_no +
              ', error: ' +
              err
          )
        );

      BrokerOMSService.getAssetPositions({ accountNo: account.account_no })
        .then((results) => {
          setTokenPositionsList(results.data);
        })
        .catch((err) =>
          console.error(
            'Failed to get account position: ' +
              account.account_no +
              ', error: ' +
              err
          )
        );
    }
  };
  useEffect(() => {
    refreshAccountPos();
  }, [accounts]);

  const refreshTokenPositionStat = useCallback(
    async (inputAccounts, inputTokenPositionsList) => {
      let totalValue = 0;
      let totalCost = 0;
      const data = [];
      const promises = getActiveTokenPositions(
        inputAccounts,
        inputTokenPositionsList
      ).map((t) => {
        let req = {
          accountNum: inputAccounts[0].account_no,
          secKey: t.securityKey,
        };
        return MarketDataService.getMarketData(req)
          .then((res) => {
            const p = res && res.data;
            if (p) {
              const value = t.netQuantity * (p.last || p.close);
              const cost = t.netQuantity * t.netAveragePrice;
              totalValue += value;
              totalCost += cost;

              t.unrealized = value - (cost + (0.25 * cost / 100));

              data.push({
                name: t.securityKey,
                value,
              });
            }
          })
          .catch((err) => {
            console.error('Error in getting market data:' + err);
          });
      });

      await Promise.all(promises);

      const updatedTokenPositions = getActiveTokenPositions(
        inputAccounts,
        inputTokenPositionsList
      ).map((t) => {
          var found = data.filter((md) => md.name == t.securityKey);
          if (found && found.length > 0)
          {
            const value = found[0].value;
            const cost = t.netQuantity * t.netAveragePrice;

            t.unrealized = value - (cost + (0.25 * cost / 100));              
          }
          return t;
      });   
      
    //   if (updatedTokenPositions.length > 0)
    //   {
    //     setTokenPositionsList(updatedTokenPositions);
    //   }

      setTokenPositionStat({
        data,
        totalValue,
        totalCost,
        tokenPositionList: updatedTokenPositions
      });
    },
    []
  );
  useEffect(() => {
    if (accounts?.length > 0) {
      refreshTokenPositionStat(accounts, tokenPositionsList);
    }
  }, [accounts, tokenPositionsList]);

  const [broker, setBroker] = useState(null);
  useEffect(() => {
    MarketCoreService.getChainInfo().then((res) =>
      setBroker(res.data.contract.nodeAccount)
    );
  }, []);

  console.log('accounts', accounts?.length > 0 && accounts[0].address);
  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <Summary
          accounts={accounts}
          tokenPositionStat={tokenPositionStat}
          acctPos={acctPos}
        />
      </Grid>
      <Grid item xs={6}>
        <PositionPieChart data={cashStat.data} />
      </Grid>
      <Grid item xs={6}>
        <PositionPieChart data={tokenPositionStat.data} />
      </Grid>
      <Grid item xs={12}>
        <Paper elevation={1}>
          <CashPositions data={cashStat.data} title={'Cash Position'} />
        </Paper>
      </Grid>
      {roles?.includes('broker') && broker && (
        <Grid item xs={12}>
          <Paper elevation={1}>
            <TokenBalance address={broker} title={'PTC Tokens'} />
          </Paper>
        </Grid>
      )}
      {(roles?.includes('custodian') || roles?.includes('issuer')) &&
        accounts?.length > 0 && (
          <Grid item xs={12}>
            <Paper elevation={1}>
              <TokenBalance
                address={accounts[0].address}
                title={'PTC Tokens'}
              />
            </Paper>
          </Grid>
        )}
      <Grid item xs={12}>
        <Paper elevation={1}>
          <TokenPositions
            accounts={accounts}
            positionsList={tokenPositionStat.tokenPositionList}
            title={'Token Position'}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default PortfolioView;
