import React from 'react';

export const updown = (valueStr) => {
  if (valueStr.includes('-')) {
    return (
      <span style={{ color: 'red' }}>
        <i className="fas fa-sort-down" /> {valueStr}
      </span>
    );
  } else {
    return (
      <span style={{ color: 'limegreen' }}>
        <i className="fas fa-sort-up" /> {valueStr}
      </span>
    );
  }
};

export const numberWithCommas = (x, precision = 0) => {
  // return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
  return x.toLocaleString(undefined, {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });
};
