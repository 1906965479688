import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import WifiOffIcon from '@material-ui/icons/WifiOff';
import clsx from 'clsx';
import React, { FC, ReactNode, useContext } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { LayoutContext } from './LayoutContext';
import NavBar from './NavBar';
import CurrencySelector from 'src/Components/WineMarket/CurrencySelector';

const drawerWidth = 240;
const systemName = 'PT Liquidity';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    // necessary for content to be below app bar
    appBarOffset: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      padding: theme.spacing(0),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },

    hide: {
      display: 'none',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
  });

export interface LayoutProps extends WithStyles<typeof styles> {
  connected: boolean;
  renderAccount: ReactNode;
  renderMenu: ReactNode;
}

const Layout: FC<LayoutProps> = ({
  classes,
  children,
  connected,
  renderAccount,
  renderMenu,
}) => {
  const { state, actions } = useContext(LayoutContext);

  return (
    <Router>
      <div className={classes.root}>
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: state.mainMenuOpened,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open main menu"
              onClick={actions.showMainMenu}
              edge="start"
              className={clsx(
                classes.menuButton,
                state.mainMenuOpened && classes.hide
              )}
            >
              <MenuIcon />
            </IconButton>
            <Grid container justify="space-between">
              <Grid item>
                <Typography variant="h6" noWrap>
                  {systemName}
                </Typography>
              </Grid>
              <Grid item>
                {connected || <WifiOffIcon />}
                <div style={{ display: 'none' }}>
                  <CurrencySelector label="" />
                </div>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <NavBar renderAccount={renderAccount} renderMenu={renderMenu}></NavBar>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: state.mainMenuOpened,
          })}
        >
          <div className={classes.appBarOffset} />
          {children}
        </main>
      </div>
    </Router>
  );
};

export default withStyles(styles)(Layout);
