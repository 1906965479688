import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import MarketCoreService from '../ApiService/MarketCoreService';

import TokenBalance from './TokenBalance';
import TransactionsTable from './CustodianForms/TransactionsTable';

import AddCustodianForm from './AdminForms/AddCustodianForm';
import CheckTokenBalanceForm from './AdminForms/CheckTokenBalanceForm';
import HasCustodianRoleForm from './CustodianForms/HasCustodianRoleForm';
import RemoveCustodianForm from './AdminForms/RemoveCustodianForm';
import TransferEthForm from './AdminForms/TransferEthForm';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const AdminView = () => {
  const classes = useStyles();
  const [admin, setAdmin] = useState(null);

  useEffect(() => {
    MarketCoreService.getChainInfo().then((res) =>
      setAdmin(res.data.contract.nodeAccount)
    );
  }, []);

  const list = [
    { title: 'My Token Balance', component: <TokenBalance address={admin} /> },
    { title: 'Check Token Balance', component: <CheckTokenBalanceForm /> },
    {
      title: 'Token Transactions',
      component: <TransactionsTable address={admin} />,
    },
    { title: 'ETH Top Up', component: <TransferEthForm /> },
    { title: 'Has Custodian Role', component: <HasCustodianRoleForm /> },
    { title: 'Add Custodian', component: <AddCustodianForm /> },
    { title: 'Remove Custodian', component: <RemoveCustodianForm /> },
  ];

  return (
    <>
      {list.map((x) => (
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>{x.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>{x.component}</AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default AdminView;
