import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import GroupIcon from '@material-ui/icons/Group';
import MarketCoreService from '../ApiService/MarketCoreService';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    margin: theme.spacing(2),
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

const BlockchainView = () => {
  const classes = useStyles();
  const [holders, setHolders] = useState([]);
  const [tokenTypes, setTokenTypes] = useState(null);
  const [chainInfo, setChainInfo] = useState(null);

  useEffect(() => {
    MarketCoreService.getTokenTypes().then((res) => setTokenTypes(res.data));
  }, []);
  useEffect(() => {
    MarketCoreService.getChainInfo().then((res) => setChainInfo(res.data));
  }, []);
  useEffect(() => {
    MarketCoreService.getHolders().then((res) => setHolders(res.data));
  }, []);

  console.log('chainInfo', chainInfo);
  return tokenTypes == null ? (
    <CircularProgress />
  ) : (
    <>
      <h3>Block Chain Status</h3>
      {chainInfo && (
        <Card className={classes.root}>
          <CardContent>
            <Typography>
              {`Block height: #${chainInfo.chain.blockHeight}`}
            </Typography>
            <Typography>
              {`Peer count: ${chainInfo.chain.peerCount}`} <GroupIcon />
            </Typography>
            <Typography>{`Version: ${chainInfo.chain.version}`}</Typography>
            <Typography>
              {`Node Account Balance: ${chainInfo.chain.balance} Wei`}
            </Typography>
            {Object.entries(chainInfo.contract).map(([k, v]) => (
              <Typography key={k}>
                {k}: {v}
              </Typography>
            ))}
          </CardContent>
        </Card>
      )}
      <hr />
      <h3>Holders</h3>
      <Typography variant="body2" component="p">
        Total {holders.length} holders
      </Typography>
      <ul>
        {holders.length > 0 && holders.map((x) => {
          return <li key={x}>{x}</li>;
        })}
      </ul>
      <hr />
      <h3>Token Types</h3>
      <Typography variant="body2" component="p">
        Total {tokenTypes.length} types
      </Typography>
      {tokenTypes.map((x) => {
        return (
          <Card className={classes.root}>
            <CardContent>
              <Typography variant="h5" component="h2">
                {x.sec_key}
              </Typography>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                Token type: {x.token_type}
              </Typography>
              <Typography className={classes.pos} color="textSecondary">
                Block number: {x.block_number}
              </Typography>
              <Typography variant="body2" component="p">
                Broker balance: {x.balance}
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small">{x.transaction_hash}</Button>
            </CardActions>
          </Card>
        );
      })}
    </>
  );
};

export default BlockchainView;
