import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { TextField, Button } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ApiService from '../../ApiService/ApiService';
import { toast } from 'react-toastify';

const ChangePasswordForm = () => {
  const { register, errors, handleSubmit, watch } = useForm();
  const password = useRef({});
  password.current = watch('password', '');

  const onSubmit = (data) => {
    console.log(data);
    ApiService.resetPassword(data)
      .then((res) => {
        toast('Password updated');
        console.log('Password updated');
      })
      .catch((err) => {
        toast.error('Failed to updated password');
      });
  };

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2>Change Password</h2>
      {passwordShown ? (
        <VisibilityOffIcon
          onClick={togglePasswordVisiblity}
        ></VisibilityOffIcon>
      ) : (
        <VisibilityIcon onClick={togglePasswordVisiblity}></VisibilityIcon>
      )}
      <div>
        <TextField
          type={passwordShown ? 'text' : 'password'}
          label="Password"
          name="password"
          inputRef={register({
            required: 'You must specify a password',
            minLength: {
              value: 8,
              message: 'Password must have at least 8 characters',
            },
          })}
          required
        />
        {errors.password && <p>{errors.password.message}</p>}
      </div>
      <div>
        <TextField
          type={passwordShown ? 'text' : 'password'}
          label="Repeat Password"
          name="password_repeat"
          inputRef={register({
            validate: (value) =>
              value === password.current || 'The passwords do not match',
          })}
          required
        />
        {errors.password_repeat && <p>{errors.password_repeat.message}</p>}
      </div>
      <Button type="submit" variant="contained" color="primary" fullWidth>
        Change Password
      </Button>
    </form>
  );
};

export default ChangePasswordForm;
