import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Toolbar,
  Typography,
  IconButton,
  Tooltip,
  Input,
  Chip,
} from '@material-ui/core/';
import { lighten, makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import FilterListIcon from '@material-ui/icons/FilterList';

// button set
const OperationSet = ({
  operationDefs,
  handleChange,
  handleFilter,
  handleLeave,
}) => {
  // operationDefs.propTypes = {
  //     label: PropTypes.string.isRequired,
  //     handleClick: PropTypes.func,
  //     content: PropTypes.object,
  //     icon: PropTypes.object,
  // }
  const [open, setOpen] = useState(false);

  return (
    <>
      {operationDefs.map((op, index) => (
        <div key={index}>
          {op.override ? (
            op.override
          ) : (
            <Tooltip title={op.label} aria-label={op.ariaLabel}>
              <IconButton onClick={op.handleClick}>{op.icon}</IconButton>
            </Tooltip> // use tool tip?
          )}
        </div>
      ))}
      <IconButton onClick={() => setOpen(!open)}>
        <SearchIcon />
      </IconButton>
      {open && (
        <Input
          placeholder="Search"
          onChange={handleChange}
          endAdornment={
            <IconButton onClick={handleFilter}>
              <FilterListIcon />
            </IconButton>
          }
          autoFocus
          onBlur={handleLeave}
        />
      )}
    </>
  );
};

// enhanced table

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolBar = ({
  operationDefs,
  selected,
  title,
  handleChange,
  searchQueryObj,
  handleDeleteFilter,
  handleSearchLeave,
}) => {
  const classes = useToolbarStyles();
  const numSelected = selected.length;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <>
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {title}
          </Typography>

          {Object.keys(searchQueryObj || {}).length !== 0 &&
            Object.entries(searchQueryObj)
              .filter(([key, value]) => value?.length)
              .map(([key, value]) => (
                <Chip
                  key={key}
                  size="small"
                  label={`${key}: ${value}`}
                  variant="outlined"
                  onDelete={handleDeleteFilter(key)}
                />
              ))}
        </>
      )}
      <OperationSet
        operationDefs={operationDefs}
        handleChange={handleChange}
        searchQueryObj={searchQueryObj}
        handleLeave={handleSearchLeave}
      />
    </Toolbar>
  );
};

EnhancedTableToolBar.propTypes = {
  selected: PropTypes.array.isRequired,
  operationDefs: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

export default EnhancedTableToolBar;
