import axios from 'axios'; // chrome.exe --user-data-dir="C:/Chrome dev session" --disable-web-security
import { handleError } from './Utilities';

const APIx = axios.create({
  baseURL: window['config'].apiBaseUrl.mayanx,
});

const ApiService = {
  setEdmsAuth(auth) {
    APIx.defaults.headers.common['Authorization'] = auth;
  },
  forgetEdmsAuth() {
    delete APIx.defaults.headers.common['Authorization'];
  },
  createDocTypeAndAcl(setter, data) {
    console.log('creating new document type: ', data);
    return APIx.post(`document_types_aclgrouped/`, data)
      .then((response) => {
        console.log('created new document type', response);
        setter && setter(response.data);
        return true;
      })
      .catch(handleError);
  },
  updateDocTypeAndAcl(setter, docTypeId, data) {
    console.log('editing document type: ', data);
    return APIx.put(`document_types_aclgrouped/${docTypeId}`, data)
      .then((response) => {
        console.log('edited document type', response);
        setter && setter(response.data);
        return true;
      })
      .catch(handleError);
  },
};

export default ApiService;
