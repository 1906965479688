import React from 'react';
import { FormControl } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Controller } from 'react-hook-form';

function EditableTextField({ formProps, name, ...textProps }) {
  return (
    <FormControl fullWidth>
      <Controller
        as={
          <TextField
            size="small"
            variant="outlined"
            title="label"
            required
            {...textProps}
          />
        }
        name={name}
        control={formProps.control}
      />
    </FormControl>
  );
}

export default EditableTextField;
