import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { Switch, Route, Redirect } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';

import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, Typography, Divider } from '@material-ui/core';

// Icons
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AssignmentIcon from '@material-ui/icons/Assignment';
import BusinessIcon from '@material-ui/icons/Business';
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
import LinkIcon from '@material-ui/icons/Link';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PeopleIcon from '@material-ui/icons/People';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import StrikethroughSIcon from '@material-ui/icons/StrikethroughS';

// Components
import AccountControlArea from './Components/AccountManagement/AccountControlArea';
import AccountOverview from './Components/WineMarket/AccountOverview';
import AdminView from './Components/AdminView';
import BlockchainView from './Components/BlockchainView';
import BondView from './Components/BondMarket/BondView';
import BrokerView from './Components/BrokerView';
import BulkloaderView from './Components/Bulkloader';
import CustodianView from './Components/CustodianView';
import CustomersManagementView from './Components/CustomersManagement/CustomersManageView';
import DiamondView from './Components/DiamondMarket/DiamondView';
import Layout from './Components/Common/Layout';
import OperatorView from './Components/OperatorView';
import PortfolioView from './Components/WineMarket/PortfolioView';
import StaffManageView from './Components/StaffManagement/StaffManageView';
import ViewSelector from './Components/ViewSelector';
import WineView from './Components/WineMarket/WineView';

// Others
import { createSignalRConnection } from './SignalRConnection';
import ApiService from './ApiService/ApiService';
import theme from './theme';

// Exchange
import { Exchange } from './Components/exchange/containers/Exchange';
import AccountsManagement from './Components/AccountsManagement';

// Demo
import Crypto from './Components/Demo/Crypto';
import CryptoDashboard from './Components/Demo/CryptoDashboard';
import WineDashboard from './Components/Demo/WineDashboard';

// Contexts
import { CurrencyContextProvider } from './Contexts/CurrencyContext';

const useStyles = makeStyles((theme) => ({
  section: {
    padding: theme.spacing(1, 2),
    fontWeight: 'bold',
  },
}));

function App() {
  const classes = useStyles();
  const [isSignalrConnected, setIsSignalrConnected] = useState(false);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  // Roles: investor/issuer/broker/custodian/admin
  const [roles, setRoles] = useState(null);

  // Refresh
  const [updateFlag, setUpdateFlag] = useState(false);
  const update = () => setUpdateFlag(!updateFlag);

  useEffect(() => {
    // auto login (by getting token from keycloak)
    if (ApiService.getToken()) {
      const token = ApiService.login();
      setIsLoggedIn(true);
      createSignalRConnection(token, setIsSignalrConnected);
    }
  }, []);

  const props = {
    isLoggedIn,
    updateFlag,
    update,
    roles,
  };

  const sections = [
    {
      label: 'Administrator',
      display: roles?.includes('admin'),
      views: [
        {
          icon: <AssignmentIcon />,
          text: 'Bulkloader',
          path: '/bulkloader',
          getRoot: () => <BulkloaderView {...props} />,
        },
        {
          icon: <LinkIcon />,
          text: 'Blockchain',
          path: '/blockchain',
          getRoot: () => <BlockchainView {...props} />,
        },
        {
          icon: <SupervisorAccountIcon />,
          text: 'Admin',
          path: '/admin',
          getRoot: () => <AdminView {...props} />,
        },
      ],
    },
    {
      label: 'Dashboard',
      display: roles?.includes('admin') && window['config'].showCryptoDemo,
      views: [
        {
          icon: <DeveloperBoardIcon />,
          text: 'Wines',
          path: '/wine_dashboard',
          getRoot: () => <WineDashboard {...props} />,
        },
        {
          icon: <DeveloperBoardIcon />,
          text: 'Cryptos',
          path: '/crypto_dashboard',
          getRoot: () => <CryptoDashboard {...props} />,
        },
      ],
    },
    {
      label: 'Staff',
      display: roles?.includes('admin') || roles?.includes('staff'),
      views: [
        {
          icon: <PeopleIcon />,
          text: 'Customers',
          path: '/staff_manageCustomers',
          getRoot: () => <CustomersManagementView {...props} />,
        },
        {
          icon: <SupervisorAccountIcon />,
          text: 'Staff',
          path: '/staff_manageStaff',
          getRoot: () => <StaffManageView {...props} />,
        },
        {
          icon: <AccountBalanceWalletIcon />,
          text: 'Accounts Management',
          path: '/accountsmanagement',
          getRoot: () => <AccountsManagement {...props} />,
        },
      ],
    },
    {
      label: 'Broker',
      display: roles?.includes('broker'),
      views: [
        {
          icon: <BusinessIcon />,
          text: 'Broker',
          path: '/broker',
          getRoot: () => <BrokerView {...props} />,
        },
        {
          icon: <AccountBalanceWalletIcon />,
          text: 'Accounts Management',
          path: '/accountsmanagement',
          getRoot: () => <AccountsManagement {...props} />,
        },
      ],
    },
    {
      label: 'Custodian',
      display: roles?.includes('custodian'),
      views: [
        {
          icon: <AccountBalanceIcon />,
          text: 'Custodian',
          path: '/custodian',
          getRoot: () => <CustodianView {...props} />,
        },
      ],
    },
    {
      label: 'Operator',
      display: roles?.includes('booperator'),
      views: [
        {
          icon: <AccountBalanceIcon />,
          text: 'Operator',
          path: '/operator',
          getRoot: () => <OperatorView {...props} />,
        },
      ],
    },
    {
      label: 'Trading',
      display: true,
      views: [
        {
          icon: <AssignmentIcon />,
          text: 'Wines',
          path: '/wines',
          getRoot: () => <WineView {...props} />,
        },
        {
          icon: <AssignmentIcon />,
          text: 'Mortgages',
          path: '/bonds',
          getRoot: () => <BondView {...props} />,
        },
        {
          icon: <AssignmentIcon />,
          text: 'Diamonds',
          path: '/diamonds',
          getRoot: () => <DiamondView {...props} />,
        },
        window['config'].showCryptoDemo && {
          icon: <StrikethroughSIcon />,
          text: 'Crypto',
          path: '/crypto',
          getRoot: () => <Crypto {...props} />,
        },
        {
          icon: <MonetizationOnIcon />,
          text: 'Portfolios',
          path: '/portfolios',
          getRoot: () => <PortfolioView {...props} />,
        },
        {
          icon: <AccountBalanceWalletIcon />,
          text: 'Account Overview',
          path: '/accounts',
          getRoot: () => <AccountOverview {...props} />,
        },
        {
          icon: <AssignmentIcon />,
          text: 'Exchange',
          path: '/exchange',
          getRoot: () => <Exchange {...props} />,
        },
      ],
    },
  ];

  const renderAccount = (
    <AccountControlArea
      isLoggedIn={isLoggedIn}
      setIsLoggedIn={setIsLoggedIn}
      setRoles={setRoles}
      update={update}
    />
  );

  const renderMenu = sections.map(
    (section) =>
      section.display && (
        <div key={section.label}>
          <Typography className={classes.section}>{section.label}</Typography>
          <ViewSelector views={section.views} />
          <Divider />
        </div>
      )
  );

  const views = sections.reduce((filtered, section) => {
    if (section.display) {
      filtered.push(...section.views);
    }
    return filtered;
  }, []);

  return (
    isLoggedIn && (
      <ThemeProvider theme={theme}>
        <CurrencyContextProvider>
          <CssBaseline />
          <Layout
            connected={isSignalrConnected}
            renderAccount={renderAccount}
            renderMenu={renderMenu}
          >
            <Switch>
              {views.map(
                (view) =>
                  view && (
                    <Route
                      key={view.path}
                      path={view.path}
                      component={view.getRoot}
                    />
                  )
              )}
              <Redirect exact from="/" to={views[0].path} />
            </Switch>
          </Layout>
          <ToastContainer />
        </CurrencyContextProvider>
      </ThemeProvider>
    )
  );
}

export default App;
