import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Controller } from 'react-hook-form';
import { FormControl, Select, MenuItem } from '@material-ui/core';

import InfoGrid from '../DetailedInfo/InfoGrid';
import OnBoardService from '../../ApiService/OnBoardService';

function DetailStaffInfo({
  data,
  params,
  staffStatus,
  staffRoles,
  update,
  edit,
  setEdit,
}) {
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (data) {
      console.log('staff: ', data);
      setIsAdmin(false); // dummy
      // TODO: check a staff's (not the current user!) admin status
      // KeycloakService.loadUserProfile(data?.staffID).then(response => {
      //   console.log('user profile: ', response);
      //   setIsAdmin((response.attributes?.isAdmin && response.attributes?.isAdmin[0]) === 'true')
      // });
    }
  }, [data]);

  const fieldDefs = [
    {
      id: 'username',
      numeric: false,
      disablePadding: true,
      label: 'Username',
      valueOf: (staff) => staff.userName,
    },
    {
      id: 'id',
      label: 'ID',
      valueOf: (staff) => staff.staffID,
    },
    {
      id: 'firstName',
      label: 'First Name',
      valueOf: (staff) => staff.firstName,
    },
    {
      id: 'lastName',
      label: 'Last Name',
      valueOf: (staff) => staff.lastName,
    },
    {
      id: 'status',
      label: 'Status',
      valueOf: (staff) => staff.status.status,
      editField: (staff, { control, register }) => (
        <FormControl fullWidth>
          <Controller
            as={
              <Select title="Status" ref={register} required>
                {staffStatus.map((status) => (
                  <MenuItem key={status.id} value={status.status}>
                    {status.status}
                  </MenuItem>
                ))}
              </Select>
            }
            name="status"
            control={control}
          />
        </FormControl>
      ),
      editApiCall: (staff, newValue) => {
        console.log('editing staff status: ', staff);
        return OnBoardService.updateStaffStatus(null, staff.staffID, newValue);
      },
    },
    {
      id: 'isAdmin',
      label: 'Is Admin?',
      valueOf: () => isAdmin,
      displayValueOf: () => isAdmin.toString(),
      editField: (staff, { register, control, setValue }) => (
        // <Checkbox
        //   name="isAdmin"
        //   inputRef={register}
        // />
        <input type="checkbox" name="isAdmin" ref={register} />
      ),
      editApiCall: (staff, newValue) => {
        console.log('editing staff admin right: ', staff, newValue);
      },
    },
    {
      id: 'createdDate',
      label: 'Created Date',
      valueOf: (staff) => moment(staff.createDate).format('lll'),
    },
  ];

  const handleSubmit = async (formData, e, staff) => {
    console.log('data', formData);
    let promises = [];
    fieldDefs.forEach((field) => {
      if (field.editField && field.editApiCall) {
        const formValue = formData[field.id];
        if (field.valueOf(staff) !== formValue) {
          promises.push(field.editApiCall(staff, formValue));
        }
      }
    });
    return promises;
  };

  return (
    <InfoGrid
      data={data}
      fieldDefs={fieldDefs}
      handleSubmit={handleSubmit}
      update={update}
      edit={edit}
      setEdit={setEdit}
    />
  );
}

export default DetailStaffInfo;
