import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Table } from 'reactstrap';
import { numberWithCommas } from './common';
import EnhancedTable from '../../EnhancedTableView/EnhancedTable';
import { makeStyles } from '@material-ui/core/styles';

export const Positions = ({ positions, path, title = '' }) => {
  const history = useHistory();

  const headers = 'Ticker, SOD Qty, Day Qty, Net Qty, Avg. Price, Cost, Net Value, Unrealized gain'.split(
    ', '
  );
  const precision = 2;

  const [selected, setSelected] = useState([]);

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }));
  const classes = useStyles();

  const columnDefs = [
    {
      id: 'ticker',
      numeric: true,
      disablePadding: false,
      label: 'Ticker',
      valueOf: (pos) => pos.securityKey,
    },
    {
      id: 'sodQty',
      numeric: false,
      disablePadding: true,
      label: 'SOD Qty',
      valueOf: (pos) => pos.sodQuantity,
    },
    {
      id: 'dayQty',
      numeric: true,
      disablePadding: false,
      label: 'Day Qty',
      valueOf: (pos) => pos.dayQuantity,
    },
    {
      id: 'netQty',
      numeric: true,
      disablePadding: false,
      label: 'Net Qty',
      valueOf: (pos) => pos.netQuantity,
    },
    {
      id: 'avgPrice',
      numeric: true,
      disablePadding: false,
      label: 'Avg. Price',
      valueOf: (pos) => numberWithCommas(pos.netAveragePrice, precision),
    },
    {
      id: 'cost',
      numeric: true,
      disablePadding: false,
      label: 'Cost',
      valueOf: (pos) => numberWithCommas(pos.netValue * 0.0025, precision),
    },
    {
      id: 'netValue',
      numeric: true,
      disablePadding: false,
      label: 'Net Value',
      valueOf: (pos) =>
        numberWithCommas(pos.netQuantity * pos.netAveragePrice, precision),
    },
    {
      id: 'unrelGain',
      numeric: true,
      disablePadding: false,
      label: 'Unrealized Gain',
      valueOf: (pos) => 
      {
        if (pos.unrealized)
        {
            return numberWithCommas(pos.unrealized, precision);
        }
      },
    },
  ];

  const opDefs = [];
  const actionDefs = [];

  return (
    <>
      <EnhancedTable
        classes={classes}
        columnDefs={columnDefs}
        operationDefs={opDefs}
        dataList={positions}
        selected={selected}
        setSelected={setSelected}
        actionDefs={actionDefs}
        title={title}
      />
    </>
  );
};
