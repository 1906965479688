import React, { useEffect, useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  market: {
    height: 600,
  },
}));

const Demo = () => {
  const classes = useStyles();

  const tickerContainerRef = useRef();
  const marketContainerRef = useRef();

  useEffect(() => {
    const script1 = document.createElement('script');
    script1.src =
      'https://s3.tradingview.com/external-embedding/embed-widget-tickers.js';
    script1.async = true;
    script1.innerHTML = JSON.stringify({
      symbols: [
        {
          proName: 'FX_IDC:EURUSD',
          title: 'EUR/USD',
        },
        {
          proName: 'BITSTAMP:BTCUSD',
          title: 'BTC/USD',
        },
        {
          proName: 'BITSTAMP:ETHUSD',
          title: 'ETH/USD',
        },
      ],
      colorTheme: 'dark',
      isTransparent: false,
      showSymbolLogo: false,
      locale: 'en',
    });
    tickerContainerRef.current.appendChild(script1);

    const script2 = document.createElement('script');
    script2.src =
      'https://s3.tradingview.com/external-embedding/embed-widget-screener.js';
    script2.async = true;
    script2.innerHTML = JSON.stringify({
      width: '100%',
      height: '100%',
      defaultColumn: 'overview',
      screener_type: 'crypto_mkt',
      displayCurrency: 'USD',
      colorTheme: 'dark',
      locale: 'en',
    });
    marketContainerRef.current.appendChild(script2);
  }, []);

  return (
    <Grid container spacing={1}>
      <Grid ref={tickerContainerRef} item xs={12}></Grid>
      <Grid
        ref={marketContainerRef}
        item
        xs={12}
        className={classes.market}
      ></Grid>
    </Grid>
  );
};

export default Demo;
