import React from 'react';

import { Dialog, DialogTitle, DialogContent, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  content: {
    width: '100%',
    padding: theme.spacing(0, 2, 2, 2),
  },
  card: {
    padding: theme.spacing(1, 2),
  },
  footer: {
    padding: theme.spacing(2, 0, 0, 0),
    float: 'right',
  },
  button: {
    margin: theme.spacing(0, 0, 0, 1),
  },
}));

function DialogForm({ open, onClose, title, onSubmit, children, maxWidth }) {
  const classes = useStyles();
  return (
    <Dialog
      className={classes.root}
      open={open}
      onClose={onClose}
      scroll="paper"
      maxWidth={maxWidth || 'md'}
      fullWidth={true}
    >
      <DialogTitle id="dialog-title">{title}</DialogTitle>
      <DialogContent className={classes.content} dividers={true}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit(e);
          }}
        >
          {children}
          <div className={classes.footer}>
            <Button
              className={classes.button}
              variant="outlined"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              type="submit"
            >
              OK
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default DialogForm;
