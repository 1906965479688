import {
  JsonHubProtocol,
  HttpTransportType,
  HubConnectionBuilder,
  LogLevel,
} from '@aspnet/signalr';
import { toast } from 'react-toastify';

const SignalrConfig = {
  connectionHub: window['config'].apiBaseUrl.hub,
  logLevel: LogLevel.Trace,
  reconnectTimeout: 3000,
  maxRetryCount: 20,
};

var retries = 0;
const retry = (fn, ms = 1000, maxRetries = 5) =>
  new Promise((resolve, reject) => {
    fn()
      .then(() => {
        retries = 0;
        resolve();
      })
      .catch(() => {
        setTimeout(() => {
          console.log(
            'Retrying failed promise... ' + retries + '/' + maxRetries
          );
          ++retries;
          if (retries >= maxRetries) {
            return reject('Maximum retries exceeded');
          }
          retry(fn, ms, maxRetries).then(resolve);
        }, ms);
      });
  });

const startSignalRConnection = (connection, setConnected) => {
  console.log('Connecting to SignalR Hub');

  retry(
    () => {
      return connection.start();
    },
    SignalrConfig.reconnectTimeout,
    SignalrConfig.maxRetryCount
  )
    .then(() => {
      console.info('SignalR Connected');
      setConnected(true);
    })
    .catch((err) => {
      console.error('SignalR Connection Error: ', err);
    });
};

export const createSignalRConnection = (token, setConnected) => {
  const connectionHub = SignalrConfig.connectionHub;
  const protocol = new JsonHubProtocol();
  const transport =
    HttpTransportType.WebSockets | HttpTransportType.LongPolling;

  const options = {
    transport,
    logMessageContent: true,
    logger: SignalrConfig.logLevel,
    accessTokenFactory: () => token,
  };

  // create the connection instance
  console.log('SignalR', {
    connectionHub,
    protocol,
    options,
  });

  const connection = new HubConnectionBuilder()
    .withUrl(connectionHub, options)
    .withHubProtocol(protocol)
    .build();

  // event handlers
  connection.on('PushNotification', (from, message) => {
    const encodedMsg = `${from}: ${message}`;
    console.log(`SignalR - PushNotification: ${encodedMsg}`);
    toast.info(`SignalR - PushNotification: ${encodedMsg}`);
  });

  // re-conneciton
  connection.onclose((error) => {
    setConnected(false);
    console.log('Debug', 'Start SignalR - Reconnect', error);
    startSignalRConnection(connection, setConnected);
  });

  console.log('Debug', 'Start Signal R when User Login');
  startSignalRConnection(connection, setConnected);
};
