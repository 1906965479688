/**
 * @fileoverview gRPC-Web generated client stub for Exchange.GRPC
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!

/* eslint-disable */
// @ts-nocheck

const grpc = {};
grpc.web = require('grpc-web');

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
const proto = {};
proto.Exchange = {};
proto.Exchange.GRPC = require('./markethub_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.Exchange.GRPC.MarketHubServiceClient = function (
  hostname,
  credentials,
  options
) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;
};

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.Exchange.GRPC.MarketHubServicePromiseClient = function (
  hostname,
  credentials,
  options
) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Exchange.GRPC.TickerRequest,
 *   !proto.Exchange.GRPC.MarketUpdate>}
 */
const methodDescriptor_MarketHubService_SubscribeFullMarket = new grpc.web.MethodDescriptor(
  '/Exchange.GRPC.MarketHubService/SubscribeFullMarket',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.Exchange.GRPC.TickerRequest,
  proto.Exchange.GRPC.MarketUpdate,
  /**
   * @param {!proto.Exchange.GRPC.TickerRequest} request
   * @return {!Uint8Array}
   */
  function (request) {
    return request.serializeBinary();
  },
  proto.Exchange.GRPC.MarketUpdate.deserializeBinary
);

/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Exchange.GRPC.TickerRequest,
 *   !proto.Exchange.GRPC.MarketUpdate>}
 */
const methodInfo_MarketHubService_SubscribeFullMarket = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Exchange.GRPC.MarketUpdate,
  /**
   * @param {!proto.Exchange.GRPC.TickerRequest} request
   * @return {!Uint8Array}
   */
  function (request) {
    return request.serializeBinary();
  },
  proto.Exchange.GRPC.MarketUpdate.deserializeBinary
);

/**
 * @param {!proto.Exchange.GRPC.TickerRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Exchange.GRPC.MarketUpdate>}
 *     The XHR Node Readable Stream
 */
proto.Exchange.GRPC.MarketHubServiceClient.prototype.subscribeFullMarket = function (
  request,
  metadata
) {
  return this.client_.serverStreaming(
    this.hostname_ + '/Exchange.GRPC.MarketHubService/SubscribeFullMarket',
    request,
    metadata || {},
    methodDescriptor_MarketHubService_SubscribeFullMarket
  );
};

/**
 * @param {!proto.Exchange.GRPC.TickerRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Exchange.GRPC.MarketUpdate>}
 *     The XHR Node Readable Stream
 */
proto.Exchange.GRPC.MarketHubServicePromiseClient.prototype.subscribeFullMarket = function (
  request,
  metadata
) {
  return this.client_.serverStreaming(
    this.hostname_ + '/Exchange.GRPC.MarketHubService/SubscribeFullMarket',
    request,
    metadata || {},
    methodDescriptor_MarketHubService_SubscribeFullMarket
  );
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Exchange.GRPC.TickerRequest,
 *   !proto.Exchange.GRPC.TickerUpdate>}
 */
const methodDescriptor_MarketHubService_SubscribeSingleTicker = new grpc.web.MethodDescriptor(
  '/Exchange.GRPC.MarketHubService/SubscribeSingleTicker',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.Exchange.GRPC.TickerRequest,
  proto.Exchange.GRPC.TickerUpdate,
  /**
   * @param {!proto.Exchange.GRPC.TickerRequest} request
   * @return {!Uint8Array}
   */
  function (request) {
    return request.serializeBinary();
  },
  proto.Exchange.GRPC.TickerUpdate.deserializeBinary
);

/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Exchange.GRPC.TickerRequest,
 *   !proto.Exchange.GRPC.TickerUpdate>}
 */
const methodInfo_MarketHubService_SubscribeSingleTicker = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Exchange.GRPC.TickerUpdate,
  /**
   * @param {!proto.Exchange.GRPC.TickerRequest} request
   * @return {!Uint8Array}
   */
  function (request) {
    return request.serializeBinary();
  },
  proto.Exchange.GRPC.TickerUpdate.deserializeBinary
);

/**
 * @param {!proto.Exchange.GRPC.TickerRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Exchange.GRPC.TickerUpdate>}
 *     The XHR Node Readable Stream
 */
proto.Exchange.GRPC.MarketHubServiceClient.prototype.subscribeSingleTicker = function (
  request,
  metadata
) {
  return this.client_.serverStreaming(
    this.hostname_ + '/Exchange.GRPC.MarketHubService/SubscribeSingleTicker',
    request,
    metadata || {},
    methodDescriptor_MarketHubService_SubscribeSingleTicker
  );
};

/**
 * @param {!proto.Exchange.GRPC.TickerRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Exchange.GRPC.TickerUpdate>}
 *     The XHR Node Readable Stream
 */
proto.Exchange.GRPC.MarketHubServicePromiseClient.prototype.subscribeSingleTicker = function (
  request,
  metadata
) {
  return this.client_.serverStreaming(
    this.hostname_ + '/Exchange.GRPC.MarketHubService/SubscribeSingleTicker',
    request,
    metadata || {},
    methodDescriptor_MarketHubService_SubscribeSingleTicker
  );
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Exchange.GRPC.BrokerRequest,
 *   !proto.Exchange.GRPC.TradableTicker>}
 */
const methodDescriptor_MarketHubService_GetTradableTicker = new grpc.web.MethodDescriptor(
  '/Exchange.GRPC.MarketHubService/GetTradableTicker',
  grpc.web.MethodType.UNARY,
  proto.Exchange.GRPC.BrokerRequest,
  proto.Exchange.GRPC.TradableTicker,
  /**
   * @param {!proto.Exchange.GRPC.BrokerRequest} request
   * @return {!Uint8Array}
   */
  function (request) {
    return request.serializeBinary();
  },
  proto.Exchange.GRPC.TradableTicker.deserializeBinary
);

/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Exchange.GRPC.BrokerRequest,
 *   !proto.Exchange.GRPC.TradableTicker>}
 */
const methodInfo_MarketHubService_GetTradableTicker = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Exchange.GRPC.TradableTicker,
  /**
   * @param {!proto.Exchange.GRPC.BrokerRequest} request
   * @return {!Uint8Array}
   */
  function (request) {
    return request.serializeBinary();
  },
  proto.Exchange.GRPC.TradableTicker.deserializeBinary
);

/**
 * @param {!proto.Exchange.GRPC.BrokerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Exchange.GRPC.TradableTicker)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Exchange.GRPC.TradableTicker>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Exchange.GRPC.MarketHubServiceClient.prototype.getTradableTicker = function (
  request,
  metadata,
  callback
) {
  return this.client_.rpcCall(
    this.hostname_ + '/Exchange.GRPC.MarketHubService/GetTradableTicker',
    request,
    metadata || {},
    methodDescriptor_MarketHubService_GetTradableTicker,
    callback
  );
};

/**
 * @param {!proto.Exchange.GRPC.BrokerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Exchange.GRPC.TradableTicker>}
 *     Promise that resolves to the response
 */
proto.Exchange.GRPC.MarketHubServicePromiseClient.prototype.getTradableTicker = function (
  request,
  metadata
) {
  return this.client_.unaryCall(
    this.hostname_ + '/Exchange.GRPC.MarketHubService/GetTradableTicker',
    request,
    metadata || {},
    methodDescriptor_MarketHubService_GetTradableTicker
  );
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Exchange.GRPC.Message,
 *   !proto.Exchange.GRPC.Message>}
 */
const methodDescriptor_MarketHubService_Echo = new grpc.web.MethodDescriptor(
  '/Exchange.GRPC.MarketHubService/Echo',
  grpc.web.MethodType.UNARY,
  proto.Exchange.GRPC.Message,
  proto.Exchange.GRPC.Message,
  /**
   * @param {!proto.Exchange.GRPC.Message} request
   * @return {!Uint8Array}
   */
  function (request) {
    return request.serializeBinary();
  },
  proto.Exchange.GRPC.Message.deserializeBinary
);

/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.Exchange.GRPC.Message,
 *   !proto.Exchange.GRPC.Message>}
 */
const methodInfo_MarketHubService_Echo = new grpc.web.AbstractClientBase.MethodInfo(
  proto.Exchange.GRPC.Message,
  /**
   * @param {!proto.Exchange.GRPC.Message} request
   * @return {!Uint8Array}
   */
  function (request) {
    return request.serializeBinary();
  },
  proto.Exchange.GRPC.Message.deserializeBinary
);

/**
 * @param {!proto.Exchange.GRPC.Message} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.Exchange.GRPC.Message)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Exchange.GRPC.Message>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Exchange.GRPC.MarketHubServiceClient.prototype.echo = function (
  request,
  metadata,
  callback
) {
  return this.client_.rpcCall(
    this.hostname_ + '/Exchange.GRPC.MarketHubService/Echo',
    request,
    metadata || {},
    methodDescriptor_MarketHubService_Echo,
    callback
  );
};

/**
 * @param {!proto.Exchange.GRPC.Message} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Exchange.GRPC.Message>}
 *     Promise that resolves to the response
 */
proto.Exchange.GRPC.MarketHubServicePromiseClient.prototype.echo = function (
  request,
  metadata
) {
  return this.client_.unaryCall(
    this.hostname_ + '/Exchange.GRPC.MarketHubService/Echo',
    request,
    metadata || {},
    methodDescriptor_MarketHubService_Echo
  );
};

module.exports = proto.Exchange.GRPC;
