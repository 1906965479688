import Keycloak from 'keycloak-js';

const initOptions = {
  url: window['config'].apiBaseUrl.keycloak,
  realm: 'Ptliquidity',
  clientId: 'spa_dms',
  onLoad: 'login-required',
  checkLoginIframe: false,
};

let keycloak = Keycloak(initOptions);

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
  keycloak
    .init({ onLoad: initOptions.onLoad })
    .then((auth) => {
      if (!auth) {
        window.location.reload();
      } else {
        console.info('Authenticated');
      }

      console.info('keycloak: ', keycloak);
      onAuthenticatedCallback();
    })
    .catch(() => {
      console.error('Authenticated Failed');
    });
};

const doLogin = keycloak.login;

const doLogout = keycloak.logout;

const getToken = () => keycloak.token;

const updateToken = () => {
  return keycloak
    .updateToken(70)
    .then((refreshed) => {
      if (refreshed) {
        console.log('Token refreshed');
        return true;
      } else {
        console.warn(
          'Token not refreshed, valid for ' +
            Math.round(
              keycloak.tokenParsed.exp +
                keycloak.timeSkew -
                new Date().getTime() / 1000
            ) +
            ' seconds'
        );
      }
    })
    .catch(doLogin);
};

const getUsername = () => keycloak.tokenParsed.preferred_username;

const loadUserProfile = keycloak.loadUserProfile; // async function

const KeycloakService = {
  initKeycloak,
  doLogin,
  doLogout,
  getToken,
  updateToken,
  getUsername,
  loadUserProfile,
};

export default KeycloakService;
