import React from 'react';
import { InputLabel, MenuItem, Select } from '@material-ui/core';
import { useCurrencyContext } from '../../Contexts/CurrencyContext';

const CurrencySelector = ({ label }) => {
  const context = useCurrencyContext();

  return (
    <>
      {label && <InputLabel id="ccy">{label}</InputLabel>}
      <Select
        labelId="ccy"
        id="ccy"
        value={context.ccy}
        onChange={(e) => {
          context.setCcy(e.target.value);
        }}
      >
        <MenuItem value={'eur'}>EUR €</MenuItem>
        <MenuItem value={'gbp'}>GBP £</MenuItem>
        <MenuItem value={'hkd'}>HKD $</MenuItem>
        <MenuItem value={'usd'}>USD $</MenuItem>
      </Select>
    </>
  );
};

export default CurrencySelector;
