import axios from 'axios';

const API = axios.create({
  baseURL: window['config'].apiBaseUrl.exchange + '/api/',
});

const ApiService = {
  setMarketCoreAuth(auth) {
    API.defaults.headers.common['Authorization'] = auth;
  },
  startmarket() {
    return API.get(`oms/startmarket/`);
  },
  premarketopen() {
    return API.get(`oms/premarketopen/`);
  },
  marketopen() {
    return API.get(`oms/marketopen/`);
  },
  marketclose() {
    return API.get(`oms/marketclose/`);
  },
  postmarketclose() {
    return API.get(`oms/postmarketclose/`);
  },
};

export default ApiService;
