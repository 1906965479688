import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import api from '../ApiService/MarketCoreService';

const TokenBalance = ({ address }) => {
  const [tokenBalance, setTokenBalance] = useState(null);

  useEffect(() => {
    if (address) {
      const fetch = async () => {
        console.log(`Check token balance ${address}`);
        setTokenBalance((await api.checktokenBalanceBroker(address)).data);
      };
      fetch();
    }
  }, [address]);

  return (
    <Grid container>
      {tokenBalance != null && (
        <Grid item xs={12}>
          <Typography>Token Balance of {tokenBalance.account}</Typography>
          <h3>
            <b>{tokenBalance.balance}</b> PTC
          </h3>
        </Grid>
      )}
    </Grid>
  );
};

export default TokenBalance;
