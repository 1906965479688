import axios from 'axios'; // chrome.exe --user-data-dir="C:/Chrome dev session" --disable-web-security
import { handleError } from './Utilities';

const API = axios.create({
  baseURL: window['config'].apiBaseUrl.workflow,
});

const WorkflowService = {
  setWorkflowServiceAuth(auth) {
    API.defaults.headers.common['Authorization'] = auth;
  },

  getStates(setter) {
    return API.get(`Status/`).then((response) => {
      let states = response.data;
      console.log('got states: ', states);
      setter && setter(states);
      return states;
    });
  },

  getWorkflows(setter) {
    return API.get(`Workflows/`)
      .then((response) => {
        let workflows = response.data;
        console.log('workflows info', workflows);
        setter && setter(workflows);
        return true;
      })
      .catch(handleError);
  },
  createWorkflow(setter, body) {
    return API.post(`Workflows`, body)
      .then((response) => {
        const workflow = response.data;
        console.log('created new workflow', workflow);
        setter && setter(workflow);
        return workflow;
      })
      .catch(handleError); // TODO: handle invalid
  },

  getUserGroups(setter) {
    return API.get(`UserGroups/`).then((response) => {
      const userGroups = response.data;
      console.log('got userGroups', userGroups);
      setter && setter(userGroups);
      return userGroups;
    });
  },
  createUserGroup(setter, body) {
    return API.post(`UserGroups/`, body)
      .then((response) => {
        const userGroup = response.data;
        console.log('created new userGroup', userGroup);
        setter && setter(userGroup);
        return userGroup;
      })
      .catch(handleError); // TODO: handle invalid
  },
  addStaffToUserGroup(setter, body) {
    return API.post(`users/`, body)
      .then((response) => {
        const x = response.data;
        console.log('added user to userGroup', x);
        setter && setter(x);
        return x;
      })
      .catch(handleError); // TODO: handle invalid
  },

  getStateTypes(setter) {
    return API.get(`StateTypes/`).then((response) => {
      const stateTypes = response.data;
      console.log('got stateTypes', stateTypes);
      setter && setter(stateTypes);
      return stateTypes;
    });
  },
  createStateType(setter, body) {
    return API.post(`StateTypes/`, body)
      .then((response) => {
        const stateType = response.data;
        console.log('created new stateType', stateType);
        setter && setter(stateType);
        return stateType;
      })
      .catch(handleError); // TODO: handle invalid
  },

  getActions(setter) {
    return API.get(`Actions/`).then((response) => {
      const actions = response.data;
      console.log('got actions', actions);
      setter && setter(actions);
      return actions;
    });
  },

  getFinalizationEvents(setter) {
    return API.get(`WorkflowFinalizationEvents/`).then((response) => {
      const e = response.data;
      console.log('got FinalizationEvents', e);
      setter && setter(e);
      return e;
    });
  },
  createAction(setter, body) {
    return API.post(`Actions/`, body)
      .then((response) => {
        const action = response.data;
        console.log('created new action ', action);
        setter && setter(action);
        return action;
      })
      .catch(handleError); // TODO: handle invalid
  },

  createProcessState(setter, body) {
    return API.post(`ProcessStates/`, body)
      .then((response) => {
        const processState = response.data;
        console.log('created new processState ', processState);
        setter && setter(processState);
        return processState;
      })
      .catch(handleError); // TODO: handle invalid
  },

  getProcessStates(setter) {
    return API.get(`ProcessStates/`)
      .then((response) => {
        let ProcessStates = response.data;
        console.log('ProcessStates info', ProcessStates);
        setter && setter(ProcessStates);
        return ProcessStates;
      })
      .catch(handleError);
  },

  getProcessInstances(setter) {
    return API.get(`ProcessInstances/`)
      .then((response) => {
        let ProcessInstances = response.data;
        console.log('ProcessInstances info', ProcessInstances);
        setter && setter(ProcessInstances);
        return ProcessInstances;
      })
      .catch(handleError);
  },

  getWorkflowInstances(setter) {
    return API.get(`WorkflowInstances/`)
      .then((response) => {
        let WorkflowInstances = response.data;
        console.log('WorkflowInstances info', WorkflowInstances);
        setter && setter(WorkflowInstances);
        return WorkflowInstances;
      })
      .catch(handleError);
  },

  getProcessStateTransition(setter) {
    return API.get(`ProcessStateTransitions/`)
      .then((response) => {
        let ProcessStateTransitions = response.data;
        console.log('ProcessStateTransitions info', ProcessStateTransitions);
        setter && setter(ProcessStateTransitions);
        return ProcessStateTransitions;
      })
      .catch(handleError);
  },

  createProcessStateTransition(setter, body) {
    return API.post(`ProcessStateTransitions/`, body)
      .then((response) => {
        const processStateTransition = response.data;
        console.log(
          'created new processStateTransition ',
          processStateTransition
        );
        setter && setter(processStateTransition);
        return processStateTransition;
      })
      .catch(handleError);
  },

  getAvailableActions(setter, workflowInstanceID, CurrentProcessStateID) {
    return API.get(
      `WorkflowInstances/AvailableAction/${workflowInstanceID}/${CurrentProcessStateID}`
    )
      .then((response) => {
        const availableActions = response.data;
        console.log('got availableAction ', availableActions);
        setter && setter(availableActions);
        return availableActions;
      })
      .catch(handleError);
  },
};

export default WorkflowService;
