import React, { useEffect, useState } from 'react';

import { fade, makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

import { measureTextWidth } from '../WineMarket/GraphUtil';
import EnhancedTable from '../EnhancedTableView/EnhancedTable';
import BinanceService from '../../ApiService/BinanceService';

const numberFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const useChartStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  header: {
    fontSize: '1.2rem',
  },
}));
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
const RADIAN = Math.PI / 180;
const renderCustomizedLabel =
  (theme) =>
  ({ cx, cy, midAngle, innerRadius, outerRadius, percent, name }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.25;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    const text = `${name} ${(percent * 100).toFixed(0)}%`;
    const textWidth = measureTextWidth(text, 14, 'Arial', 700);
    const rectWidth = textWidth + 10;
    const rectPadding = 5;

    return (
      <>
        <rect
          x={x > cx ? x - rectPadding : x - rectWidth + rectPadding}
          y={y - 10}
          width={rectWidth}
          height="20"
          fill={fade(theme.palette.text.secondary, 0.5)}
        ></rect>
        <text
          x={x}
          y={y}
          fill={theme.palette.text.primary}
          textAnchor={x > cx ? 'start' : 'end'}
          dominantBaseline="central"
          fontFamily="Arial"
          fontSize={14}
          fontWeight={700}
        >
          {text}
        </text>
      </>
    );
  };
const StatPieChart = ({ header, data }) => {
  const classes = useChartStyles();
  const theme = useTheme();

  return (
    <Paper elevation={1} className={classes.root}>
      <div className={classes.header}>{header}</div>
      <ResponsiveContainer minHeight={200}>
        <PieChart>
          <Pie
            data={data}
            labelLine={false}
            label={renderCustomizedLabel(theme)}
            innerRadius={30}
            isAnimationActive={false}
          >
            {data.map((_, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </Paper>
  );
};

const useTableStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  label: {
    paddingLeft: theme.spacing(4),
  },
  value: {
    paddingTop: theme.spacing(2),
  },
  up: {
    color: 'lime',
  },
  down: {
    color: 'red',
  },
}));
function formatUpDownPct(classes, pct) {
  const className = pct < 0 ? classes.down : classes.up;
  return <span className={className}>{pct.toFixed(2)}%</span>;
}

const StatTable = ({ data }) => {
  const classes = useTableStyles();

  const [selected, setSelected] = useState([]);
  const columnDefs = [
    {
      id: 'product',
      numeric: false,
      disablePadding: false,
      label: 'Product',
      valueOf: (item) => item.product,
    },
    {
      id: 'size',
      numeric: false,
      disablePadding: false,
      label: 'Issued Size',
      valueOf: (item) => item.size,
      displayValueOf: (item) =>
        Number(Number(item.size).toFixed(2)).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
    },
    {
      id: 'buy',
      numeric: true,
      disablePadding: false,
      label: 'Total Buy',
      valueOf: (item) => item.buy,
    },
    {
      id: 'sell',
      numeric: true,
      disablePadding: false,
      label: 'Total Sell',
      valueOf: (item) => item.sell,
    },
    {
      id: 'qty',
      numeric: true,
      disablePadding: false,
      label: 'Net Qty',
      valueOf: (item) => (item.buy - item.sell).toLocaleString(),
    },
    {
      id: 'delta',
      numeric: true,
      disablePadding: false,
      label: 'Net Chg%',
      valueOf: (item) =>
        formatUpDownPct(classes, ((item.buy - item.sell) / item.size) * 100),
    },
    {
      id: '90d',
      numeric: true,
      disablePadding: false,
      label: '90 Day',
      valueOf: (item) => 100,
      displayValueOf: (item) => (
        <img src="/images/90d.png" style={{ maxWidth: 50 }} />
      ),
    },
    {
      id: 'open',
      numeric: true,
      disablePadding: false,
      label: 'Open Price',
      valueOf: (item) => item.open && numberFormatter.format(item.open),
    },
    {
      id: 'last',
      numeric: true,
      disablePadding: false,
      label: 'Last Price',
      valueOf: (item) => (
        <>
          {numberFormatter.format(item.last)}(
          {formatUpDownPct(
            classes,
            ((item.last - item.open) / item.open) * 100
          )}
          )
        </>
      ),
    },
    {
      id: 'cap',
      numeric: true,
      disablePadding: false,
      label: 'Mkt Cap',
      valueOf: (item) => numberFormatter.format(item.size * item.last),
    },
    // {
    //   id: 'pnl',
    //   numeric: true,
    //   disablePadding: false,
    //   label: 'P&L',
    //   valueOf: (item) => item.unrealized,
    //   displayValueOf: (item) =>
    //     item.unrealized && (
    //       <div className={item.percentage < 0 ? classes.down : classes.up}>
    //         {item.unrealized.toLocaleString()} (
    //         {(Math.round(item.percentage * 100) / 100).toFixed(2)}%)
    //       </div>
    //     ),
    // },
  ];

  const opDefs = [];
  const actionDefs = [];
  const title = '';

  return (
    <EnhancedTable
      classes={classes}
      columnDefs={columnDefs}
      operationDefs={opDefs}
      dataList={data}
      selected={selected}
      setSelected={setSelected}
      actionDefs={actionDefs}
      title={title}
    />
  );
};

const DefaultStats = [
  {
    product: 'BTC',
    size: 10000,
    buy: 100.15468,
    sell: 984.12045,
    open: 495,
    last: 500,
  },
  {
    product: 'ETH',
    size: 10000,
    buy: 50.12008,
    sell: 550.00045,
    open: 462,
    last: 450,
  },
  {
    product: 'EOS',
    size: 10000,
    buy: 360.18818,
    sell: 355.78891,
    open: 342,
    last: 350,
  },
];
const symbols = ['BTC', 'ETH', 'EOS'];

const CryptoDashboard = () => {
  const [stats, setStats] = useState(DefaultStats);

  useEffect(() => {
    const promises = symbols.map((product) => {
      return BinanceService.get24hr(`${product}USDT`)
        .then(({ data }) => {
          setStats((stats) =>
            stats.map((x) => {
              if (x.product === product) {
                return {
                  ...x,
                  size: data.volume,
                  open: data.openPrice,
                  last: data.lastPrice,
                };
              }
              return x;
            })
          );
        })
        .catch((err) => {
          console.error(
            'Failed to get crypto market data (' + product + '), error: ' + err
          );
        });
    });
  }, []);

  return (
    <Grid container spacing={1}>
      <Grid item xs={4}>
        <StatPieChart
          header="Buy Volume"
          data={stats.map((x) => ({ name: x.product, value: x.buy }))}
        />
      </Grid>
      <Grid item xs={4}>
        <StatPieChart
          header="Sell Volume"
          data={stats.map((x) => ({ name: x.product, value: x.sell }))}
        />
      </Grid>
      <Grid item xs={4}>
        <StatPieChart
          header="Trade Volume"
          data={stats.map((x) => ({ name: x.product, value: x.buy + x.sell }))}
        />
      </Grid>
      <Grid item xs={12}>
        <StatTable data={stats} />
      </Grid>
    </Grid>
  );
};

export default CryptoDashboard;
