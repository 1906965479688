import React, { FC } from 'react';
import Layout, { LayoutProps } from './Layout';
import { LayoutContextProvider } from './LayoutContext';

const LayoutContainer: FC<LayoutProps> = ({ children, ...otherProps }) => (
  <LayoutContextProvider>
    <Layout {...otherProps}>{children}</Layout>
  </LayoutContextProvider>
);

export default LayoutContainer;
