import React from 'react';
import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  TextField,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import api from '../../ApiService/MarketCoreService';
import { toast } from 'react-toastify';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const RemoveCustodianForm = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    console.log('RemoveCustodianForm', data);
    setOpen(true);
    try {
      await api.removeCustodian(data.account);
      toast.success('Custodian removed');
    } catch (ex) {
      toast.error('Failed to remove custodian');
    }
    setOpen(false);
  };

  return (
    <>
      <Grid container>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid item xs={12}>
            <TextField
              required
              inputRef={register}
              id="account"
              name="account"
              label="Account"
              helperText="Enter the custodian account address to be removed"
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" color="primary" variant="contained">
              Remove Custodian
            </Button>
          </Grid>
        </form>
      </Grid>
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default RemoveCustodianForm;
