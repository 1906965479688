import React, { useState, useEffect } from 'react';
import { Button, Table } from 'reactstrap';
import { CircularProgress } from '@material-ui/core';
import { numberWithCommas } from './common';
import { useHistory } from 'react-router-dom';
import BrokerOMSService from '../../../ApiService/BrokerOMSService';
import moment from 'moment';
import Switch from '@material-ui/core/Switch';
import { toast } from 'react-toastify';
import EnhancedTable from '../../EnhancedTableView/EnhancedTable';
import { makeStyles } from '@material-ui/core/styles';

export const TradeBook = ({ account_no, path, stock }) => {
  const [trades, setTrades] = useState([]);
  const precision = 2;

  const fetchData = () => {
    const req = {
      accountNo: account_no,
    };
    if (stock) {
      req['secKey'] = stock.securityKey;
    }
    // Fetch all trades for the account and secKey
    BrokerOMSService.getFills(req)
      .then((res) => {
        setTrades(res.data);
      })
      .catch((err) => {
        console.error('Error in retrieving trades.');
        setTrades([]);
      });
  };

  useEffect(() => {
    fetchData();
    let interval_id = setInterval(fetchData, 5000);
    return () => clearInterval(interval_id);
  }, [account_no, stock]);

  const [filterEnabled, setFilterEnabled] = useState(false);
  const handleChange = (e) => {
    setFilterEnabled(e.target.checked);
  };

  const [selected, setSelected] = useState([]);

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }));
  const classes = useStyles();

  const columnDefs = [
    {
      id: 'ticker',
      numeric: true,
      disablePadding: false,
      label: 'Ticker',
      valueOf: (fill) => fill.securityKey,
    },
    {
      id: 'id',
      numeric: false,
      disablePadding: true,
      label: 'ID',
      valueOf: (fill) => fill.id,
    },
    {
      id: 'date',
      numeric: true,
      disablePadding: false,
      label: 'Date',
      valueOf: (fill) =>
        moment(fill.executionTime).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      id: 'side',
      numeric: true,
      disablePadding: false,
      label: 'Side',
      valueOf: (fill) => fill.side,
    },
    {
      id: 'price',
      numeric: true,
      disablePadding: false,
      label: 'Price',
      valueOf: (fill) => numberWithCommas(fill.price, 2),
    },
    {
      id: 'qty',
      numeric: true,
      disablePadding: false,
      label: 'Qty',
      valueOf: (fill) => numberWithCommas(fill.quantity),
    },
    {
      id: 'orderId',
      numeric: true,
      disablePadding: false,
      label: 'Order ID',
      valueOf: (fill) => fill.orderId,
    },
  ];

  const opDefs = [];
  const actionDefs = [];

  return (
    <>
      <EnhancedTable
        classes={classes}
        columnDefs={columnDefs}
        operationDefs={opDefs}
        dataList={trades}
        selected={selected}
        setSelected={setSelected}
        actionDefs={actionDefs}
        title=""
        initialOrder="desc"
        initialOrderBy={columnDefs.find((x) => x.id === 'date')}
      />
    </>
  );
};
