import { toast } from 'react-toastify';

export function handleError(error) {
  let errorMessage = `${error}`;
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    if (typeof error.response.data === 'object') {
      errorMessage =
        Object.entries(error.response.data).reduce(
          (text, cur) => (text += `\n${cur[0]}: ${cur[1]}`),
          ''
        ) + errorMessage;
    }
    console.error('error data: ', error.response.data);
    console.error('error status: ', error.response.status);
    console.error('error headers: ', error.response.headers);
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.error('error request: ', error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.error('error message: ', error.message);
  }
  console.error('error config: ', error.config);
  toast.error(errorMessage);
  return false;
}

export function onResolved(message, setter) {
  return (response) => {
    let data = response.data;
    console.log(`${message}: `, data);
    setter && setter(data);
    return data;
  };
}

export const handleSpecificError = (statusCode, message) => (error) => {
  if (error.response.status === statusCode) {
    message && toast.error(message);
  } else {
    handleError(error);
  }
  return false;
};
