import React from 'react';
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

const SelectGroupsSubform = ({ allGroups, selected, setSelected }) => {
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    console.log('selected: ', newSelected);

    setSelected(newSelected);
  };

  const isSelected = (primaryKey) => selected.indexOf(primaryKey) !== -1;

  return (
    <FormControl>
      <FormGroup>
        {allGroups.length ? (
          allGroups.map(
            (group) =>
              group.id !== 1 && (
                <FormControlLabel
                  key={group.id}
                  control={
                    <Checkbox
                      checked={isSelected(group.id)}
                      onClick={(e) => handleClick(e, group.id)}
                      name={group.name}
                    />
                  }
                  label={group.name}
                />
              )
          )
        ) : (
          <>(No existing groups)</>
        )}
      </FormGroup>
    </FormControl>
  );
};

export default SelectGroupsSubform;
