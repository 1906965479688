import React, { useEffect } from 'react';

import InfoGrid from '../DetailedInfo/InfoGrid';
import ApiService from '../../ApiService/ApiService';

function DetailInfo({ data, update }) {
  useEffect(() => {
    if (data) {
    }
  }, [data]);

  const fieldDefs = [
    {
      id: 'id',
      numeric: false,
      disablePadding: false,
      label: 'ID',
      valueOf: (wine) => wine.sec_key,
    },
    {
      id: 'vinatage',
      numeric: false,
      disablePadding: false,
      label: 'Vintage',
      valueOf: (wine) => wine.vintage,
    },
    {
      id: 'unit_size',
      numeric: false,
      disablePadding: false,
      label: 'Unit Size',
      valueOf: (wine) => wine.unit_size,
    },
    {
      id: 'risk',
      numeric: true,
      disablePadding: false,
      label: 'Risk',
      valueOf: (wine) => wine.risk,
    },
    {
      id: 'origin',
      numeric: false,
      disablePadding: false,
      label: 'Origin',
      valueOf: (wine) => wine.origin,
    },
    {
      id: 'region',
      numeric: false,
      disablePadding: false,
      label: 'Region',
      valueOf: (wine) => wine.region,
    },
    {
      id: 'producer',
      numeric: false,
      disablePadding: false,
      label: 'Producer',
      valueOf: (wine) => wine.producer,
    },
    {
      id: 'grape',
      numeric: false,
      disablePadding: false,
      label: 'Grape',
      displayValueOf: (wine) => wine.grape,
    },
    {
      id: 'style',
      numeric: false,
      disablePadding: false,
      label: 'Style',
      displayValueOf: (wine) => wine.style,
    },
    {
      id: 'issued_size',
      numeric: true,
      disablePadding: false,
      label: 'Issued Size',
      displayValueOf: (wine) => wine.issued_size,
    },
    {
      id: 'issuer',
      numeric: false,
      disablePadding: false,
      label: 'Issuer',
      displayValueOf: (wine) => wine.issuer,
    },
    {
      id: 'outstanding',
      numeric: true,
      disablePadding: false,
      label: 'Outstanding',
      displayValueOf: (wine) => wine.outstanding,
    },
  ];

  const handleSubmit = async (formData, e, doc) => {
    console.log('data', formData);
    let calledPutApi = false;
    const putApiFields = ['description', 'label', 'language'];
    let promises = [];
    fieldDefs.forEach((field) => {
      if (field.editField) {
        const formValue = formData[field.id];
        if (field.valueOf(doc) !== formValue) {
          if (!calledPutApi && putApiFields.indexOf(field.id) !== -1) {
            calledPutApi = true;
            promises.push(
              ApiService.editDocument(
                null,
                doc.id,
                putApiFields.reduce((body, field) => {
                  body[field] = formData[field];
                  return body;
                }, {})
              )
            );
          } else if (field.editApiCall) {
            promises.push(field.editApiCall(doc, formValue));
          }
        }
      }
    });
    return promises;
  };

  return (
    <InfoGrid
      data={data}
      fieldDefs={fieldDefs}
      handleSubmit={handleSubmit}
      update={update}
    />
  );
}

export default DetailInfo;
