import React from 'react';
import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import api from '../../ApiService/MarketCoreService';
import { toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';
import { addressBook } from './AddressBook';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const MintTokensForm = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { control, register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    console.log('MintTokensForm', data);
    setOpen(true);
    try {
      await api.mintTokens(data.to, data.amount, data.refId);
      toast.success(`Tokens minted [${data.refId}]`);
    } catch (ex) {
      toast.error(`Failed to mint tokens [${data.refId}}`);
    }
    setOpen(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12}>
            <InputLabel id="toAddress">To</InputLabel>
            <Controller
              as={
                <Select labelId="toAddress">
                  {addressBook.map((x, i) => (
                    <MenuItem key={i} value={x.address}>
                      {x.name} - {x.address}
                    </MenuItem>
                  ))}
                </Select>
              }
              name="to"
              control={control}
            />
            {/* <TextField
              required
              inputRef={register}
              id="to"
              name="to"
              label="To"
              helperText="Enter the account address to mint tokens to"
              fullWidth
            /> */}
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              inputRef={register}
              id="amount"
              name="amount"
              label="Amount"
              helperText="Enter the amount of PTC to be minted"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              inputRef={register}
              id="refId"
              name="refId"
              label="Ref ID"
              helperText="Enter a reference ID"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" color="primary" variant="contained">
              Mint Tokens
            </Button>
          </Grid>
        </Grid>
      </form>
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default MintTokensForm;
