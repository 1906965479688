import React from 'react';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import {
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
} from '@material-ui/core';

function ControlledSelectWithValidation({
  fullWidth,
  control,
  rules,
  errors,
  name,
  label,
  required,
  defaultValue,
  children,
  error,
  ...inputFeieldProps
}) {
  return (
    <FormControl fullWidth={fullWidth === true}>
      <InputLabel required={required} error={error === true}>
        {label}
      </InputLabel>
      <Controller
        render={({ onChange }) => (
          <Select
            defaultValue={
              typeof defaultValue !== 'undefined' ? defaultValue : ''
            }
            onChange={onChange}
            error={error === true}
            {...inputFeieldProps}
          >
            {children}
          </Select>
        )}
        rules={rules}
        name={name}
        control={control}
      />
      <ErrorMessage errors={errors} name={name} as={<FormHelperText error />} />
    </FormControl>
  );
}

export default ControlledSelectWithValidation;
