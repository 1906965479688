import axios from 'axios';

const API = axios.create({
  baseURL: window['config'].apiBaseUrl.brokeroms,
});

const ApiService = {
  setBrokeromsAuth(auth) {
    API.defaults.headers.common['Authorization'] = auth;
  },

  // Accounts
  deposit(accountNo, currency, amount) {
    return API.post('orders/deposit/', { accountNo, currency, amount });
  },
  withdraw(accountNo, currency, amount) {
    return API.post('orders/withdraw/', { accountNo, currency, amount });
  },
  approve(accountNo, txnId, isApproved, comment) {
    return API.post('orders/approve/', {
      accountNo,
      iD: txnId,
      approved: isApproved,
      comment,
    });
  },
  getAcctTxn(accountNo) {
    return API.get(`orders/accttxns/${accountNo}`);
  },

  // Ordering
  placeOrder(order) {
    return API.post('orders/submit/', order);
  },

  cancelOrder(order) {
    return API.post('orders/cancel/', order);
  },

  getOpenOrders(req) {
    return API.post('orders/open/', req);
  },

  getFills(req) {
    if (req.secKey){
	    console.info(`getting order fills ${req.accountNo}/${req.secKey}`);
	    return API.get(`orders/fills/${req.accountNo}/${req.secKey}`);
    }else{
	    console.info(`getting order fills ${req.accountNo}`);
	    return API.get(`orders/fills/${req.accountNo}`);
    }
  },

  getAssetPositions(req) {
    if (req.secKey){
	    console.info(`getting asset positions ${req.accountNo}/${req.secKey}`);
	    return API.get(`orders/positions/${req.accountNo}/${req.secKey}`);
    }else{
	    console.info(`getting asset positions ${req.accountNo}`);
	    return API.get(`orders/positions/${req.accountNo}`);
    }
  },

  getAcctPositions(req) {
    return API.get(`orders/acctpos/${req.accountNo}`);
  },

};

export default ApiService;
