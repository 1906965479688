import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Divider, MenuItem, Select, Tab, Tabs } from '@material-ui/core';

import ApiService from '../../ApiService/ApiService';
import AccountDetail from './Accounts/AccountDetail';
import AccountTrades from './Accounts/AccountTrades';
import AccountPositions from './Accounts/AccountPositions';
import CashMovements from './Accounts/CashMovements';
import { OrderHistory } from '../exchange/components/OrderHistory';
import { Positions } from '../exchange/components/Positions';
import BrokerOMSService from '../../ApiService/BrokerOMSService';
import { TradeBook } from '../exchange/components/TradeBook';

const numberFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

function AccountOverview({ isLoggedIn, roles }) {
  const [wineList, setWineList] = useState([]);
  const [infoData, setInfoData] = useState(null);
  const classes = useStyles();

  const [accounts, setAccounts] = useState([]);
  console.log('accounts', accounts);

  //  const [updateFlag, setUpdateFlag] = useState(false);
  //  const update = () => setUpdateFlag((old) => !old);
  const handleUpdate = () => {
    let mounted = true;
    if (isLoggedIn && mounted) {
      ApiService.getCurrentAccounts(setAccounts);

      /*
      ApiService.getCurrentPositions().then((results) => {
        if (results && mounted) {
          setWineList(results);
          infoData &&
            setInfoData((old) => results.find((doc) => doc.id === old.id));
        }
      });
*/
    } else {
      setWineList([]);
    }

    return () => {
      mounted = false;
    };
  };

  useEffect(handleUpdate, []);

  const [activeTab, setActiveTab] = useState(0);
  const handleTabChange = (event, value) => {
    setActiveTab(value);
  };
  const TabPanel = ({ activeTab, index, children }) => {
    return activeTab === index ? <>{children}</> : null;
  };

  const [activeAccount, setActiveAccount] = useState(0);
  const acc = accounts && activeAccount != null && accounts[activeAccount];

  const [positions, setPositions] = useState([]);
  useEffect(() => {
    if (acc) {
      console.info('effect on asset positions..' + acc);
      const req = {
        accountNo: acc && acc.account_no,
      };
      BrokerOMSService.getAssetPositions(req)
        .then((results) => {
          console.info('retrieved asset position');
          setPositions(results.data);
        })
        .catch((err) =>
          console.error('Error in getting asset position: ' + err)
        );
    }
  }, [acc]);

  /*
  var positions =
    wineList &&
    accounts[activeAccount] &&
    wineList[accounts[activeAccount].account_no]
      ? wineList[accounts[activeAccount].account_no]
      : [];
*/

  return (
    <>
      <h3>Account Overview</h3>
      <Select
        labelId="select-label"
        id="select"
        value={activeAccount}
        onChange={(e) => {
          setActiveAccount(e.target.value);
        }}
      >
        <MenuItem value={null}>
          <em>Select account...</em>
        </MenuItem>
        {accounts.map((a, i) => {
          return (
            <MenuItem value={i} key={i}>
              {a.account_no} {a.remarks}
            </MenuItem>
          );
        })}
      </Select>

      {acc && (
        <>
          <Card key={acc.account_no}>
            <h4>Purchasing power: {acc.balance.toLocaleString()}</h4>
          </Card>

          <Tabs
            value={activeTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChange}
            aria-label="tabs"
          >
            <Tab label="Positions" />
            <Tab label="Order Book" />
            <Tab label="Trade Book" />
            <Tab label="Account Details" />
            <Tab label="Cash Movements" />
          </Tabs>
          <TabPanel activeTab={activeTab} index={0}>
            <Positions positions={positions}></Positions>
          </TabPanel>
          <TabPanel activeTab={activeTab} index={1}>
            <OrderHistory
              account_no={acc && acc.account_no}
              orderHistory={[]}
              all={true}
            />
          </TabPanel>
          <TabPanel activeTab={activeTab} index={2}>
            <TradeBook account_no={acc && acc.account_no} />
          </TabPanel>
          <TabPanel activeTab={activeTab} index={3}>
            <AccountDetail account={acc} />
          </TabPanel>
          <TabPanel activeTab={activeTab} index={4}>
            <CashMovements classes={classes} account={acc} roles={roles} />
          </TabPanel>
          <Divider />
        </>
      )}
    </>
  );
}

export default AccountOverview;
