import React, { useEffect, useState } from 'react';
import MarketCoreService from '../ApiService/MarketCoreService';

export const CurrencyContext = React.createContext();

export function useCurrencyContext() {
  return React.useContext(CurrencyContext);
}

export const CurrencyContextProvider = (props) => {
  const [fx, setFx] = useState(null);
  const [ccy, setCcy] = useState('hkd');

  useEffect(() => {
    MarketCoreService.getFxRates().then((res) => {
      setFx(res.data);
    });
  }, []);

  const convert = (value) => {
    const numberFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: ccy,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    if (ccy === 'hkd') {
      return numberFormatter.format(value).replace('HK$', '$');
    } else {
      const r = fx?.rates.find((x) => x.ccy === ccy);
      return numberFormatter.format(value * r?.rate);
    }
  };

  // The following values are accessible when using the context
  const value = {
    ccy,
    convert,
    fx,
    setCcy,
  };

  return <CurrencyContext.Provider value={value} {...props} />;
};
